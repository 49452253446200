import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { formatDate, formatTime } from '../../helpers/dateHelpers';
import { deletePin } from '../../redux/reducers/commentPinsSlice';
import RKContextText from '../design-system/RKContextText';
import CommentToolbarInput from './CommentToolbarInput';

import './css/CommentToolbar.css';

function CommentToolbarEditableDisplay({ uuid, text, timestamp }) {
  const dispatch = useDispatch();

  const onCancelClick = (commentUuid) => {
    dispatch(deletePin({ uuid: commentUuid }));
  };
  return (
    <>
      <FontAwesomeIcon
        className="commentmodal-button"
        icon={faTimes}
        onClick={() => onCancelClick(uuid)}
      />
      <div className="last-comments-comment">
        <CommentToolbarInput uuid={uuid} text={text} isEditable />
      </div>
      <div className="last-comments-comment-block-footer">
        <RKContextText className="last-comments-footer-text">{formatDate(timestamp)}</RKContextText>
        <RKContextText className="last-comments-footer-text">{formatTime(timestamp)}</RKContextText>
      </div>
    </>
  );
}

CommentToolbarEditableDisplay.propTypes = {
  uuid: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
};

export default CommentToolbarEditableDisplay;
