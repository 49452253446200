import LZString from 'lz-string';

export const getCompressedAnnotations = (annotations) => {
  const compressedMarkup = LZString.compressToEncodedURIComponent(annotations.markup);
  const compressedComments = LZString.compressToEncodedURIComponent(annotations.comments);
  return {
    compressedMarkup,
    compressedComments,
  };
};

export const getCompressedComments = (comments) => {
  const compressedComments = LZString.compressToEncodedURIComponent(comments);
  return compressedComments;
};

export const getDecompressedAnnotations = (annotations) => {
  const decompressedMarkup = LZString.decompressFromEncodedURIComponent(annotations.markup);
  const decompressedComments = LZString.decompressFromEncodedURIComponent(annotations.comments);
  return {
    decompressedMarkup,
    decompressedComments,
  };
};
