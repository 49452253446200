/* eslint-disable no-param-reassign */
// (the `state` param of reducers is intended to be reassigned)
import { createSlice } from '@reduxjs/toolkit';

import { fetchResume } from './reviewerApiSlice';

export const reviewerPdfSlice = createSlice({
  name: 'reviewerPdf',
  initialState: {
    pdf: null, // A PDF file (array of bytes).
    page: 1, // The page to display.
    name: null,
    notes: '',
  },
  reducers: {
    setPdf: (state, { payload }) => {
      state.pdf = payload;
    },
    resetResume: (state) => {
      state.pdf = null;
      state.page = 1;
      state.name = null;
      state.notes = '';
    },
    setPage: (state, { payload }) => {
      // Payload should have { oldPage, newPage } since there is dependent middleware.
      const { newPage } = payload;
      state.page = newPage;
    },
  },
  extraReducers: {
    [fetchResume.fulfilled]: (state, { payload }) => {
      const isSameArray =
        state.pdf &&
        state.pdf.length === payload.pdfData.length &&
        state.pdf.every((element, index) => element === payload.pdfData[index]);
      // Prevents flickering from pdf being updated with a new array representing the same data
      if (!isSameArray) {
        state.pdf = payload.pdfData;
      }
      state.page = 1;
      state.name = payload.name;
      state.notes = payload.notes ?? state.notes;
    },
  },
});

export const { setPdf, resetResume, setPage } = reviewerPdfSlice.actions;
export const selectReviewerPdf = (state) => state.reviewerPdf.pdf;
export const selectReviewerPdfName = (state) => state.reviewerPdf.name;
export const selectReviewerPdfNotes = (state) => state.reviewerPdf.notes;
export const selectReviewerPage = (state) => state.reviewerPdf.page;
export default reviewerPdfSlice.reducer;
