import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { useDispatch } from 'react-redux';

import PDF_RENDER_STATUS from '../../../helpers/pdfHelpers';
import { setPdfRenderStatus } from '../../../redux/reducers/pdfMarkupStatusSlice';

// Enables PDF rendering in a web worker so the page doesn't hang to render.
// See https://github.com/wojtekmaj/react-pdf#enable-pdfjs-worker for more info.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`; // eslint-disable-line max-len

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_debounce
function debounce(func, wait) {
  let timeout;
  return () => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context);
    }, wait);
  };
}

function PdfView({ onPageRenderSuccess, pdfData, page, setTotalPages, pdfContainerRef }) {
  const dispatch = useDispatch();
  // memoize the file object to prevent flickering when the pdf data hasn't actually changed
  const memoizedFile = useMemo(() => new Uint8Array(pdfData).buffer, [pdfData]);

  const [scale, setScale] = useState(1);

  // Object states will always be considered different when updating which will cause
  // a rerender even if all the fields are the same. It is ok in this case because screen
  // size changes will almost always result in different dimensions.
  const [pdfDimensions, setPdfDimensions] = useState({ height: 0, width: 0 });
  const [pdfContainerDimension, setPdfContainerDimensions] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    setPdfContainerDimensions({
      height: pdfContainerRef?.current?.clientHeight,
      width: pdfContainerRef?.current?.clientWidth,
    });
  }, []);

  useEffect(() => {
    const DESIRED_HEIGHT_DIFF = 50;
    const DESIRED_WIDTH_DIFF = 25;
    const pdfAspectRatio = pdfDimensions.height / pdfDimensions.width;
    const pdfContainerAspectRatio = pdfContainerDimension.height / pdfContainerDimension.width;
    // Determine whether we're height or width restricted and determine a desired height/width.
    // Then calculate the needed scale to achieve this desired height/width.
    if (pdfAspectRatio > pdfContainerAspectRatio) {
      // Height restricted
      const desiredHeight = pdfContainerDimension.height - DESIRED_HEIGHT_DIFF;
      setScale(desiredHeight / pdfDimensions.height);
    } else {
      // Width restricted
      const desiredWidth = pdfContainerDimension.width - DESIRED_WIDTH_DIFF;
      setScale(desiredWidth / pdfDimensions.width);
    }
  }, [pdfDimensions, pdfContainerDimension]);

  useEffect(() => {
    dispatch(setPdfRenderStatus(PDF_RENDER_STATUS.LOADING));
  }, [page]);

  const onRenderSuccess = () => {
    onPageRenderSuccess();
    dispatch(setPdfRenderStatus(PDF_RENDER_STATUS.SUCCEEDED));
  };

  const onLoadError = () => {
    dispatch(setPdfRenderStatus(PDF_RENDER_STATUS.FAILED));
  };

  const onWindowResize = debounce(() => {
    if (pdfContainerRef?.current?.clientHeight && pdfContainerRef?.current?.clientWidth) {
      setPdfContainerDimensions({
        height: pdfContainerRef.current.clientHeight,
        width: pdfContainerRef.current.clientWidth,
      });
    }
  }, 250);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return (
    pdfData && (
      <Document
        loading=""
        error=""
        externalLinkTarget="_blank"
        file={memoizedFile}
        onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
        onSourceError={onLoadError}
        options={{
          cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
        }}
        renderMode="svg"
      >
        <Page
          onLoadSuccess={({ originalHeight: pdfHeight, originalWidth: pdfWidth }) =>
            setPdfDimensions({ height: pdfHeight, width: pdfWidth })
          }
          loading=""
          error=""
          pageNumber={page}
          scale={scale}
          onLoadError={onLoadError}
          onRenderSuccess={onRenderSuccess}
        />
      </Document>
    )
  );
}

PdfView.propTypes = {
  onPageRenderSuccess: PropTypes.func,
  page: PropTypes.number.isRequired,
  pdfData: PropTypes.arrayOf(PropTypes.number),
  setTotalPages: PropTypes.func.isRequired,
  pdfContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLElement) }),
  ]).isRequired,
};

PdfView.defaultProps = {
  pdfData: null,
  onPageRenderSuccess: () => {},
};

export default PdfView;
