import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import { selectPdfIsLoading } from '../../redux/reducers/pdfMarkupStatusSlice';
import ArrowWrapper from './pdf-markup-builder/ArrowWrapper';
import PdfView from './pdf-markup-builder/PdfView';
import SkeletonPdf from './SkeletonPdf';
import './css/PdfView.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

function PdfContainer({ onPageChange, pdfData, showBackButton, backButtonLink }) {
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const isLoading = useSelector(selectPdfIsLoading);

  const pdfContainerRef = useRef(null);

  return (
    <div className="pdf-container" ref={pdfContainerRef}>
      {showBackButton && (
        <Button
          className="pdf-page-button pdf-page-back-button"
          onClick={() => history.push(backButtonLink)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      )}
      <ArrowWrapper
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        onPageChange={onPageChange}
      >
        <div className="pdf-canvas-wrapper">
          {isLoading && <SkeletonPdf />}
          <div className={isLoading ? 'pdf-hidden' : ''}>
            <PdfView
              pdfData={pdfData}
              page={page}
              setTotalPages={setTotalPages}
              pdfContainerRef={pdfContainerRef}
            />
          </div>
        </div>
      </ArrowWrapper>
    </div>
  );
}

PdfContainer.propTypes = {
  onPageChange: PropTypes.func,
  pdfData: PropTypes.arrayOf(PropTypes.number),
  showBackButton: PropTypes.bool,
  backButtonLink: PropTypes.string,
};

PdfContainer.defaultProps = {
  onPageChange: () => {},
  pdfData: null,
  showBackButton: false,
  backButtonLink: '/home',
};

export default PdfContainer;
