import axios from 'axios';

import { RefreshTokenError } from './customErrorHelpers';
import env from './envHelpers';

/* eslint-disable import/prefer-default-export */
export async function authFetch(url, requestOptions) {
  const modifiedReqOptions = { credentials: 'include', ...requestOptions };
  const origRes = await fetch(url, modifiedReqOptions); // Make original Request
  if (origRes.status === 403) {
    // If our access token is expired, call token endpoint
    const res = await fetch(`${env('RVKT_API_HOST')}/token`, { credentials: 'include' });
    if (res.ok) {
      return fetch(url, modifiedReqOptions);
    }
    // Our refresh token is invalid so we must kick the user out
    throw new RefreshTokenError();
  }
  return origRes;
}

export async function authAxiosRequest(urlPath, requestType, data, config) {
  const modifiedConfig = {
    withCredentials: true,
    method: requestType,
    url: urlPath,
    data,
    ...config,
  };
  return axios
    .request(modifiedConfig) // Make original Request
    .then((origRes) => origRes)
    .catch((error) => {
      if (error.response.status === 403) {
        // If our access token is expired, call token endpoint
        return axios
          .get('/token', {
            baseURL: `${env('RVKT_API_HOST')}`,
            withCredentials: true,
          })
          .then(() => axios.request(modifiedConfig)) // Make original Request again
          .catch(() => {
            // Our refresh token is invalid so we must kick the user out
            throw new RefreshTokenError();
          });
      }
      return error;
    });
}
