import React from 'react';
import { Media } from 'reactstrap';

import EmptySavedReviewImage from './assets/empty-saved-review.png';

import './css/SavedReviewEmpty.css';

/* TODO: Design graphic for this component, perhaps a confused rhino */
function SavedReviewEmpty() {
  return (
    <div className="saved-review-empty-base">
      <div className="saved-review-empty-resume-block">
        <Media src={EmptySavedReviewImage} />
        <h5>
          This review doesn&apos;t exist,
          <br /> please check the URL and try again!
        </h5>
      </div>
    </div>
  );
}

export default SavedReviewEmpty;
