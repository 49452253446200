import ReactGA from 'react-ga';

export const GA_CATEGORY = {
  REVIEWEE: 'Reviewee',
  REVIEWER: 'Reviewer',
};

export function gaEvent(category, componentName, action) {
  ReactGA.event(
    {
      category,
      label: componentName,
      action,
    },
    ['trackerProd', 'trackerStaging'],
  );
}

export function gaPageview() {
  ReactGA.pageview(window.location.pathname + window.location.search, [
    'trackerProd',
    'trackerStaging',
  ]);
}
