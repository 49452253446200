import { faEdit, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';

import { formatDate } from '../../helpers/dateHelpers';
import {
  selectRevieweePdfName,
  selectRevieweePdfUploadedAt,
  selectRevieweePdfId,
  updatePdfData,
} from '../../redux/reducers/revieweePdfSlice';
import RKCard from '../design-system/RKCard';
import RKContextText from '../design-system/RKContextText';

import './css/ResumeNameCard.css';

function ResumeNameCard({ disabled }) {
  const dispatch = useDispatch();
  const resumeId = useSelector(selectRevieweePdfId);
  const resumeUploadTime = useSelector(selectRevieweePdfUploadedAt);
  const savedResumeName = useSelector(selectRevieweePdfName);

  const [isEditing, _setIsEditing] = useState(false);
  const isEditingRef = useRef(isEditing);
  const setIsEditing = (data) => {
    isEditingRef.current = data;
    _setIsEditing(data);
  };
  const toggleIsEditing = () => setIsEditing(!isEditing);

  // Focus the input after isEditing is toggled to true
  const nameInputRef = useRef(null);
  useEffect(() => {
    if (isEditing) {
      nameInputRef.current?.focus();
    }
  }, [isEditing]);

  const [resumeNameValue, setResumeNameValue] = useState(savedResumeName);

  const resumeCardClassName = 'upload-sidebar-resume-name-card';
  const mouseDownDetection = (event) => {
    const composedPaths = event.composedPath();
    if (!composedPaths) {
      // This code is only reachable on IE.
      // This early return will not be functional but prevents our app from just crashing
      // https://caniuse.com/mdn-api_event_composedpath
      return;
    }
    const paths = composedPaths.map((path) => path.className);
    const resumeNameCardClicked = paths.some((element) => {
      const classString = `${element}`; // ensure classname is a string
      return classString.includes(resumeCardClassName);
    });

    if (!resumeNameCardClicked && isEditingRef.current) {
      setIsEditing(false);
      setResumeNameValue(savedResumeName);
    }
  };
  useEffect(() => {
    setResumeNameValue(savedResumeName);
    document.body.addEventListener('mousedown', mouseDownDetection);
    return () => {
      document.body.removeEventListener('mousedown', mouseDownDetection);
    };
  }, [savedResumeName]);

  const editResumeNameOnClickHandler = () => {
    if (isEditing && savedResumeName !== resumeNameValue && resumeNameValue !== '') {
      // Only make request if file name is different
      dispatch(updatePdfData({ resumeId, name: resumeNameValue }));
    } else {
      setResumeNameValue(savedResumeName);
    }
    toggleIsEditing();
  };

  return (
    <RKCard noTopMargin className={resumeCardClassName}>
      <InputGroup className="upload-sidebar-resume-name-input">
        <Input
          innerRef={nameInputRef}
          disabled={disabled || !isEditing}
          value={resumeNameValue}
          onChange={(e) => setResumeNameValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              editResumeNameOnClickHandler();
            }
          }}
        />
        <InputGroupAddon addonType="append">
          <Button onClick={editResumeNameOnClickHandler} disabled={disabled}>
            {isEditing ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faEdit} />}
          </Button>
        </InputGroupAddon>
      </InputGroup>
      {!disabled && <RKContextText>Uploaded on {formatDate(resumeUploadTime)} </RKContextText>}
    </RKCard>
  );
}

ResumeNameCard.propTypes = {
  disabled: PropTypes.bool,
};

ResumeNameCard.defaultProps = {
  disabled: false,
};

export default ResumeNameCard;
