import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsError } from '../../redux/reducers/errorSlice';

import './css/ErrorToastRenderer.css';

const ERROR_TOAST_TIMEOUT_MS = 3000;

function ErrorToastRenderer() {
  const isError = useSelector(selectIsError);

  // Tri-state boolean, null = state before any toasts have appeared
  // This prevents the "exit" animation from playing on page load (which would happen
  // if the default value was false)
  const [toastDisplayed, setToastDisplayed] = useState(null);

  useEffect(() => {
    /* For when object has fully faded */
    const toast = document.getElementById('error-toast');
    toast.addEventListener('animationend', () => {
      if (
        toast.className.includes('error-toast-exiting') &&
        !toast.className.includes('error-toast-hidden')
      ) {
        toast.className += ' error-toast-hidden';
      }
    });

    if (isError && !toastDisplayed) {
      setToastDisplayed(true);
      setTimeout(() => setToastDisplayed(false), ERROR_TOAST_TIMEOUT_MS);
    }
  }, [isError]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handle tri-state boolean toastDisplayed
  let displayClassName = 'error-toast-hidden';
  if (toastDisplayed === true) {
    displayClassName = 'error-toast-entering';
  } else if (toastDisplayed === false) {
    displayClassName = 'error-toast-exiting';
  }

  return (
    <div id="error-toast" color="danger" className={`error-toast ${displayClassName}`}>
      <div className="error-toast-icon-wrapper">
        <FontAwesomeIcon icon={faExclamation} size="sm" />
      </div>
      <span>Something went wrong, please try again.</span>
    </div>
  );
}

export default ErrorToastRenderer;
