import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Media, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';

import { userLogout, selectUserData } from '../../redux/reducers/userLoginSlice';
import './css/LoggedInDropdown.css';

function LoggedInDropdown() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);

  const logout = () => {
    dispatch(userLogout());
  };

  const feedback = () => {
    window.open('https://forms.gle/actJUiW8owq95W6V9', '_blank', 'noopener');
  };

  return (
    <Dropdown className="loggedin-dropdown" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className="loggedin-dropdown-toggle" tag="div" caret>
        <Media
          className="loggedin-dropdown-avatar"
          object
          src={userData.profilePictureUrl}
          alt="Profile picture"
        />
        <span>{`${userData.firstName} ${userData.lastName}`}</span>
      </DropdownToggle>
      <DropdownMenu className="loggedin-dropdown-menu" right>
        {/* <DropdownItem className="loggedin-dropdown-menu-item">
          Profile
        </DropdownItem> */}
        <DropdownItem className="loggedin-dropdown-menu-item" onClick={logout}>
          Sign out
        </DropdownItem>
        <DropdownItem className="loggedin-dropdown-menu-item" onClick={feedback}>
          Feedback
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default LoggedInDropdown;
