// Slice for API interactions on the /review page.
// - Holds strictly API-related vars (eg. reviewId is only used in calling the API).
// - Async actions are dispatched to this slice for API calls.

/* eslint-disable no-param-reassign */
// (the `state` param of reducers is intended to be reassigned)
import http from 'http';
import https from 'https';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fabric } from 'fabric';

import {
  getDecompressedAnnotations,
  getCompressedAnnotations,
} from '../../helpers/compressionHelpers';
import env from '../../helpers/envHelpers';

// Creates the serialized annotations to send to the server. The annotations are serialized
// in redux on page change, meaning the annotations on the current page may not have been
// serialized yet. Thus for the request, use the stored serialized annotations for non-current
// pages and directly serialize the current page.
function createSerializedAnnotations(currentState) {
  const { page } = currentState.reviewerPdf;
  const { canvas, serializedMarkup } = currentState.canvas;
  const updatedSerializedMarkup = JSON.stringify({
    ...serializedMarkup,
    [page]: JSON.stringify(canvas),
  });
  const { pins, serializedPins } = currentState.commentPins;
  const serializedComments = JSON.stringify({
    ...serializedPins,
    [page]: JSON.stringify(pins),
  });

  return {
    markup: updatedSerializedMarkup,
    comments: serializedComments,
  };
}

// Create an annotated thumbnail for the first page of a resume
async function createAnnotatedThumbnail(
  canvas,
  serializedMarkup,
  pins,
  serializedPins,
  initialHeight,
  initialWidth,
) {
  // Create a temporary canvas on the DOM (needed for fabric operations)
  const tempDomCanvas = document.createElement('canvas');
  tempDomCanvas.setAttribute('id', 'temp-dom-canvas');
  tempDomCanvas.setAttribute('display', 'none');
  document.body.appendChild(tempDomCanvas);

  // Ensure markup from the first page is being used
  const pageOneCanvas = new fabric.Canvas('temp-dom-canvas');
  pageOneCanvas.setDimensions({ height: initialHeight, width: initialWidth });
  if (serializedMarkup[0]) {
    pageOneCanvas.loadFromJSON(serializedMarkup[0]);
  } else {
    // Canvas needs to be cloned since it will be modified when generating a thumbnail
    pageOneCanvas.loadFromJSON(JSON.stringify(canvas));
  }

  // Ensure pins from the first page are being used
  let pageOnePins;
  if (serializedPins[0]) {
    pageOnePins = JSON.parse(serializedMarkup[0]);
  } else {
    // Pins are read-only in thumbnail generation, so no need to clone
    pageOnePins = pins;
  }

  // Use the react-pdf generated PDF canvas to create a background for the markup thumbnail
  const pdfCanvases = document.getElementsByClassName('react-pdf__Page__canvas');
  if (pdfCanvases.length === 0) {
    return null;
  }
  const pdfCanvas = pdfCanvases[0];
  const bgImage = pdfCanvas.toDataURL('image/png');
  await new Promise((resolve) => {
    pageOneCanvas.setBackgroundImage(bgImage, resolve);
  });
  // This actually scales both height and width equally
  pageOneCanvas.backgroundImage.scaleToWidth(initialWidth);

  // For each pin, draw a comment pin icon onto the canvas
  const drawCommentPinIcon = ({ xPos, yPos }) => {
    const PRIMARY_COLOR_CODE = getComputedStyle(document.documentElement).getPropertyValue(
      '--primary',
    );
    const PIN_OUTER_RADIUS = 9;
    const PIN_INNER_RADIUS = 4;
    const PIN_CENTER_OFFSET = 15;
    const PIN_TRIANGLE_OFFSET = 12;

    pageOneCanvas.add(
      new fabric.Circle({
        fill: PRIMARY_COLOR_CODE,
        radius: PIN_OUTER_RADIUS,
        left: xPos,
        top: yPos - PIN_CENTER_OFFSET,
        originX: 'center',
        originY: 'center',
      }),
      new fabric.Polygon(
        [
          { x: xPos + PIN_OUTER_RADIUS, y: yPos - PIN_TRIANGLE_OFFSET },
          { x: xPos, y: yPos },
          { x: xPos - PIN_OUTER_RADIUS, y: yPos - PIN_TRIANGLE_OFFSET },
        ],
        {
          fill: PRIMARY_COLOR_CODE,
        },
      ),
      new fabric.Circle({
        fill: 'white',
        radius: PIN_INNER_RADIUS,
        left: xPos,
        top: yPos - PIN_CENTER_OFFSET,
        originX: 'center',
        originY: 'center',
      }),
    );
  };
  Object.values(pageOnePins).forEach(drawCommentPinIcon);

  // Scale the canvas down so that its maximum dimension is 600px
  // (600px chosen to match the scaling used for resume thumbnails)
  const markupThumbnailScaleMultiplier = 600 / Math.max(initialHeight, initialWidth);
  const markupThumbnail = pageOneCanvas.toDataURL({
    format: 'png',
    multiplier: markupThumbnailScaleMultiplier,
  });

  // Clean up the DOM tree
  tempDomCanvas.parentNode.removeChild(tempDomCanvas);
  return markupThumbnail;
}

// [POST /review] request generator
function generatePostReviewRequest(currentState, initialHeight, initialWidth) {
  const { resumeId } = currentState.reviewerApi;
  const annotations = createSerializedAnnotations(currentState);
  const { compressedMarkup, compressedComments } = getCompressedAnnotations(annotations);
  const data = {
    markup: compressedMarkup,
    comments: compressedComments,
    canvasHeight: initialHeight,
    canvasWidth: initialWidth,
  };
  const config = {
    baseURL: `${env('RVKT_API_HOST')}`,
    params: {
      resumeId,
    },
    headers: { 'Content-Type': 'application/json' },
  };

  return axios.post('/review', data, config);
}

// [PUT /review] request generator
function generatePutReviewRequest(currentState) {
  const { reviewId } = currentState.reviewerApi;
  const annotations = createSerializedAnnotations(currentState);
  const { compressedMarkup, compressedComments } = getCompressedAnnotations(annotations);
  const data = {
    markup: compressedMarkup,
    comments: compressedComments,
  };

  // Only PUT to the server if the annotations are different than what the server has
  if (
    currentState.reviewerApi.lastPutCompressedMarkup === compressedMarkup &&
    currentState.reviewerApi.lastPutCompressedComments === compressedComments
  ) {
    return Promise.resolve({
      data: {
        data,
        status: 200,
        statusText: 'OK',
      },
    });
  }

  const config = {
    baseURL: `${env('RVKT_API_HOST')}`,
    params: {
      reviewId,
    },
    headers: { 'Content-Type': 'application/json' },
    // Since we send this request once on page unload.
    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true }),
  };

  return axios.put('/review', data, config);
}

// [GET /review] request generator
function generateGetReviewRequest(currentState) {
  const { reviewId } = currentState.reviewerApi;
  const config = {
    baseURL: `${env('RVKT_API_HOST')}`,
    params: {
      reviewId,
    },
  };
  return axios.get('/review', config);
}

// [GET /resume] request generator
function generateGetResumeRequest(resumeId) {
  const url = new URL(`${env('RVKT_API_HOST')}/resume`);
  url.search = new URLSearchParams({
    id: resumeId,
  }).toString();
  const requestOptions = { method: 'GET' };
  return fetch(url, requestOptions);
}

// [POST /review/complete] request generator
async function generatePostReviewCompleteRequest(
  currentState,
  reviewerName,
  reviewerEmail,
  reviewerGeneralComments,
) {
  const { reviewId, resumeId } = currentState.reviewerApi;
  const { initialHeight, initialWidth } = currentState.scale;
  const { canvas, serializedMarkup } = currentState.canvas;
  const { pins, serializedPins } = currentState.commentPins;
  const annotations = createSerializedAnnotations(currentState);
  const { compressedMarkup, compressedComments } = getCompressedAnnotations(annotations);

  const markupThumbnail = await createAnnotatedThumbnail(
    canvas,
    serializedMarkup,
    pins,
    serializedPins,
    initialHeight,
    initialWidth,
  );

  const data = {
    markup: compressedMarkup,
    markupThumbnail: markupThumbnail || '',
    comments: compressedComments,
    reviewerName,
    reviewerEmail,
    generalComments: reviewerGeneralComments,
    canvasHeight: initialHeight,
    canvasWidth: initialWidth,
  };

  const config = {
    baseURL: `${env('RVKT_API_HOST')}`,
    params: {
      reviewId,
      resumeId,
    },
    headers: { 'Content-Type': 'application/json' },
  };

  return axios.post('/review/complete', data, config);
}

export const createReview = createAsyncThunk(
  'reviewerApi/createReview',
  async ({ initialHeight, initialWidth }, thunkApi) =>
    generatePostReviewRequest(thunkApi.getState(), initialHeight, initialWidth)
      .then((response) => {
        response.data.initialHeight = initialHeight;
        response.data.initialWidth = initialWidth;
        return response.data;
      })
      .catch((error) => {
        throw new Error(`Response code: ${error.response.status}`);
      }),
);

export const pushAnnotationsToServer = createAsyncThunk(
  'reviewerApi/pushAnnotationsToServerStatus',
  async (_, thunkApi) =>
    generatePutReviewRequest(thunkApi.getState())
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(`Response code: ${error.response.status}`);
      }),
);

export const fetchAnnotations = createAsyncThunk(
  'reviewerApi/fetchAnnotationsStatus',
  async (_, thunkApi) =>
    generateGetReviewRequest(thunkApi.getState())
      .then((response) => {
        const responseData = response.data;
        const { decompressedMarkup, decompressedComments } = getDecompressedAnnotations(
          responseData.data,
        );
        responseData.data.markup = decompressedMarkup;
        responseData.data.comments = decompressedComments;
        return responseData.data;
      })
      .catch((error) => {
        throw new Error(`Response code: ${error.response.status}`);
      }),
);

export const fetchResume = createAsyncThunk('reviewerApi/fetchPdfStatus', async (resumeId) => {
  const response = await generateGetResumeRequest(resumeId);
  if (!response.ok) {
    throw new Error(`Response code: ${response.status}`);
  }
  const responseJson = await response.json();
  return {
    resumeId,
    pdfData: responseJson.data.pdf,
    name: responseJson.data.name,
    uploadedAt: responseJson.data.uploadedAt,
    notes: responseJson.data.notes,
  };
});

export const pushSavedReview = createAsyncThunk(
  'reviewerApi/pushSavedReview',
  async ({ reviewerName, reviewerEmail, reviewerGeneralComments }, thunkApi) =>
    generatePostReviewCompleteRequest(
      thunkApi.getState(),
      reviewerName,
      reviewerEmail,
      reviewerGeneralComments,
    )
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(`Response code: ${error.response.status}`);
      }),
);

export const reviewerApiSlice = createSlice({
  name: 'reviewerApi',
  initialState: {
    resumeId: null, // The resumeId for the current session.
    reviewId: null, // The reviewId for the current session.
    lastPutCompressedMarkup: null,
    lastPutCompressedComments: null,
  },
  reducers: {
    setResumeId: (state, { payload }) => {
      state.resumeId = payload;
    },
    setReviewId: (state, { payload }) => {
      state.reviewId = payload;
    },
  },
  extraReducers: {
    [createReview.fulfilled]: (state, { payload }) => {
      state.reviewId = payload.data.reviewId;
    },
    [pushAnnotationsToServer.fulfilled]: (state, { payload }) => {
      state.lastPutCompressedMarkup = payload.data.markup;
      state.lastPutCompressedComments = payload.data.comments;
    },
  },
});

export const { setResumeId, setReviewId } = reviewerApiSlice.actions;
export default reviewerApiSlice.reducer;
