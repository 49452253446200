export const VIEWPORT_SIZE = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

export function getViewport() {
  // https://stackoverflow.com/a/55368012
  const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  if (width <= 576) return VIEWPORT_SIZE.xs;
  if (width <= 768) return VIEWPORT_SIZE.sm;
  if (width <= 992) return VIEWPORT_SIZE.md;
  if (width <= 1200) return VIEWPORT_SIZE.lg;
  return VIEWPORT_SIZE.xl;
}
