import { faMousePointer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GA_CATEGORY, gaEvent } from '../../../helpers/googleAnalyticsHelpers';
import { selectCanvas } from '../../../redux/reducers/canvasSlice';
import { setTool } from '../../../redux/reducers/selectedToolSlice';
import RKHeaderButton from '../../design-system/RKHeaderButton';
import RKHelpTooltip from '../../design-system/RKHelpTooltip';
import ToolTypes from './SelectedToolTypes';

/* eslint-disable no-param-reassign */
function setSelectionMode(canvas) {
  if (canvas) {
    canvas.isDrawingMode = false;
    canvas.selection = false;
    canvas.forEachObject((o) => {
      o.selectable = false;
      o.evented = false;
    });

    canvas.off('mouse:down');
    canvas.off('mouse:move');
    canvas.off('mouse:up');
    canvas.off('object:added');
  }
}

function SelectionToolButton({ isSelected, disabled }) {
  const canvas = useSelector(selectCanvas);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSelected) {
      setSelectionMode(canvas);
    }
  }, [isSelected]);

  return (
    <>
      <RKHeaderButton
        id="selection-tool"
        isSelected={isSelected}
        canDeselect
        disabled={disabled}
        onClick={() => {
          gaEvent(GA_CATEGORY.REVIEWER, 'onSelectionButtonClick', 'Clicked SelectionToolButton');
          dispatch(setTool(ToolTypes.SELECTION));
        }}
      >
        <FontAwesomeIcon icon={faMousePointer} size="lg" />
      </RKHeaderButton>
      <RKHelpTooltip targetId="selection-tool">Move comment pins</RKHelpTooltip>
    </>
  );
}

SelectionToolButton.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

SelectionToolButton.defaultProps = {
  disabled: false,
};

export default SelectionToolButton;
