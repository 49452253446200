import { configureStore } from '@reduxjs/toolkit';

import {
  setLocalResumeIdAndReviewId,
  setLocalLoggedIn,
  setLocalUserInfo,
} from '../helpers/localStorageHelpers';
import canvasReducer from './reducers/canvasSlice';
import commentPinReducer from './reducers/commentPinsSlice';
import errorReducer, { setIsError, resetIsError } from './reducers/errorSlice';
import pdfMarkupStatusReducer from './reducers/pdfMarkupStatusSlice';
import resumesMetadataReducer from './reducers/resumesMetadataSlice';
import revieweePdfReducer from './reducers/revieweePdfSlice';
import reviewerApiReducer from './reducers/reviewerApiSlice';
import reviewerPdfReducer from './reducers/reviewerPdfSlice';
import reviewsMetadataReducer from './reducers/reviewsMetadataSlice';
import savedReviewApiReducer from './reducers/savedReviewApiSlice';
import scaleReducer from './reducers/scaleSlice';
import selectedToolReducer from './reducers/selectedToolSlice';
import userLoginReducer from './reducers/userLoginSlice';

const localStorageMiddleware = (storeAPI) => (next) => (action) => {
  const result = next(action);
  // Update localStorage if a new reviewId is created
  if (action.type === 'reviewerApi/createReview/fulfilled') {
    const reviewerApiState = storeAPI.getState().reviewerApi;
    setLocalResumeIdAndReviewId(reviewerApiState.resumeId, reviewerApiState.reviewId);
  } else if (
    action.type === 'userLogin/googleLogin/fulfilled' ||
    action.type === 'userLogin/linkedinLogin/fulfilled'
  ) {
    const userLoginState = storeAPI.getState().userLogin;
    const { firstName, lastName, profilePictureUrl, userId } = userLoginState;

    setLocalUserInfo({
      firstName,
      lastName,
      profilePictureUrl,
      userId,
    });
    setLocalLoggedIn(true);
  } else if (action.type === 'userLogin/logout/fulfilled') {
    setLocalLoggedIn(false);
  } else if (
    action.type === 'revieweePdf/uploadPdfStatus/rejected' ||
    action.type === 'resumesMetadata/fetchResumesMetadata/rejected'
  ) {
    const { loggedIn } = storeAPI.getState().userLogin;
    if (!loggedIn) {
      setLocalLoggedIn(false);
    }
  }
  return result;
};

const errorMiddleware = (storeAPI) => (next) => (action) => {
  const result = next(action);
  if (action.type.endsWith('rejected')) {
    storeAPI.dispatch(setIsError());
  } else if (action.type.endsWith('pending')) {
    storeAPI.dispatch(resetIsError());
  }
  return result;
};

export default configureStore({
  reducer: {
    canvas: canvasReducer,
    commentPins: commentPinReducer,
    savedReviewApi: savedReviewApiReducer,
    error: errorReducer,
    pdfMarkupStatus: pdfMarkupStatusReducer,
    resumesMetadata: resumesMetadataReducer,
    reviewerApi: reviewerApiReducer,
    reviewerPdf: reviewerPdfReducer,
    revieweePdf: revieweePdfReducer,
    reviewsMetadata: reviewsMetadataReducer,
    scale: scaleReducer,
    userLogin: userLoginReducer,
    selectedTool: selectedToolReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // The canvas is not serializable in the traditional sense, but we still want it
      // as a global in the redux store. See:
      // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      serializableCheck: {
        ignoredActions: [
          'canvas/changePage',
          'canvas/initCanvas',
          'canvas/resetCanvas',
          'reviewerPdf/setPdf',
          'revieweePdf/setPdf',
        ],
        ignoredPaths: ['canvas.canvas', 'reviewerPdf.pdf', 'revieweePdf.pdf'],
      },
      immutableCheck: {
        ignoredPaths: ['canvas.canvas', 'reviewerPdf.pdf', 'revieweePdf.pdf'],
      },
    }).concat([localStorageMiddleware, errorMiddleware]),
});
