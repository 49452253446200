/* eslint-disable no-param-reassign */
// (the `state` param of reducers is intended to be reassigned)
import { createSlice, isFulfilled } from '@reduxjs/toolkit';

import { fetchAnnotations } from './reviewerApiSlice';
import { fetchSavedAnnotations } from './savedReviewApiSlice';

// This is intended as a globally shared variable, it shouldn't have any
// actions dispatched past initialization
export const canvasSlice = createSlice({
  name: 'canvas',
  initialState: {
    // serializedMarkup is an object mapping int (page number) to string (serialized canvas).
    serializedMarkup: {},
    canvas: null, // `fabric.Canvas` object
  },
  reducers: {
    initCanvas: (state, { payload }) => {
      const { canvas } = payload;
      state.canvas = canvas;
    },
    resetCanvas: (state) => {
      state.canvas?.dispose();
      state.canvas = null;
      state.serializedMarkup = {};
    },
    changePage: (state, { payload }) => {
      const { oldPage, newPage } = payload;
      state.serializedMarkup[oldPage] = JSON.stringify(state.canvas);
      state.canvas.clear();
      if (newPage in state.serializedMarkup) {
        state.canvas.loadFromJSON(state.serializedMarkup[newPage]);
      }
    },
    setCanvasDimensions: (state, { payload }) => {
      const { height, width } = payload;
      state.canvas?.setDimensions({ height, width });
    },
    setCanvasZoom: (state, { payload }) => {
      state.canvas?.setZoom(payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isFulfilled(fetchAnnotations, fetchSavedAnnotations),
      (state, { payload }) => {
        // state.canvas will be null if ReviewCanvas/SavedReviewCanvas are unmounted (unmount calls resetCanvas)
        // before fetchAnnotations/fetchSavedAnnotations are fulfilled
        if (!state.canvas) {
          return;
        }

        state.serializedMarkup = JSON.parse(payload.markup);
        // Should never be false but pre-refactor markup could
        if (state.serializedMarkup[1]) {
          state.canvas.loadFromJSON(state.serializedMarkup[1]);
        }
      },
    );
  },
});

export const { changePage, initCanvas, resetCanvas, setCanvasDimensions, setCanvasZoom } =
  canvasSlice.actions;
export const selectCanvas = (state) => state.canvas.canvas;
export default canvasSlice.reducer;
