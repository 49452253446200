import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { formatDate } from '../../helpers/dateHelpers';
import { selectReviewerPdfName } from '../../redux/reducers/reviewerPdfSlice';
import {
  selectReviewerName,
  selectReviewerEmail,
  selectReviewedAt,
} from '../../redux/reducers/savedReviewApiSlice';
import RKCard from '../design-system/RKCard';
import RKContextText from '../design-system/RKContextText';
import RKEmphasisText from '../design-system/RKEmphasisText';
import CommentToolbar from './CommentToolbar';
import CommentToolbarDisplay from './CommentToolbarDisplay';

function SavedReviewSidebar({ isDisabled }) {
  const pdfName = useSelector(selectReviewerPdfName);
  const reviewerName = useSelector(selectReviewerName);
  const reviewerEmail = useSelector(selectReviewerEmail);
  const reviewedAt = useSelector(selectReviewedAt);

  const reviewerNameDisplay = !reviewerName || !reviewerName.trim() ? 'Anonymous' : reviewerName;
  const reviewerNameText =
    !reviewerEmail || !reviewerEmail.trim() ? (
      reviewerNameDisplay
    ) : (
      <a className="pdf-container-side-bar-link" href={`mailto:${reviewerEmail}`}>
        <RKEmphasisText>{reviewerNameDisplay}</RKEmphasisText>
      </a>
    );

  const getCommentDisplayContents = (uuid, text, timestamp, isSelected, dismissed) => (
    <CommentToolbarDisplay
      uuid={uuid}
      text={text}
      timestamp={timestamp}
      isSelected={isSelected}
      isDismissed={dismissed}
    />
  );

  return (
    <div className="pdf-container-side-bar">
      <CommentToolbar
        isDisabled={isDisabled}
        getCommentDisplayContents={getCommentDisplayContents}
      />
      {/* 
        Since this wrapping div is a flex box, margin collapsing wont be performed: 
        https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing.
        The following card must have its top margin manually turned off.
      */}
      <RKCard noTopMargin>
        <RKEmphasisText>{pdfName}</RKEmphasisText>
        <br />
        <RKContextText>
          Reviewed {reviewedAt && `on ${formatDate(reviewedAt)}`} by {reviewerNameText}
        </RKContextText>
      </RKCard>
    </div>
  );
}

SavedReviewSidebar.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export default SavedReviewSidebar;
