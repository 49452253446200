import React from 'react';
import Joyride from 'react-joyride';

export const HomeOnboardingStepTarget1 = 'home-onboarding-popover-1';
export const HomeOnboardingStepTarget2 = 'home-onboarding-popover-2';

export function HomeOnboardingJoyride() {
  const steps = [
    {
      target: `.${HomeOnboardingStepTarget1}`,
      content: (
        <div style={{ textAlign: 'left' }}>
          <h5>Getting started</h5>
          <br />
          <span>
            Upload your resume and generate a <b>share link</b> to start sending to reviewers!
          </span>
        </div>
      ),
      placement: 'auto',
      isFixed: true,
    },
    {
      target: `.${HomeOnboardingStepTarget2}`,
      content: (
        <div style={{ textAlign: 'left' }}>
          <h5>All reviews of your resume will appear here!</h5>
          <br />
          <span>
            Reviews will only show up once your reviewer has pressed <b>Finish</b>.
          </span>
        </div>
      ),
      placement: 'auto',
    },
  ];

  return (
    <Joyride
      steps={steps}
      continuous
      showProgress
      disableScrolling
      styles={{
        options: {
          // Hint colour
          primaryColor: 'var(--secondary-light)',
        },
      }}
    />
  );
}
