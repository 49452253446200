// toLocaleString options: https://stackoverflow.com/a/17743990
export const formatDate = (timestamp) => {
  if (!timestamp) {
    return null;
  }

  return new Date(timestamp).toLocaleString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const formatTime = (timestamp) => {
  if (!timestamp) {
    return null;
  }

  return new Date(timestamp).toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  });
};
