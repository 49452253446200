import { faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'reactstrap';

import { GA_CATEGORY, gaEvent } from '../../helpers/googleAnalyticsHelpers';
import {
  selectRevieweePdfId,
  selectRevieweePdfNotes,
  updatePdfData,
} from '../../redux/reducers/revieweePdfSlice';
import RKCard from '../design-system/RKCard';
import RKContextText from '../design-system/RKContextText';
import ShareModal from './ShareModal';
import { UploadOnboardingStepTarget } from './UploadOnboardingJoyride';

import './css/ShareCard.css';

const NOTES_PUSH_FREQUENCY_MS = 10 * 1000;

function ShareCard({ initialShareOpen, disabled }) {
  const dispatch = useDispatch();
  const pdfId = useSelector(selectRevieweePdfId);
  const storedNotes = useSelector(selectRevieweePdfNotes);
  const storedNotesRef = useRef(storedNotes);
  const setStoredNotesRef = (data) => {
    storedNotesRef.current = data;
  };

  const [notes, _setNotes] = useState(storedNotes);
  const notesRef = useRef(notes);
  const setNotes = (data) => {
    notesRef.current = data;
    _setNotes(data);
  };

  const [modalOpen, setModalOpen] = useState(initialShareOpen);
  const toggle = () => {
    gaEvent(GA_CATEGORY.REVIEWEE, 'onShareButtonClick', 'Clicked ShareButton');
    setModalOpen(!modalOpen);
  };

  // Write notes to the server every interval & before closing the tab.
  useEffect(() => {
    if (!pdfId) {
      return;
    }
    const pushNotes = () => {
      if (storedNotesRef.current !== notesRef.current) {
        dispatch(updatePdfData({ resumeId: pdfId, notes: notesRef.current }));
      }
    };
    const intervalId = setInterval(pushNotes, NOTES_PUSH_FREQUENCY_MS);
    window.addEventListener('beforeunload', pushNotes);
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('beforeunload', pushNotes);
      pushNotes();
    };
  }, [pdfId]);

  useEffect(() => {
    setNotes(storedNotes);
    setStoredNotesRef(storedNotes);
  }, [storedNotes]);

  const shareOnClickHandler = () => {
    dispatch(updatePdfData({ resumeId: pdfId, notes }));
    toggle();
  };

  return (
    <RKCard className="share-card">
      Attach notes to your resume
      <Input
        type="textarea"
        value={notes}
        onChange={(e) => {
          setNotes(e.target.value);
        }}
        disabled={disabled}
      />
      <RKContextText>All reviewers can see notes after sharing</RKContextText>
      <div className="share-card-sharebutton">
        <Button
          className={`primary-button ${UploadOnboardingStepTarget}`}
          disabled={disabled}
          onClick={shareOnClickHandler}
        >
          <h4>
            Share <FontAwesomeIcon icon={faShare} />
          </h4>
        </Button>
      </div>
      <ShareModal open={modalOpen} toggle={toggle} pdfId={pdfId} />
    </RKCard>
  );
}

ShareCard.propTypes = {
  initialShareOpen: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

ShareCard.defaultProps = {
  disabled: false,
};

export default ShareCard;
