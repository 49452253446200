import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import env from '../../helpers/envHelpers';
import { getLocalLinkedInState, setLocalLinkedInState } from '../../helpers/localStorageHelpers';
import { linkedinLogin } from '../../redux/reducers/userLoginSlice';
import './css/LinkedInLogin.css';

const OAUTH_URL = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code';
const REDIRECT_URL = `${env('RVKT_HOST')}/login`;

function LinkedInLogin({ setLoginFailed }) {
  const dispatch = useDispatch();

  const handleLinkedInLoginSuccess = (code) => {
    dispatch(linkedinLogin({ code, redirectUri: REDIRECT_URL }));
    setLoginFailed(false);
  };

  const handleLinkedInLoginFailure = () => {
    setLoginFailed(true);
    // Clear error query params so error doesn't persist on refresh
    window.history.replaceState({}, null, '/login');
  };

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    const savedState = getLocalLinkedInState();
    if (queryParams?.code && savedState === queryParams?.state) {
      handleLinkedInLoginSuccess(queryParams?.code);
    } else if (queryParams?.error) {
      handleLinkedInLoginFailure();
    }
  }, []);

  const onClick = () => {
    // Generate random string (https://stackoverflow.com/a/8084248)
    const state = (Math.random() + 1).toString(36).substring(2);
    setLocalLinkedInState(state);
    const url = `${OAUTH_URL}&client_id=${env(
      'LINKEDIN_CLIENT_ID',
    )}&scope=r_liteprofile%20r_emailaddress&state=${state}&redirect_uri=${REDIRECT_URL}`;
    const width = 450;
    const height = 730;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    window.open(
      url,
      'Linkedin',
      `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${width}, height=${height}, top=${top}, left=${left}`,
    );
  };

  return (
    <button type="button" className="linkedIn-button" onClick={onClick}>
      <FontAwesomeIcon icon={faLinkedin} />
      Sign in with Linkedin
    </button>
  );
}

LinkedInLogin.propTypes = {
  setLoginFailed: PropTypes.func.isRequired,
};

export default LinkedInLogin;
