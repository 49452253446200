import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import {
  fetchResumesMetadata,
  selectResumesMetadata,
} from '../../redux/reducers/resumesMetadataSlice';
import { selectUserData } from '../../redux/reducers/userLoginSlice';
import RKNavButton from '../design-system/RKNavButton';
import AnimatedGridWrapper from '../shared/AnimatedGridWrapper';
import './css/MyGrid.css';
import './css/AllResumesGrid.css';
import ResumeItem from './ResumeItem';

export default function AllResumesGrid() {
  const history = useHistory();

  const dispatch = useDispatch();
  const { userId } = useSelector(selectUserData);
  const resumesMetadata = useSelector(selectResumesMetadata);
  const gridId = 'all-resumes-grid-container';

  const gridContents = [];
  gridContents.push(
    ...resumesMetadata
      .slice()
      .sort((a, b) => b.uploadedAt - a.uploadedAt)
      .map((resume) => (
        <Col className="item-container" key={resume.resumeId}>
          <ResumeItem resume={resume} containerId={gridId} />
        </Col>
      )),
  );

  useEffect(() => {
    dispatch(fetchResumesMetadata(userId));
  }, [dispatch, userId]);

  return (
    <div id={gridId} className="all-resumes-container">
      <div id={gridId} className="my-grid-container">
        <div className="all-resumes-header">
          <RKNavButton
            left
            onClick={() => {
              history.push('/home');
            }}
          >
            Home
          </RKNavButton>
          <div className="all-resumes-header-title">All Resumes</div>
        </div>
        <AnimatedGridWrapper /* AnimatedGridWrapper must only wrap 1 child */>
          <Row className="my-grid" lg="4" md="3" noGutters xl="5">
            {gridContents}
          </Row>
        </AnimatedGridWrapper>
      </div>
    </div>
  );
}
