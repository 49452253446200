import { faShare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Row, Button } from 'reactstrap';

import { formatDate } from '../../helpers/dateHelpers';
import { deleteResume } from '../../redux/reducers/revieweePdfSlice';
import RKCheckbox from '../design-system/RKCheckbox';
import RKContextText from '../design-system/RKContextText';
import RKEmphasisText from '../design-system/RKEmphasisText';
import RKHelpTooltip from '../design-system/RKHelpTooltip';
import ThumbnailPlaceholder from './assets/thumbnail-placeholder.png';
import DeletePopover from './DeletePopover';

function ResumeItem({ resume, containerId }) {
  const dispatch = useDispatch();
  const { name, resumeId, thumbnailUrl = '', uploadedAt } = resume;
  const date = uploadedAt ? formatDate(uploadedAt) : '';

  const shareButtonId = `grid-share-${resumeId}`;
  const deleteButtonId = `grid-delete-${resumeId}`;

  const history = useHistory();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [deleteAllReviews, setDeleteAllReviews] = useState(false);
  const toggleDeleteAllReviews = () => setDeleteAllReviews(!deleteAllReviews);

  const deleteButtonAction = () => {
    dispatch(deleteResume({ resumeId, deleteAllReviews }));
  };

  return (
    <div className="grid-item-container grid-item-container-clickable">
      <div className="grid-item-overlay">
        <RKEmphasisText className="text-overflow-ellipsis">{name}</RKEmphasisText>
        <Row noGutters className="grid-item-overlay-info">
          <RKContextText>{date}</RKContextText>
          <div className="grid-item-fill-row" />
          <FontAwesomeIcon
            id={shareButtonId}
            className="grid-item-share-button"
            icon={faShare}
            onClick={() => {
              history.push(`/upload/${resumeId}?share=true`);
            }}
          />
          <FontAwesomeIcon
            id={deleteButtonId}
            className={`grid-item-delete-button ${
              tooltipOpen && 'grid-item-delete-button-selected'
            }`}
            icon={faTrash}
            onClick={() => setTooltipOpen(!tooltipOpen)}
          />
          <DeletePopover
            isOpen={tooltipOpen}
            setTooltipOpen={setTooltipOpen}
            targetId={deleteButtonId}
            resumeId={resumeId}
            scrollableContainerId={containerId}
          >
            <RKCheckbox
              label="Also delete associated reviews"
              checked={deleteAllReviews}
              onChange={toggleDeleteAllReviews}
            />
            <br />
            <RKContextText small={false}>Resumes cannot be recovered after deleting</RKContextText>
            <Button className="danger-button" onClick={deleteButtonAction}>
              <small>Delete Resume</small>
            </Button>
          </DeletePopover>
        </Row>
        <RKHelpTooltip targetId={shareButtonId}>Generate share link</RKHelpTooltip>
        <RKHelpTooltip disabled={tooltipOpen} targetId={deleteButtonId}>
          Delete resume
        </RKHelpTooltip>
      </div>
      <Link to={{ pathname: `/upload/${resumeId}`, state: { from: window.location.pathname } }}>
        <img
          alt="Resume thumbnail"
          className="grid-item"
          onError={(e) => {
            e.target.src = ThumbnailPlaceholder;
          }}
          src={thumbnailUrl || ThumbnailPlaceholder}
        />
      </Link>
    </div>
  );
}

ResumeItem.propTypes = {
  resume: PropTypes.exact({
    name: PropTypes.string.isRequired,
    resumeId: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string,
    uploadedAt: PropTypes.number.isRequired,
  }).isRequired,
  containerId: PropTypes.string,
};

ResumeItem.defaultProps = {
  containerId: null,
};

export default ResumeItem;
