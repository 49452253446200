import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'reactstrap';

import THUNK_STATUS from '../../helpers/reduxHelpers';
import { selectLoggedInStatus } from '../../redux/reducers/userLoginSlice';
import GoogleLogin from './GoogleLogin';
import LinkedInLogin from './LinkedInLogin';
import './css/Login.css';

function Login() {
  const [loginFailed, setLoginFailed] = useState(false);

  const status = useSelector(selectLoggedInStatus);

  return (
    <div className="login-container">
      <GoogleLogin setLoginFailed={setLoginFailed} />
      <br />
      <LinkedInLogin setLoginFailed={setLoginFailed} />
      {(status === THUNK_STATUS.FAILED || loginFailed) && (
        <Alert color="danger">
          There was a problem logging in. Make sure cookies are enabled and try again.
        </Alert>
      )}
    </div>
  );
}

export default Login;
