import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { updatePinScale } from '../../redux/reducers/commentPinsSlice';

import './css/ZoomWrapper.css';

// Reasonable defaults, change if something feels bad
const DEFAULT_DELTA_Y = 12;
const MAX_SCALE = 3;
const MIN_SCALE = 1;
const SCALE_RATIO = 0.02;
const SCROLL_RATIO = 1;
const MAX_ZOOM_INCREMENT = 12;
let Y_OVERFILL = 500;
let X_OVERFILL = 500;

function ZoomWrapper({ children }) {
  const dispatch = useDispatch();
  const zoomFrameRef = useRef(null);
  const zoomRef = useRef(null);

  let scale = 1;
  let translateX = 0;
  let translateY = 0;
  // Only used for safari events
  let scaleModifer = 1;

  const updateStyle = (updateScale, updateX, updateY) => {
    zoomRef.current.style.transform = `translate3d(${updateX}px, ${updateY}px, 0px) scale(${updateScale})`;
  };

  const boundScale = (rawScale) => {
    return Math.min(MAX_SCALE, Math.max(MIN_SCALE, rawScale));
  };

  const boundTranslate = () => {
    Y_OVERFILL = zoomFrameRef.current.clientHeight / 2;
    X_OVERFILL = zoomFrameRef.current.clientWidth / 2;
    const totScale = scale * scaleModifer;
    translateX = Math.min(
      X_OVERFILL * (totScale - 1),
      Math.max(-X_OVERFILL * (totScale - 1), translateX),
    );
    translateY = Math.min(
      Y_OVERFILL * (totScale - 1),
      Math.max(-Y_OVERFILL * (totScale - 1), translateY),
    );
  };

  const handleZoom = (e) => {
    // Key events don't have a deltaY, so provide a default
    // A key press of + requires multiplying by -1
    const deltaY = (e.deltaY ?? DEFAULT_DELTA_Y) * (e.keyCode === 187 ? -1 : 1);

    // this same event is fired for both pinch zoom and ctrl + scrollwheel
    //   - pinch zoom: many handleZoom events, each with deltaY < 10
    //   - ctrl + scrollwheel: single handleZoom event, with large deltaY (150 for james)
    // to avoid ctrl + scrollwheel zooming in too much, clamp the delta magnitude to 10
    let boundDeltaY = deltaY;
    if (boundDeltaY > MAX_ZOOM_INCREMENT) {
      boundDeltaY = MAX_ZOOM_INCREMENT;
    } else if (boundDeltaY < -MAX_ZOOM_INCREMENT) {
      boundDeltaY = -MAX_ZOOM_INCREMENT;
    }
    scale -= boundDeltaY * SCALE_RATIO;
    scale = boundScale(scale);
    boundTranslate();
    dispatch(updatePinScale(scale));
  };

  const handleScroll = (e) => {
    translateX -= e.deltaX * SCROLL_RATIO;
    translateY -= e.deltaY * SCROLL_RATIO;
    boundTranslate();
  };

  const handleWheel = (e) => {
    e.preventDefault();
    // scroll + ctrl = pinch zoom for most browsers (except safari)
    if (e.ctrlKey) {
      handleZoom(e);
    } else {
      handleScroll(e);
    }
    updateStyle(scale, translateX, translateY);
  };

  const handleKeyDown = (e) => {
    // Disable ctrl +/- zooming on Mac since convention is to use cmd key
    // + keyCode 187, - keyCode 189
    if (
      ((e.ctrlKey && navigator.userAgent.indexOf('Mac') === -1) || e.metaKey) &&
      (e.keyCode === 187 || e.keyCode === 189)
    ) {
      e.preventDefault();
      handleZoom(e);
      updateStyle(scale, translateX, translateY);
    }
  };

  const handleGesture = (e) => {
    e.preventDefault();
    scaleModifer = boundScale(scale * e.scale) / scale;
    boundTranslate();
    updateStyle(scale * scaleModifer, translateX, translateY);
    dispatch(updatePinScale(scale * scaleModifer));
  };

  const handleGestureEnd = (e) => {
    e.preventDefault();
    scale = boundScale(scale * e.scale);
    dispatch();
    scaleModifer = 1;
    boundTranslate();
    updateStyle(scale, translateX, translateY);
    dispatch(updatePinScale(scale));
  };

  useEffect(() => {
    zoomFrameRef.current.addEventListener('wheel', handleWheel, { passive: false });

    // Keydown event needs to listen to the window since the zoom ref can't be focused
    window.addEventListener('keydown', handleKeyDown, { passive: false });

    // Safari pinch events
    zoomFrameRef.current.addEventListener('gesturestart', handleGesture, { passive: false });
    zoomFrameRef.current.addEventListener('gesturechange', handleGesture, { passive: false });
    zoomFrameRef.current.addEventListener('gestureend', handleGestureEnd, { passive: false });
    return () => {
      zoomFrameRef.current.removeEventListener('wheel', handleWheel);
      window.removeEventListener('keydown', handleKeyDown);
      zoomFrameRef.current.removeEventListener('gesturestart', handleGesture);
      zoomFrameRef.current.removeEventListener('gesturechange', handleGesture);
      zoomFrameRef.current.removeEventListener('gestureend', handleGestureEnd);
    };
  }, []);

  return (
    <div ref={zoomFrameRef} className="zoom-frame">
      <div ref={zoomRef} className="zoom-item">
        {children}
      </div>
    </div>
  );
}

ZoomWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ZoomWrapper;
