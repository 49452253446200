import { faUndo, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import canvasEventEmitter, { canvasAction } from '../../../helpers/canvasEventEmitter';
import { selectCanvas } from '../../../redux/reducers/canvasSlice';
import RKHelpTooltip from '../../design-system/RKHelpTooltip';
import ToolTypes from './SelectedToolTypes';

// Undo/Redo stack hold events of the form:
// {
//   type: ADD | DELETE | MOVE
//   old: (data that existed before action)
//   new: (data that exists after action)
// }
function UndoRedoToolButton({ disabled, selectedTool }) {
  const canvas = useSelector(selectCanvas);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  function undo() {
    if (undoStack.length === 0) return;
    const undoAction = undoStack[0];
    setUndoStack((oldUndoStack) => oldUndoStack.slice(1));
    setRedoStack((oldRedoStack) => [undoAction, ...oldRedoStack]);
    switch (undoAction.action) {
      case canvasAction.ADD:
        canvas.remove(undoAction.new);
        break;
      case canvasAction.DELETE:
        undoAction.old.forEach((oldObj) => canvas.add(oldObj));
        break;
      case canvasAction.MOVE:
        break;
      default:
        break;
    }
  }

  function redo() {
    if (redoStack.length === 0) return;
    const redoAction = redoStack[0];
    setRedoStack((oldRedoStack) => oldRedoStack.slice(1));
    setUndoStack((oldUndoStack) => [redoAction, ...oldUndoStack]);
    switch (redoAction.action) {
      case canvasAction.ADD:
        canvas.add(redoAction.new);
        break;
      case canvasAction.DELETE:
        redoAction.old.forEach((oldObj) => canvas.remove(oldObj));
        break;
      case canvasAction.MOVE:
        break;
      default:
        break;
    }
  }

  function handleKeyPress(e) {
    if (
      (e.keyCode === 90 && e.ctrlKey && !e.shiftKey) ||
      (e.keyCode === 90 && e.metaKey && !e.shiftKey)
    ) {
      undo();
      e.preventDefault();
    }
    if (
      (e.keyCode === 90 && e.ctrlKey && e.shiftKey) ||
      (e.keyCode === 90 && e.metaKey && e.shiftKey)
    ) {
      redo();
      e.preventDefault();
    }
  }

  // Listen to changes of undo/redo stack to renew callback state,
  // referenced here: https://stackoverflow.com/a/60643670
  useEffect(() => {
    canvasEventEmitter.subscribe('undoRedo', 'action', (data) => {
      setUndoStack((oldUndoStack) => [data, ...oldUndoStack]);
      setRedoStack([]);
    });
    window.addEventListener('keydown', handleKeyPress);
    if (selectedTool === ToolTypes.COMMENT) {
      window.removeEventListener('keydown', handleKeyPress);
    }
    return () => {
      canvasEventEmitter.unsubscribe('undoRedo', 'action');
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [undoStack, redoStack, selectedTool]);

  return (
    <>
      <div className="review-header-undo-redo">
        <button
          id="undo-tool"
          type="button"
          disabled={disabled || undoStack.length === 0}
          onClick={undo}
        >
          <FontAwesomeIcon icon={faUndo} />
        </button>
        <button
          id="redo-tool"
          type="button"
          disabled={disabled || redoStack.length === 0}
          onClick={redo}
        >
          <FontAwesomeIcon icon={faRedo} />
        </button>
      </div>
      {undoStack.length > 0 && <RKHelpTooltip targetId="undo-tool">Undo drawings</RKHelpTooltip>}
      {redoStack.length > 0 && <RKHelpTooltip targetId="redo-tool">Redo drawings</RKHelpTooltip>}
    </>
  );
}

UndoRedoToolButton.propTypes = {
  disabled: PropTypes.bool,
  selectedTool: PropTypes.oneOf(Object.values(ToolTypes)).isRequired,
};

UndoRedoToolButton.defaultProps = {
  disabled: false,
};

export default UndoRedoToolButton;
