import PropTypes from 'prop-types';
import React from 'react';
import './css/RKCard.css';

/**
 * A card component to display contents in a floating container.
 * Contents can be information or interactable.
 */
function RKCard({ children, className, center, noTopMargin }) {
  let styling = center ? `rkcard rkcard-center ${className}` : `rkcard ${className}`;
  if (noTopMargin) {
    styling += ' rkcard-no-top-margins';
  } else {
    styling += ' rkcard-default-margins';
  }
  return <div className={styling}>{children}</div>;
}

RKCard.propTypes = {
  /**
   * Contents of the card.
   */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  ]).isRequired,
  /**
   * Any additional styling
   */
  className: PropTypes.string,
  /**
   * A flag to set whether contents should be centered.
   */
  center: PropTypes.bool,
  /**
   * A flag to remove the top margin. This prop is useful in cases where margin collapsing does not get performed.
   */
  noTopMargin: PropTypes.bool,
};

RKCard.defaultProps = {
  center: false,
  className: '',
  noTopMargin: false,
};

export default RKCard;
