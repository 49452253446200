import { setPdfRenderStatus } from '../redux/reducers/pdfMarkupStatusSlice';
import { setPdf, uploadResume } from '../redux/reducers/revieweePdfSlice';
import PDF_RENDER_STATUS from './pdfHelpers';

export default function loadResume(dispatch, file, history, redirectPath = '') {
  dispatch(setPdfRenderStatus(PDF_RENDER_STATUS.LOADING));
  const fileReader = new FileReader();

  fileReader.onload = (loadEvent) => {
    // Upload file, non-PDF uploads will still trigger an error toast
    dispatch(uploadResume(file));

    // Don't redirect or set local pdf value when file wasn't a PDF (fail faster)
    if (file.type !== 'application/pdf') {
      return;
    }

    dispatch(setPdf(Array.from(new Uint8Array(loadEvent.target.result))));

    if (redirectPath) {
      history?.push(redirectPath);
    }
  };
  fileReader.readAsArrayBuffer(file);
}
