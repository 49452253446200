import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import ErrorToastRenderer from '../components/shared/ErrorToastRenderer';
import Header from '../components/shared/Header';
import PdfContainer from '../components/shared/PdfContainer';
import UploadEmpty from '../components/upload/UploadEmpty';
import { UploadOnboardingJoyride } from '../components/upload/UploadOnboardingJoyride';
import UploadSidebar from '../components/upload/UploadSidebar';
import { gaPageview } from '../helpers/googleAnalyticsHelpers';
import THUNK_STATUS from '../helpers/reduxHelpers';
import { resetLoadStatus } from '../redux/reducers/pdfMarkupStatusSlice';
import {
  fetchResume,
  resetResume,
  selectRevieweePdf,
  selectRevieweePdfId,
  selectFetchResumeStatus,
  selectUploadResumeStatus,
} from '../redux/reducers/revieweePdfSlice';
import { selectUserLoginState } from '../redux/reducers/userLoginSlice';

import './css/Page.css';
import './css/UploadView.css';
import '../style/css/Sticky.css';

/* eslint-disable react/prop-types */
function UploadView({ match, location }) {
  gaPageview();
  const dispatch = useDispatch();
  const { resumeId: resumePathId } = match.params;
  const resumeUploadId = useSelector(selectRevieweePdfId);
  const pdfData = useSelector(selectRevieweePdf);
  const resumeFetchStatus = useSelector(selectFetchResumeStatus);
  const resumeUploadStatus = useSelector(selectUploadResumeStatus);
  const isEmpty = !resumePathId && !pdfData;

  // Store if the user has visited this page before in local storage for onboarding
  const [firstVisitToPage, setFirstVisitToPage] = useState(false);
  useEffect(() => {
    setFirstVisitToPage(!localStorage.getItem('wasUploadViewVisited'));
    localStorage.setItem('wasUploadViewVisited', 1);
  }, []);

  const initialShareOpen = queryString.parse(window.location.search).share === 'true';
  // on mount so fetch is only performed if navigating to /upload/id and not if history.replaceState
  useEffect(() => {
    if (resumePathId) {
      dispatch(fetchResume(resumePathId));
    }
  }, []);

  // ensure url is /upload while uploading
  // ie. /upload/error-id ==> /upload when uploading from empty component
  useEffect(() => {
    if (resumeUploadStatus === THUNK_STATUS.LOADING) {
      window.history.replaceState({}, null, `/upload`);
    }
  }, [resumeUploadStatus]);

  // resumeUploadId can change if fetch fails and a new upload is made
  useEffect(() => {
    if (resumeUploadId) {
      window.history.replaceState({}, null, `/upload/${resumeUploadId}`);
    }
  }, [resumeUploadId]);

  useEffect(
    () => () => {
      dispatch(resetResume());
      dispatch(resetLoadStatus());
    },
    [],
  );

  const loggedIn = useSelector(selectUserLoginState);
  const history = useHistory();

  if (!loggedIn) {
    history.replace('/login');
  }

  return (
    <div className="page-flex-container upload-view">
      {/* Only show onboarding guide in first visit */}
      {firstVisitToPage && <UploadOnboardingJoyride />}
      <ErrorToastRenderer />
      <div className="sticky-header">
        <Header />
      </div>
      <Row noGutters className="page-fill-height">
        <Col sm="9">
          {isEmpty ||
          resumeFetchStatus === THUNK_STATUS.FAILED ||
          resumeUploadStatus === THUNK_STATUS.FAILED ? (
            <UploadEmpty />
          ) : (
            <PdfContainer
              pdfData={pdfData}
              showBackButton
              backButtonLink={location?.state?.from ?? '/home'}
            />
          )}
        </Col>
        <Col sm="3">
          <UploadSidebar
            disabled={!pdfData}
            resumeId={resumeUploadId}
            initialShareOpen={initialShareOpen}
          />
        </Col>
      </Row>
    </div>
  );
}

export default UploadView;
