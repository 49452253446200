import env from './envHelpers';

function getKeyedLocalStorage() {
  const localStorageStr = window.localStorage.getItem(env('RVKT_LOCALSTORAGE_KEY'));
  return JSON.parse(localStorageStr || '{}');
}

export function getLocalReviewIdFromResumeId(resumeId) {
  const localStorageObj = getKeyedLocalStorage();
  return localStorageObj[resumeId];
}

export function setLocalResumeIdAndReviewId(resumeId, reviewId) {
  const localStorageObj = getKeyedLocalStorage();
  localStorageObj[resumeId] = reviewId;
  window.localStorage.setItem(env('RVKT_LOCALSTORAGE_KEY'), JSON.stringify(localStorageObj));
}

export function clearLocalReviewId(resumeId) {
  setLocalResumeIdAndReviewId(resumeId, null);
}

export function getLocalLoggedIn() {
  const localStorageObj = getKeyedLocalStorage();
  return localStorageObj.loggedIn || false;
}

export function setLocalLoggedIn(loggedIn) {
  const localStorageObj = getKeyedLocalStorage();
  localStorageObj.loggedIn = loggedIn;
  window.localStorage.setItem(env('RVKT_LOCALSTORAGE_KEY'), JSON.stringify(localStorageObj));
}

export function getLocalUserInfo() {
  const localStorageObj = getKeyedLocalStorage();
  return localStorageObj.userInfo || {};
}

export function setLocalUserInfo(userInfo) {
  const localStorageObj = getKeyedLocalStorage();
  localStorageObj.userInfo = userInfo;
  window.localStorage.setItem(env('RVKT_LOCALSTORAGE_KEY'), JSON.stringify(localStorageObj));
}
export function getLocalLinkedInState() {
  const localStorageObj = getKeyedLocalStorage();
  return localStorageObj.linkedInState || {};
}

export function setLocalLinkedInState(state) {
  const localStorageObj = getKeyedLocalStorage();
  localStorageObj.linkedInState = state;
  window.localStorage.setItem(env('RVKT_LOCALSTORAGE_KEY'), JSON.stringify(localStorageObj));
}
