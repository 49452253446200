import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { GA_CATEGORY, gaEvent } from '../../../helpers/googleAnalyticsHelpers';
import { selectCanvas } from '../../../redux/reducers/canvasSlice';
import { addPin } from '../../../redux/reducers/commentPinsSlice';
import { selectScaleRatio } from '../../../redux/reducers/scaleSlice';
import { setTool } from '../../../redux/reducers/selectedToolSlice';
import RKHeaderButton from '../../design-system/RKHeaderButton';
import RKHelpTooltip from '../../design-system/RKHelpTooltip';
import ToolTypes from './SelectedToolTypes';

/* eslint-disable no-param-reassign */
function setCommentMode(canvas) {
  canvas.isDrawingMode = false;
  canvas.selection = false;
  canvas.forEachObject((o) => {
    o.selectable = false;
    o.evented = false;
  });

  canvas.off('mouse:down');
  canvas.off('mouse:move');
  canvas.off('mouse:up');
  canvas.off('object:added');
}

function CommentToolButton({ isSelected, disabled }) {
  const dispatch = useDispatch();
  const canvas = useSelector(selectCanvas);
  const scaleRatio = useSelector(selectScaleRatio);

  const onMouseUp = (e) => {
    gaEvent(GA_CATEGORY.REVIEWER, 'onPinDrop', 'Placed a CommentPin');
    const newPinObj = {
      uuid: uuidv4(),
      xPos: e.pointer.x / scaleRatio,
      yPos: e.pointer.y / scaleRatio,
      text: '',
      timestamp: Date.now(),
      isSelected: true,
    };
    dispatch(addPin({ pinData: newPinObj }));
  };

  const onCommentButtonClick = (e) => {
    gaEvent(GA_CATEGORY.REVIEWER, 'onCommentButtonClick', 'Clicked CommentToolButton');
    if (isSelected) {
      dispatch(setTool(ToolTypes.SELECTION));
      e.currentTarget.blur();
    } else {
      dispatch(setTool(ToolTypes.COMMENT));
    }
  };

  useEffect(() => {
    if (isSelected) {
      setCommentMode(canvas);
    }
  }, [isSelected]);

  // Rebind onMouseUp to canvas when scaleRatio changes to work around stale closure of onMouseUp
  // and onCommentButtonClick
  useEffect(() => {
    if (isSelected) {
      canvas?.off('mouse:up');
      canvas?.on('mouse:up', onMouseUp);
    }
  }, [isSelected, scaleRatio]);

  return (
    <>
      <RKHeaderButton
        id="comment-tool"
        isSelected={isSelected}
        canDeselect
        disabled={disabled || !canvas}
        onClick={onCommentButtonClick}
      >
        <FontAwesomeIcon icon={faComment} size="lg" />
      </RKHeaderButton>
      <RKHelpTooltip targetId="comment-tool">Add comment pins</RKHelpTooltip>
    </>
  );
}

CommentToolButton.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CommentToolButton.defaultProps = {
  disabled: false,
};

export default CommentToolButton;
