import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';

import env, { APP_ENV } from '../../helpers/envHelpers';
import Header from './Header';
import './css/ErrorBoundary.css';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorInfo: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError, errorInfo } = this.state;
    if (hasError) {
      return (
        <>
          <Header />
          <div className="errorboundary">
            <div>Something went wrong.</div>
            <Button
              onClick={() => {
                const { history } = this.props;
                // Refresh page
                history.go(0);
              }}
            >
              Refresh
            </Button>
            {env('APP_ENV') === APP_ENV.DEVELOPMENT && (
              <div>
                <details>
                  <summary>Click for error details.</summary>
                  {errorInfo && errorInfo.componentStack.toString()}
                </details>
              </div>
            )}
          </div>
        </>
      );
    }

    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  history: PropTypes.shape({
    go: PropTypes.func.isRequired,
  }),
};

ErrorBoundary.defaultProps = {
  history: {
    go: () => {},
  },
};

export default withRouter(ErrorBoundary);
