import { resetCanvas } from '../redux/reducers/canvasSlice';
import { resetPins } from '../redux/reducers/commentPinsSlice';
import { resetLoadStatus } from '../redux/reducers/pdfMarkupStatusSlice';
import { resetResume } from '../redux/reducers/reviewerPdfSlice';
import { clearLocalReviewId } from './localStorageHelpers';

export default (dispatch, resumeId) => {
  clearLocalReviewId(resumeId);
  dispatch(resetResume());
  dispatch(resetPins());
  dispatch(resetCanvas());
  dispatch(resetLoadStatus());
};
