/* eslint-disable no-param-reassign */
// (the `state` param of reducers is intended to be reassigned)
import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import PDF_RENDER_STATUS from '../../helpers/pdfHelpers';
import THUNK_STATUS from '../../helpers/reduxHelpers';
import { createReview, fetchAnnotations } from './reviewerApiSlice';
import { fetchSavedAnnotations } from './savedReviewApiSlice';

export const pdfMarkupStatusSlice = createSlice({
  name: 'pdfMarkupStatus',
  initialState: {
    annotationsLoadStatus: THUNK_STATUS.WAITING,
    pdfRenderStatus: PDF_RENDER_STATUS.WAITING,
    scaleFinished: false,
  },
  reducers: {
    resetLoadStatus: () => ({
      annotationsLoadStatus: THUNK_STATUS.WAITING,
      pdfRenderStatus: PDF_RENDER_STATUS.WAITING,
      scaleFinished: false,
    }),
    setPdfRenderStatus: (state, { payload }) => ({
      ...state,
      pdfRenderStatus: payload,
    }),
    setScaleFinished: (state) => ({
      ...state,
      scaleFinished: true,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending(createReview, fetchAnnotations, fetchSavedAnnotations), (state) => {
        state.annotationsLoadStatus = THUNK_STATUS.LOADING;
      })
      .addMatcher(isFulfilled(createReview, fetchAnnotations, fetchSavedAnnotations), (state) => {
        state.annotationsLoadStatus = THUNK_STATUS.SUCCEEDED;
      })
      .addMatcher(isRejected(createReview, fetchAnnotations, fetchSavedAnnotations), (state) => {
        state.annotationsLoadStatus = THUNK_STATUS.FAILED;
      });
  },
});

export const { resetLoadStatus, setPdfRenderStatus, setScaleFinished } =
  pdfMarkupStatusSlice.actions;
export const selectPdfRenderStatus = (state) => state.pdfMarkupStatus.pdfRenderStatus;
export const selectPdfIsLoading = (state) => {
  const { pdfRenderStatus } = state.pdfMarkupStatus;
  return pdfRenderStatus === PDF_RENDER_STATUS.LOADING;
};
export const selectPdfMarkupIsComplete = (state) => {
  const { annotationsLoadStatus, pdfRenderStatus, scaleFinished } = state.pdfMarkupStatus;
  return (
    annotationsLoadStatus === THUNK_STATUS.SUCCEEDED &&
    pdfRenderStatus === PDF_RENDER_STATUS.SUCCEEDED &&
    scaleFinished
  );
};
export const selectPdfMarkupIsLoading = (state) => {
  const { annotationsLoadStatus, pdfRenderStatus, scaleFinished } = state.pdfMarkupStatus;
  return (
    annotationsLoadStatus === THUNK_STATUS.LOADING ||
    pdfRenderStatus === PDF_RENDER_STATUS.LOADING ||
    (annotationsLoadStatus === THUNK_STATUS.SUCCEEDED &&
      pdfRenderStatus !== THUNK_STATUS.SUCCEEDED) ||
    (annotationsLoadStatus !== THUNK_STATUS.SUCCEEDED &&
      pdfRenderStatus === THUNK_STATUS.SUCCEEDED) ||
    (!scaleFinished &&
      annotationsLoadStatus === THUNK_STATUS.SUCCEEDED &&
      pdfRenderStatus === THUNK_STATUS.SUCCEEDED) ||
    (scaleFinished &&
      annotationsLoadStatus === THUNK_STATUS.WAITING &&
      pdfRenderStatus === THUNK_STATUS.WAITING)
  );
};
export const selectPdfMarkupIsError = (state) => {
  const { annotationsLoadStatus, pdfRenderStatus } = state.pdfMarkupStatus;
  return (
    annotationsLoadStatus === THUNK_STATUS.FAILED || pdfRenderStatus === PDF_RENDER_STATUS.FAILED
  );
};
export default pdfMarkupStatusSlice.reducer;
