import PropTypes from 'prop-types';
import React from 'react';
import { Navbar, NavbarBrand, Media } from 'reactstrap';

import logo from './assets/logo.svg';
import LoggedInDropdown from './LoggedInDropdown';
import './css/Header.css';

function Header({ children, noShadow, showUserProfile }) {
  return (
    <div className="header-wrapper">
      <Navbar light expand="md" className={noShadow ? '' : 'header-shadow'}>
        <div className="header">
          <NavbarBrand id="header-title" href="/" className="header-title">
            <Media object src={logo} alt="ReviewKit" className="header-logo" />
            <h1>ReviewKit</h1>
          </NavbarBrand>
          {children || (
            <div className="header-user-dropdown">
              {/* component defaults to just showing login if no children are provided */}
              <div className="header-fill" />
              {showUserProfile && <LoggedInDropdown />}
            </div>
          )}
        </div>
      </Navbar>
    </div>
  );
}

Header.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  noShadow: PropTypes.bool,
  showUserProfile: PropTypes.bool,
};

Header.defaultProps = {
  children: null,
  noShadow: false,
  showUserProfile: true,
};

export default Header;
