import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AllResumesGrid from '../components/home/AllResumesGrid';
import ErrorToastRenderer from '../components/shared/ErrorToastRenderer';
import Header from '../components/shared/Header';
import { gaPageview } from '../helpers/googleAnalyticsHelpers';
import { selectUserLoginState } from '../redux/reducers/userLoginSlice';

import './css/Home.css';
import '../style/css/Sticky.css';

/* eslint-disable react/prop-types */
function AllResumesView() {
  gaPageview();
  const history = useHistory();
  const loggedIn = useSelector(selectUserLoginState);

  if (!loggedIn) {
    history.replace('/login');
  }

  return (
    <div className="homeview">
      <ErrorToastRenderer />
      <div className="sticky-header">
        <Header />
      </div>
      <div className="homeview-content">
        <AllResumesGrid />
      </div>
    </div>
  );
}

export default AllResumesView;
