import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Media, Button } from 'reactstrap';

import loadResume from '../../helpers/uploadHelpers';
import FileUpload from '../shared/FileUpload';
import UploadImage from './assets/upload-placeholder.png';

import './css/UploadEmpty.css';

function UploadEmpty() {
  const dispatch = useDispatch();
  let fileUpload;

  const EMPTY_RESUME_CLASSNAME = 'upload-empty-resume-block';
  const DROP_AREA_ID = 'drop-area';

  useEffect(() => {
    const dropArea = document.getElementById(DROP_AREA_ID);
    dropArea.addEventListener(
      'drop',
      (event) => {
        /* eslint-disable no-param-reassign */
        event.target.style.backgroundColor = 'var(--lighter-grey)';
        loadResume(dispatch, event.dataTransfer.files[0]);
      },
      false,
    );

    dropArea.addEventListener(
      'dragenter',
      (event) => {
        if (event.target.className === EMPTY_RESUME_CLASSNAME) {
          /* eslint-disable no-param-reassign */
          event.target.style.backgroundColor = 'var(--light-grey)';
        }
      },
      false,
    );

    dropArea.addEventListener(
      'dragleave',
      (event) => {
        if (event.target.className === EMPTY_RESUME_CLASSNAME) {
          /* eslint-disable no-param-reassign */
          event.target.style.backgroundColor = 'var(--lighter-grey)';
        }
      },
      false,
    );

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(
        eventName,
        (event) => {
          event.preventDefault();
          event.stopPropagation();
        },
        false,
      );
    });
  });

  return (
    <div id={DROP_AREA_ID} className="upload-empty-base">
      <div className={EMPTY_RESUME_CLASSNAME}>
        <Media src={UploadImage} />
        <FileUpload
          id="file-upload"
          linkedUploadButton={(ref) => {
            fileUpload = ref;
          }}
        />
        <Button className="primary-button" onClick={() => fileUpload.click()}>
          <h5>Upload Resume</h5>
        </Button>
        <div className="upload-empty-supported-filetype-text">
          <small>File must be a PDF and smaller than 6 MB</small>
        </div>
      </div>
    </div>
  );
}

export default UploadEmpty;
