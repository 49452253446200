import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import loadResume from '../../helpers/uploadHelpers';

function FileUpload({ redirectPath, linkedUploadButton }) {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <input
      id="fileExplorer"
      type="file"
      accept=".pdf"
      ref={linkedUploadButton}
      style={{ display: 'none' }}
      onChange={(event) => {
        loadResume(dispatch, event.target.files[0], history, redirectPath);
        event.stopPropagation();
      }}
    />
  );
}

FileUpload.propTypes = {
  redirectPath: PropTypes.string,
  linkedUploadButton: PropTypes.func,
};

FileUpload.defaultProps = {
  redirectPath: null,
  linkedUploadButton: null,
};

export default FileUpload;
