import { wrapGrid } from 'animate-css-grid';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

// Light wrapper for applying animate-css-grid
// The passed-in children should be a grid container
// that contains a SINGLE child which wraps the items to animate
function AnimatedGridWrapper({ children }) {
  const gridRef = useRef(null);
  const [ranOnce, setRanOnce] = useState(false);

  useEffect(() => {
    // Running wrapGrid more than once leads to the animation replaying multiple times
    if (gridRef.current && !ranOnce) {
      wrapGrid(gridRef.current.children[0]);
      setRanOnce(true);
    }
  }, [children]);

  return <div ref={gridRef}>{children}</div>;
}

AnimatedGridWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default AnimatedGridWrapper;
