import { faMapMarker, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import './css/CommentPinIcon.css';

const ICON_HEIGHT = 21;
const ICON_WIDTH = 19;

function CommentPinIcon({ onClick, isSelected, isDismissed, xPos, yPos }) {
  const colourClass = isDismissed ? 'comment-pin-icon-disabled' : 'comment-pin-icon-enabled';
  return (
    /* Layering in order to "fill" the transparent hole in faMapMarkerAlt, see
       https://fontawesome.com/v5.15/how-to-use/on-the-web/styling/layering */
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <span
      className={`fa-layers comment-pin-icon-container ${colourClass} ${
        isSelected ? 'comment-pin-icon-container-selected' : 'comment-pin-icon-container-deselected'
      }`}
      onClick={onClick}
      style={{
        position: 'absolute',
        left: `${xPos - ICON_WIDTH / 2}px`,
        top: `${yPos - ICON_HEIGHT}px`,
      }}
    >
      <FontAwesomeIcon className="comment-pin-icon-backing" icon={faMapMarker} />
      <FontAwesomeIcon className="comment-pin-icon-object" icon={faMapMarkerAlt} />
    </span>
  );
}

CommentPinIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDismissed: PropTypes.bool.isRequired,
  xPos: PropTypes.number.isRequired,
  yPos: PropTypes.number.isRequired,
};

export default CommentPinIcon;
