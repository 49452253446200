import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Input } from 'reactstrap';

import clearReviewSession from '../../helpers/apiHelpers';
import { pushSavedReview } from '../../redux/reducers/reviewerApiSlice';
import RKContextText from '../design-system/RKContextText';
import RKEmphasisText from '../design-system/RKEmphasisText';
import RKModal from '../design-system/RKModal';
import RKModalElement from '../design-system/RKModalElement';

import './css/SaveModal.css';

function SaveModal({ open, toggleModal, setSaved, resumeId }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const SAVE_MODAL_NAME_INPUT_ID = 'save-modal-name-input';
  const SAVE_MODAL_EMAIL_INPUT_ID = 'save-modal-email-input';
  const SAVE_MODAL_GENERAL_COMMENTS_INPUT_ID = 'save-modal-general-comments-input';
  const [generalComments, setGeneralComments] = useState('');
  const [name, setReviewerName] = useState('');
  const [email, setReviewerEmail] = useState('');

  const [attemptedAbandon, setAttemptedAbandon] = useState(false);

  const toggleSaveModal = () => {
    setAttemptedAbandon(false);
    toggleModal();
  };

  const handleSaveModalDone = () => {
    toggleSaveModal();
    setSaved();
    const reviewerName = document.getElementById(SAVE_MODAL_NAME_INPUT_ID).value;
    const reviewerEmail = document.getElementById(SAVE_MODAL_EMAIL_INPUT_ID).value;
    const reviewerGeneralComments = document
      .getElementById(SAVE_MODAL_GENERAL_COMMENTS_INPUT_ID)
      .value?.trim();

    dispatch(pushSavedReview({ reviewerName, reviewerEmail, reviewerGeneralComments }));
    clearReviewSession(dispatch, resumeId);
    history.push('/review');
  };

  const handleSaveModalAbandon = () => {
    toggleSaveModal();
    clearReviewSession(dispatch, resumeId);
    history.push('/review');
  };

  return (
    <RKModal isOpen={open} toggle={toggleSaveModal}>
      <RKModalElement>
        <div className="savemodal-header">
          <h3>
            <RKEmphasisText>Finished</RKEmphasisText> reviewing?&nbsp;
          </h3>
          <RKContextText>reviews can’t be edited again after submitting</RKContextText>
        </div>
        <div className="savemodal-form">
          <Input
            id={SAVE_MODAL_GENERAL_COMMENTS_INPUT_ID}
            value={generalComments}
            placeholder="Leave any overall comments, ex) Overall looks good!"
            onChange={(e) => {
              setGeneralComments(e.target.value);
            }}
          />
          Attach your <RKEmphasisText>name</RKEmphasisText> to the review&nbsp;
          <RKContextText>(optional)</RKContextText>
          <Input
            id={SAVE_MODAL_NAME_INPUT_ID}
            placeholder="Anonymous"
            value={name}
            onChange={(e) => {
              setReviewerName(e.target.value);
            }}
          />
          Attach your <RKEmphasisText>email</RKEmphasisText> to the review&nbsp;
          <RKContextText>(optional)</RKContextText>
          <Input
            id={SAVE_MODAL_EMAIL_INPUT_ID}
            placeholder="Enter your email"
            value={email}
            onChange={(e) => {
              setReviewerEmail(e.target.value);
            }}
          />
        </div>
        <div className="savemodal-button-row">
          {!attemptedAbandon ? (
            <Button className="tertiary-button" onClick={() => setAttemptedAbandon(true)}>
              Abandon Review
            </Button>
          ) : (
            <Button className="danger-button" onClick={handleSaveModalAbandon}>
              Confirm Abandonment
            </Button>
          )}
          <div>
            <Button className="tertiary-button" onClick={toggleSaveModal}>
              Keep Reviewing
            </Button>
            <Button className="left-margin-button primary-button" onClick={handleSaveModalDone}>
              <FontAwesomeIcon icon={faCheck} /> Done
            </Button>
          </div>
        </div>
      </RKModalElement>
    </RKModal>
  );
}

SaveModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  setSaved: PropTypes.func.isRequired,
  resumeId: PropTypes.string.isRequired,
};

export default SaveModal;
