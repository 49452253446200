// Based mostly off https://react-select.com/creatable, rewritten to be functional.

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import './css/ReactSelectCreatableInput.css';

const internalValuesToStringArray = (values) => values.map((value) => value.value);

function ReactSelectCreatableInput({ onValuesChanged, placeholder }) {
  const [inputValue, setInputValue] = useState('');
  const [values, setValues] = useState([]);
  // Trigger external callback whenever the state of values changes
  useEffect(() => onValuesChanged(internalValuesToStringArray(values)), [values]);

  // Moves the current value of inputValue into the values array, setting inputValue to ''
  const moveInputValueToValues = () => {
    if (!inputValue) {
      return;
    }
    const trimmedInputValue = inputValue.trim();
    const newValues = [...values, { label: trimmedInputValue, value: trimmedInputValue }];
    setValues(newValues);
    setInputValue('');
  };

  // Occurs when someone removes one or more "tags" from the input (backspace or clicking x).
  const onChange = (newValues) => {
    setValues(newValues);
  };

  const onInputChange = (newInputValue, { action }) => {
    if (action === 'input-blur') {
      moveInputValueToValues();
    } else {
      setInputValue(newInputValue);
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      moveInputValueToValues();
      event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      className="react-select-creatable-input"
      classNamePrefix="react-select"
      components={{ DropdownIndicator: null }}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={onChange}
      onInputChange={onInputChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      value={values}
    />
  );
}

ReactSelectCreatableInput.propTypes = {
  onValuesChanged: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default ReactSelectCreatableInput;
