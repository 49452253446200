import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Popover } from 'reactstrap';

import './css/DeletePopover.css';

function DeletePopover({ targetId, scrollableContainerId, setTooltipOpen, isOpen, children }) {
  const popoverClickDetection = (event) => {
    const composedPaths = event.composedPath();
    if (!composedPaths) {
      // This code is only reachable on IE.
      // This early return will not be functional but prevents our app from just crashing
      // https://caniuse.com/mdn-api_event_composedpath
      return;
    }
    const paths = composedPaths.map((path) => {
      return { id: path.id, className: path.className };
    });
    const popoverWasClicked = paths.some((element) => {
      const classString = `${element.className}`; // ensure classname is a string
      return classString.includes('popover') || element.id === targetId;
    });
    if (!popoverWasClicked) {
      // close popover if anything outside of it was clicked except the delete button
      setTooltipOpen(false);
    }
  };

  const closePopoverOnScroll = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    document.body.addEventListener('click', popoverClickDetection);
    if (scrollableContainerId) {
      document
        .getElementById(scrollableContainerId)
        .addEventListener('scroll', closePopoverOnScroll);
    }
    return () => {
      document.body.removeEventListener('click', popoverClickDetection);
      if (scrollableContainerId) {
        document
          .getElementById(scrollableContainerId)
          .removeEventListener('scroll', closePopoverOnScroll);
      }
    };
  }, []);

  return (
    <Popover className="delete-popover" target={targetId} isOpen={isOpen} placement="bottom">
      {children}
    </Popover>
  );
}

DeletePopover.propTypes = {
  targetId: PropTypes.string.isRequired,
  scrollableContainerId: PropTypes.string,
  setTooltipOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

DeletePopover.defaultProps = {
  scrollableContainerId: null,
};

export default DeletePopover;
