import React from 'react';
import ContentLoader from 'react-content-loader';

import './css/SkeletonPdf.css';

function SkeletonPdf() {
  return (
    <div className="skeleton-pdf-container">
      {/* Made with https://skeletonreact.com/ */}
      <ContentLoader
        speed={1}
        height={window.innerHeight - 75}
        title=""
        viewBox="0 0 425 550"
        backgroundColor="#f0f0f0"
        foregroundColor="#e3e3e3"
        className="skeleton-pdf"
      >
        <rect x="25" y="25" rx="0" ry="0" width="180" height="35" />
        <rect x="25" y="65" rx="0" ry="0" width="120" height="15" />
        <rect x="260" y="45" rx="0" ry="0" width="140" height="15" />
        <rect x="25" y="103" rx="0" ry="0" width="190" height="20" />
        <rect x="25" y="128" rx="0" ry="0" width="375" height="70" />
        <rect x="25" y="212" rx="0" ry="0" width="150" height="20" />
        <rect x="25" y="237" rx="0" ry="0" width="375" height="99" />
        <rect x="25" y="355" rx="0" ry="0" width="219" height="20" />
        <rect x="25" y="379" rx="0" ry="0" width="375" height="68" />
        <rect x="25" y="465" rx="0" ry="0" width="190" height="20" />
        <rect x="280" y="25" rx="0" ry="0" width="120" height="15" />
        <rect x="290" y="65" rx="0" ry="0" width="110" height="15" />
        <rect x="25" y="490" rx="0" ry="0" width="375" height="35" />
      </ContentLoader>
    </div>
  );
}

export default SkeletonPdf;
