// Based off of: https://lolahef.medium.com/react-event-emitter-9a3bb0c719
const canvasEventEmitter = {
  events: {},
  emit(event, data) {
    if (!this.events[event]) return;
    this.events[event].forEach((listener) => listener.callback(data));
  },
  subscribe(name, event, callback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push({ name, callback });
  },
  unsubscribe(name, event) {
    this.events[event] = this.events[event].filter((listener) => {
      return listener.name !== name;
    });
  },
};

export const createCanvasEvent = (type, oldState, newState) => {
  return {
    action: type,
    old: oldState,
    new: newState,
  };
};

export const canvasEventType = {
  MARKUP: 'markup',
  COMMENT: 'comment',
};

export const canvasAction = {
  ADD: 'add',
  DELETE: 'delete',
  MOVE: 'move',
};

export default canvasEventEmitter;
