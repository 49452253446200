import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  updatePinText,
  setSelectedPinUuid,
  deletePin,
} from '../../redux/reducers/commentPinsSlice';

import './css/CommentToolbarInput.css';

function CommentToolbarInput({ uuid, text, isEditable }) {
  const dispatch = useDispatch();
  const commentRef = useRef();
  const [commentText, setCommentText] = useState(text);

  const updateCommentHeightToScrollHeight = () => {
    // Prevent resetting of scroll position when textarea is reduced such that scroll bar disappears
    // https://stackoverflow.com/questions/995168/textarea-to-resize-based-on-content-length#comment14873107_995374
    const parentHeight = commentRef.current.parentElement.style.height;
    commentRef.current.parentElement.style.height = `${commentRef.current.scrollHeight}px`;
    // Must first set height to 1px to allow text box to shrink in case removing lines of text
    commentRef.current.style.height = '1px';
    commentRef.current.style.height = `${commentRef.current.scrollHeight}px`;
    commentRef.current.parentElement.style.height = parentHeight;
  };

  function handleKeyPress(e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      // Pressed enter without pressing shift
      document.getElementById(`input-${uuid}`).blur();
      dispatch(setSelectedPinUuid({ uuid: null }));
    }
  }

  useEffect(updateCommentHeightToScrollHeight, [commentText]);
  useEffect(() => {
    window.addEventListener('resize', updateCommentHeightToScrollHeight);
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('resize', updateCommentHeightToScrollHeight);
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const onInputChange = (e) => {
    setCommentText(e.target.value);
    dispatch(
      updatePinText({
        uuid,
        newText: e.target.value,
      }),
    );
  };

  const onBlurHandler = () => {
    // A non-editable textarea can't be blurred
    if (!isEditable) {
      return;
    }

    if (!text) {
      dispatch(deletePin({ uuid }));
    }
    dispatch(setSelectedPinUuid({ uuid: null }));
  };

  /* eslint-disable jsx-a11y/no-autofocus */
  return (
    <textarea
      ref={commentRef}
      id={`input-${uuid}`}
      className="comment-box-input"
      autoFocus="autofocus"
      onChange={onInputChange}
      value={commentText}
      onBlur={onBlurHandler}
      disabled={!isEditable}
    />
  );
}

CommentToolbarInput.propTypes = {
  uuid: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default CommentToolbarInput;
