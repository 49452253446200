/* eslint-disable no-param-reassign */
// (the `state` param of reducers is intended to be reassigned)
import { createSlice, isFulfilled } from '@reduxjs/toolkit';

import { createReview, fetchAnnotations } from './reviewerApiSlice';
import { fetchSavedAnnotations } from './savedReviewApiSlice';

export const scaleSlice = createSlice({
  name: 'scale',
  initialState: {
    initialHeight: 0,
    initialWidth: 0,
    scaleRatio: 0,
  },
  reducers: {
    setScaleRatio: (state, { payload }) => {
      state.scaleRatio = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createReview.fulfilled, (state, { payload }) => {
        state.initialHeight = payload.initialHeight;
        state.initialWidth = payload.initialWidth;
      })
      .addMatcher(isFulfilled(fetchAnnotations, fetchSavedAnnotations), (state, { payload }) => {
        // This one is payload.canvasHeight instead of payload.initialHeight like above because
        // the backend attribute is called canvasHeight while we call it initialHeight on the
        // frontend. `createReview`'s initialHeight comes from the frontend while this comes from
        // the backend.
        state.initialHeight = payload.canvasHeight;
        state.initialWidth = payload.canvasWidth;
      });
  },
});

export const { setScaleRatio } = scaleSlice.actions;
export const selectInitialHeight = (state) => state.scale.initialHeight;
export const selectScaleRatio = (state) => state.scale.scaleRatio;
export const selectInitialWidth = (state) => state.scale.initialWidth;
export default scaleSlice.reducer;
