import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import { Row } from 'reactstrap';

import RKContextText from '../design-system/RKContextText';

import './css/GeneralComments.css';
import './css/CommentToolbarInput.css';

function GeneralComments({ generalCommentsValue }) {
  const generalCommentRef = useRef();
  useEffect(() => {
    generalCommentRef.current.style.height = '1px';
    generalCommentRef.current.style.height = `${generalCommentRef.current.scrollHeight}px`;
  }, []);

  return (
    <Row noGutters className="last-comments-pin-comment last-comments-general-comments">
      <div className="last-comments-comment-block saved-review-sidebar-general-comments">
        <div className="last-comments-comment">
          <textarea
            className="comment-box-input general-comment-box-input"
            value={generalCommentsValue}
            ref={generalCommentRef}
            disabled
          />
        </div>
        <div className="last-comments-comment-block-footer">
          <RKContextText className="last-comments-footer-text">General Comments</RKContextText>
        </div>
      </div>
    </Row>
  );
}

GeneralComments.propTypes = {
  generalCommentsValue: PropTypes.string.isRequired,
};

export default GeneralComments;
