import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import RKCard from '../design-system/RKCard';
import RKEmphasisText from '../design-system/RKEmphasisText';

import './css/NotesFromAuthor.css';

function NotesFromAuthor({ notes }) {
  const notesRef = useRef();
  useEffect(() => {
    if (notesRef.current) {
      notesRef.current.style.height = '1px';
      let notesHeight = notesRef.current.scrollHeight + 2;
      /* If it is more than 3 lines, resize to only show first 3 */
      if (notesHeight > 75) {
        notesHeight = 70;
      }
      notesRef.current.style.height = `${notesHeight}px`;
    }
  }, [notesRef]);

  return (
    <RKCard noTopMargin className="notes-displayer">
      <RKEmphasisText>Notes from the author</RKEmphasisText>
      <br />
      <textarea className="notes-text-displayer" value={notes} ref={notesRef} disabled />
    </RKCard>
  );
}

export default NotesFromAuthor;

NotesFromAuthor.propTypes = {
  notes: PropTypes.string.isRequired,
};
