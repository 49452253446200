import PropTypes from 'prop-types';
import React from 'react';

import './css/RKContextText.css';

/**
 * Font tags for grey italicised context text.
 */
function RKContextText({ children, className, small }) {
  return (
    <div className={`rkcontexttext ${className}`}>
      {small ? (
        <small>
          <i>{children}</i>
        </small>
      ) : (
        <i>{children}</i>
      )}
    </div>
  );
}

RKContextText.propTypes = {
  /**
   * The text to by styled.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node)]).isRequired,

  /**
   * Any additional styling.
   */
  className: PropTypes.string,

  /**
   * A boolean flag to set whether text should used `<small>` tag. This flag is `true` by default.
   */
  small: PropTypes.bool,
};

RKContextText.defaultProps = {
  className: '',
  small: true,
};

export default RKContextText;
