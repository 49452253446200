import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Tooltip } from 'reactstrap';

import './css/RKHelpTooltip.css';

/**
 * A tooltip to display help text on a component after hovering for some time.
 */
function RKHelpTooltip({ children, targetId, disabled }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    if (disabled) {
      setTooltipOpen(false);
    }
  }, [disabled, tooltipOpen]);

  return (
    <Tooltip
      className="rkhelptooltip"
      placement="bottom"
      isOpen={tooltipOpen}
      target={targetId}
      toggle={toggle}
      trigger="hover"
      delay={{ show: 700, hide: 100 }}
    >
      {children}
    </Tooltip>
  );
}

RKHelpTooltip.propTypes = {
  /**
   * The help text to display in the tooltip.
   */
  children: PropTypes.string.isRequired,
  /**
   * The id of the component the tooltip should appear over.
   */
  targetId: PropTypes.string.isRequired,
  /**
   * A flag to disable the tooltip from being triggered. By default this flag is `false`.
   */
  disabled: PropTypes.bool,
};

RKHelpTooltip.defaultProps = {
  disabled: false,
};

export default RKHelpTooltip;
