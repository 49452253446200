import PropTypes from 'prop-types';
import React from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';

import { GA_CATEGORY, gaEvent } from '../../helpers/googleAnalyticsHelpers';
import {
  setSelectedPinUuid,
  updatePinPosition,
  selectPinScale,
} from '../../redux/reducers/commentPinsSlice';
import {
  selectInitialHeight,
  selectInitialWidth,
  selectScaleRatio,
} from '../../redux/reducers/scaleSlice';
import { selectTool } from '../../redux/reducers/selectedToolSlice';
import CommentPinIcon from './CommentPinIcon';
import ToolTypes from './tools/SelectedToolTypes';

function CommentPin({ uuid, xPos, yPos, isSelected, isEditable, isDismissed }) {
  const dispatch = useDispatch();
  const canvasHeight = useSelector(selectInitialHeight);
  const canvasWidth = useSelector(selectInitialWidth);
  const scaleRatio = useSelector(selectScaleRatio);
  const selectedTool = useSelector(selectTool);
  const pinScale = useSelector(selectPinScale);

  const onPinClick = () => {
    gaEvent(GA_CATEGORY.REVIEWER, 'onPinClick', 'Clicked on CommentPin');
    dispatch(setSelectedPinUuid({ uuid }));
    const inputEl = document.getElementById(`input-${uuid}`);
    if (inputEl.disabled) {
      // Quickly enable the input in order activate the move-into-view behavior of
      // enabled inputs on focus, then disable it again
      inputEl.disabled = false;
      inputEl.focus();
      inputEl.disabled = true;
    } else {
      inputEl.focus();
    }
  };

  const handleDrag = (e, data) => {
    dispatch(
      updatePinPosition({
        uuid,
        xPos: (xPos + data.deltaX) / scaleRatio,
        yPos: (yPos + data.deltaY) / scaleRatio,
      }),
    );
  };

  const handleStart = () => {
    gaEvent(GA_CATEGORY.REVIEWER, 'onPinDrag', 'Dragged a CommentPin');
    dispatch(setSelectedPinUuid({ uuid }));
  };

  return (
    <div>
      {isEditable && selectedTool === ToolTypes.SELECTION ? (
        <Draggable
          position={{ x: xPos, y: yPos }}
          scale={pinScale}
          onDrag={(e, data) => handleDrag(e, data)}
          onStart={handleStart}
          bounds={{
            top: 0,
            left: 0,
            right: canvasWidth * scaleRatio,
            bottom: canvasHeight * scaleRatio,
          }}
        >
          {/* Wrapper to absorb react-draggable's props, see
              https://github.com/react-grid-layout/react-draggable#draggable */}
          <span>
            <CommentPinIcon
              onClick={() => onPinClick()}
              isSelected={isSelected}
              isDismissed={isDismissed}
              xPos={xPos}
              yPos={yPos}
            />
          </span>
        </Draggable>
      ) : (
        <CommentPinIcon
          onClick={() => onPinClick()}
          isSelected={isSelected}
          isDismissed={isDismissed}
          xPos={xPos}
          yPos={yPos}
        />
      )}
    </div>
  );
}

CommentPin.propTypes = {
  uuid: PropTypes.string.isRequired,
  xPos: PropTypes.number.isRequired,
  yPos: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDismissed: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default CommentPin;
