import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import './css/RKModalElement.css';

/**
 * Helper component, a hoverable section within an RKModal.
 *
 * Should only be nested within an RKModal. The props hovered, underlined, and onMouseEnter
 * are for internal interactions with RKModal and will be overwritten if supplied.
 */
function RKModalElement({ onHoverChange, hovered, underlined, onMouseEnter, children }) {
  useEffect(() => onHoverChange(hovered), [hovered]);
  return (
    <div
      onMouseEnter={onMouseEnter}
      className={`rkmodalelement ${hovered ? '' : 'rkmodalelement-unhovered'} ${
        underlined ? 'rkmodalelement-underlined' : ''
      }`}
    >
      {children}
    </div>
  );
}

RKModalElement.propTypes = {
  // Callback called when the hover state of the modal element changes.
  // 1 bool param: hovered (whether or not the element is currently being hovered).
  onHoverChange: PropTypes.func,

  // These props are for internal interactions with RKModal.
  hovered: PropTypes.bool,
  underlined: PropTypes.bool,
  onMouseEnter: PropTypes.func,

  children: PropTypes.node.isRequired,
};

RKModalElement.defaultProps = {
  onHoverChange: () => {},

  // These props are auto-filled when RKModalElement is used within an RKModal.
  hovered: false,
  underlined: false,
  onMouseEnter: () => {},
};

export default RKModalElement;
