import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLocalReviewIdFromResumeId } from '../../../helpers/localStorageHelpers';
import { initCanvas, resetCanvas } from '../../../redux/reducers/canvasSlice';
import { resetPins } from '../../../redux/reducers/commentPinsSlice';
import { resetLoadStatus } from '../../../redux/reducers/pdfMarkupStatusSlice';
import {
  setReviewId,
  setResumeId,
  fetchAnnotations,
  createReview,
  pushAnnotationsToServer,
} from '../../../redux/reducers/reviewerApiSlice';
import { resetTool, selectTool } from '../../../redux/reducers/selectedToolSlice';
import ToolTypes from '../../review/tools/SelectedToolTypes';
import '../css/Canvas.css';

const ANNOTATION_PUSH_FREQUENCY_MS = 30 * 1000;

function ReviewCanvas({ resumeId, height, width }) {
  const dispatch = useDispatch();
  const tool = useSelector(selectTool);

  const [initialized, setInitialized] = useState(false);

  // Write annotations to the server every interval & before closing the tab.
  // This useEffect must come before the next one to initialize and reset the canvas so that
  // residual annotations can be pushed before the canvas is nullified
  // Order of useEffect specification matters: https://reactjs.org/docs/hooks-effect.html#tip-use-multiple-effects-to-separate-concerns
  useEffect(() => {
    if (!initialized) {
      return;
    }
    const pushAnnotations = () => dispatch(pushAnnotationsToServer());
    const intervalId = setInterval(pushAnnotations, ANNOTATION_PUSH_FREQUENCY_MS);
    window.addEventListener('beforeunload', pushAnnotations);
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('beforeunload', pushAnnotations);
      dispatch(pushAnnotationsToServer());
    };
  }, [initialized]);

  // Initialize canvas and get or create review session
  useEffect(() => {
    const originalSetCursor = fabric.Canvas.prototype.setCursor;
    // We have to overwrite fabric canvas's setCursor in order to be able to change it via classes.
    // The given setCursor function sets cursor to 'default' everytime we mouse over the canvas
    // (preventing it from being changed by css)
    fabric.Canvas.prototype.setCursor = () => {};

    const canvas = new fabric.Canvas('review-canvas');
    dispatch(initCanvas({ canvas }));
    dispatch(setResumeId(resumeId));
    const reviewId = getLocalReviewIdFromResumeId(resumeId);
    if (reviewId) {
      dispatch(setReviewId(reviewId));
      dispatch(fetchAnnotations()).then(() => setInitialized(true));
    } else {
      dispatch(createReview({ initialHeight: height, initialWidth: width })).then(() =>
        setInitialized(true),
      );
    }

    return () => {
      fabric.Canvas.prototype.setCursor = originalSetCursor;
      dispatch(resetCanvas());
      dispatch(resetTool());
      dispatch(resetPins());
      dispatch(resetLoadStatus());
    };
  }, []);

  let cursorClass = '';
  if (tool === ToolTypes.COMMENT) {
    cursorClass = 'pin-cursor';
  } else if (tool === ToolTypes.PENCIL || tool === ToolTypes.HIGHLIGHTER) {
    cursorClass = 'draw-cursor';
  } else if (tool === ToolTypes.ERASER) {
    cursorClass = 'erase-cursor';
  } else if (tool === ToolTypes.RECTANGLE) {
    cursorClass = 'crosshair-cursor';
  } else if (tool === ToolTypes.SELECTION) {
    cursorClass = 'no-pointer-events';
  }

  return (
    <div className={`canvas ${cursorClass}`}>
      <canvas id="review-canvas" />
    </div>
  );
}

ReviewCanvas.propTypes = {
  height: PropTypes.number.isRequired,
  resumeId: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default ReviewCanvas;
