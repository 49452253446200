import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import loadResume from '../../helpers/uploadHelpers';
import { VIEWPORT_SIZE, getViewport } from '../../helpers/windowHelpers';
import {
  fetchResumesMetadata,
  selectResumesMetadata,
} from '../../redux/reducers/resumesMetadataSlice';
import { selectUserData } from '../../redux/reducers/userLoginSlice';
import RKNavButton from '../design-system/RKNavButton';
import AnimatedGridWrapper from '../shared/AnimatedGridWrapper';
import FileUpload from '../shared/FileUpload';
import { HomeOnboardingStepTarget1 } from './HomeOnboardingJoyride';
import ResumeItem from './ResumeItem';
import './css/MyGrid.css';
import './css/RecentUploadsGrid.css';

const createGridContents = (metadata, gridId, maxItems) => {
  const gridContents = [];

  let fileUpload;
  const linkedUpload = (ref) => {
    fileUpload = ref;
  };

  gridContents.push(
    <Col className="item-container" key="uploadResumeButton">
      <div className="grid-item-container">
        <FileUpload redirectPath="/upload" linkedUploadButton={linkedUpload} />
        <Button
          className={`primary-button grid-upload-button grid-item ${HomeOnboardingStepTarget1}`}
          onClick={() => fileUpload.click()}
        >
          <FontAwesomeIcon icon={faPlusCircle} size="4x" />
          <h4>Upload Resume</h4>
          <small>File must be a PDF</small>
        </Button>
      </div>
    </Col>,
  );

  gridContents.push(
    ...metadata
      .slice()
      .sort((a, b) => b.uploadedAt - a.uploadedAt)
      .slice(0, maxItems)
      .map((resume) => (
        <Col className="item-container" key={resume.resumeId}>
          <ResumeItem small resume={resume} containerId={gridId} />
        </Col>
      )),
  );

  return gridContents;
};

const getMaxItems = () => {
  const viewportSize = getViewport();
  if (viewportSize === VIEWPORT_SIZE.xl) {
    return 5;
  }
  if (viewportSize === VIEWPORT_SIZE.lg) {
    return 4;
  }
  if (viewportSize === VIEWPORT_SIZE.md) {
    return 3;
  }
  return 1;
};

export default function RecentUploadsGrid() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { userId } = useSelector(selectUserData);
  const resumesMetadata = useSelector(selectResumesMetadata);
  const gridId = 'recent-uploads-container';

  useEffect(() => {
    dispatch(fetchResumesMetadata(userId));
  }, [dispatch, userId]);

  const [maxItems, _setMaxItems] = useState(getMaxItems());
  const setMaxItems = () => {
    _setMaxItems(getMaxItems());
  };

  useEffect(() => {
    window.addEventListener('resize', setMaxItems);
    return () => {
      window.removeEventListener('resize', setMaxItems);
    };
  }, []);

  const onDrop = useCallback(([file]) => loadResume(dispatch, file, history, '/upload'), []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ noClick: true, onDrop });

  const gridContents = createGridContents(resumesMetadata, gridId, maxItems);

  return (
    <div id={gridId} className="my-grid-container recent-uploads-container">
      <div className="recent-uploads-header-wide">
        <div className="recent-uploads-header-title">Recent Uploads</div>
        {resumesMetadata.length > getMaxItems() && (
          <RKNavButton right onClick={() => history.push('home/all-resumes')}>
            See More
          </RKNavButton>
        )}
      </div>
      <div className="recent-uploads-header-small">
        <h4 className="recent-uploads-header-title">Recent Uploads</h4>
      </div>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getRootProps()}
      >
        <AnimatedGridWrapper /* AnimatedGridWrapper must only wrap 1 child */>
          <Row
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getInputProps({
              className: `my-grid ${isDragActive ? 'upload-outline' : ''}`,
              lg: 5,
              md: 4,
              xl: 6,
              noGutters: true,
              // The default style is "display: none"
              style: { cursor: 'auto' },
            })}
          >
            {gridContents}
          </Row>
        </AnimatedGridWrapper>
      </div>
      <div className="recent-uploads-header-small">
        {resumesMetadata.length > getMaxItems() && (
          <RKNavButton right onClick={() => history.push('home/all-resumes')}>
            <h4>See More</h4>
          </RKNavButton>
        )}
      </div>
    </div>
  );
}
