import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import './css/RKNavButton.css';

/**
 * Small navigational button. Used in similar places as breadcrumbs.
 */
function RKNavButton({ children, className, onClick, left, right }) {
  let style = `${className} rknavbutton-content`;
  if (left) {
    style += ' rknavbutton-left';
  }
  if (right) {
    style += ' rknavbutton-right';
  }
  return (
    <button type="button" className="primary-button rknavbutton" onClick={onClick}>
      <div className={style}>
        {left && <FontAwesomeIcon icon={faChevronLeft} />}
        {children}
        {right && <FontAwesomeIcon icon={faChevronRight} />}
      </div>
    </button>
  );
}

RKNavButton.propTypes = {
  /**
   * The text to display in the button.
   */
  children: PropTypes.string.isRequired,
  /**
   * Any additional styling.
   */
  className: PropTypes.string,
  /**
   * onClick behaviour.
   */
  onClick: PropTypes.func,
  /**
   * Add left arrow to button.
   */
  left: PropTypes.bool,
  /**
   * Add right arrow to button.
   */
  right: PropTypes.bool,
};

RKNavButton.defaultProps = {
  className: '',
  onClick: () => {},
  left: false,
  right: false,
};

export default RKNavButton;
