import 'bootstrap/dist/css/bootstrap.css';
import './style/css/index.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';

import ErrorBoundary from './components/shared/ErrorBoundary';
import AllResumesView from './pages/AllResumesView';
import HomeView from './pages/HomeView';
import LoginPage from './pages/LoginPage';
import NotFound from './pages/NotFound';
import ReviewerView from './pages/ReviewerView';
import SavedReviewView from './pages/SavedReviewView';
import UploadView from './pages/UploadView';
import { selectUserLoginState } from './redux/reducers/userLoginSlice';

function App() {
  const loggedIn = useSelector(selectUserLoginState);

  return (
    <Router>
      <ErrorBoundary>
        <main>
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                if (loggedIn) {
                  window.location.href = '/home';
                } else {
                  window.location.href = '/main';
                }
              }}
            />
            {/* TODO: resumeId shouldn't be optional once PDF upload is removed from /review */}
            <Route path="/review/:resumeId?" component={ReviewerView} />
            <Route path="/resume/:resumeId?/:reviewId?" component={SavedReviewView} />
            <Route exact path="/home" component={HomeView} />
            <Route exact path="/home/all-resumes" component={AllResumesView} />
            <Route exact path="/home/reviews" render={() => <Redirect to="/home" />} />
            <Route exact path="/home/resumes" render={() => <Redirect to="/home" />} />
            <Route exact path="/upload/:resumeId?" component={UploadView} />
            <Route exact path="/login" component={LoginPage} />
            <Route component={NotFound} />
          </Switch>
        </main>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
