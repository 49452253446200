const ToolTypes = {
  NONE: 'NONE',
  SELECTION: 'SELECTION',
  PENCIL: 'PENCIL',
  HIGHLIGHTER: 'HIGHLIGHTER',
  RECTANGLE: 'RECTANGLE',
  COMMENT: 'COMMENT',
  ERASER: 'ERASER',
};

export default ToolTypes;
