import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import {
  fetchReviewsMetadata,
  selectReviewsMetadata,
  selectReviewsMetadataStatus,
} from '../../redux/reducers/reviewsMetadataSlice';
import { selectUserData } from '../../redux/reducers/userLoginSlice';
import RKContextText from '../design-system/RKContextText';
import AnimatedGridWrapper from '../shared/AnimatedGridWrapper';
import { HomeOnboardingStepTarget2 } from './HomeOnboardingJoyride';
import ReviewItem from './ReviewItem';
import './css/MyGrid.css';
import './css/ReviewsGrid.css';

function ReviewsGrid() {
  const dispatch = useDispatch();
  const { userId } = useSelector(selectUserData);
  const reviewsMetadata = useSelector(selectReviewsMetadata);
  const reviewMetadataStatus = useSelector(selectReviewsMetadataStatus);
  const gridId = 'my-grid-container';

  useEffect(() => {
    dispatch(fetchReviewsMetadata(userId));
  }, [dispatch, userId]);

  const gridContents = [];
  gridContents.push(
    ...reviewsMetadata
      .slice()
      .sort((a, b) => b.reviewedAt - a.reviewedAt)
      .map((review) => (
        <Col className="item-container" key={review.reviewId}>
          <ReviewItem review={review} containerId={gridId} />
        </Col>
      )),
  );

  const gridDisplay =
    gridContents.length === 0 ? (
      <div className="my-grid-empty">
        <RKContextText small={false}>
          Completed reviews of your resume will appear here once a reviewer saves their review.
          Upload a resume to get started!
        </RKContextText>
      </div>
    ) : (
      <AnimatedGridWrapper /* AnimatedGridWrapper must only wrap 1 child */>
        <Row className="my-grid" lg="4" md="3" noGutters xl="5">
          {gridContents}
        </Row>
      </AnimatedGridWrapper>
    );

  return (
    <div id={gridId} className={`my-grid-container reviews-container ${HomeOnboardingStepTarget2}`}>
      <div className="reviews-header-wide">
        <div className="reviews-header-title">Your Reviews</div>
      </div>
      <div className="reviews-header-small">
        <h4 className="reviews-header-title">Your Reviews</h4>
      </div>
      {!reviewMetadataStatus.isLoading && gridDisplay}
    </div>
  );
}

export default ReviewsGrid;
