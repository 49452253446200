import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import { formatDate } from '../../helpers/dateHelpers';
import { deleteSavedReview } from '../../redux/reducers/savedReviewApiSlice';
import RKContextText from '../design-system/RKContextText';
import RKEmphasisText from '../design-system/RKEmphasisText';
import RKHelpTooltip from '../design-system/RKHelpTooltip';
import RKPill from '../design-system/RKPill';
import ThumbnailPlaceholder from './assets/thumbnail-placeholder.png';
import DeletePopover from './DeletePopover';

function ReviewItem({ review, containerId }) {
  const dispatch = useDispatch();
  const {
    name,
    reviewerName,
    resumeId,
    reviewedAt,
    reviewId,
    viewed,
    thumbnailUrl = '',
    markupThumbnailUrl = '',
  } = review;

  const reviewerDisplayName = reviewerName || 'Anonymous';

  const deleteButtonId = `grid-delete-${reviewId}`;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const deleteButtonAction = () => {
    dispatch(deleteSavedReview({ resumeId, reviewId }));
  };

  return (
    <div className="grid-item-container grid-item-container-clickable">
      {!viewed && (
        <RKPill danger topRight>
          NEW
        </RKPill>
      )}
      <div className="grid-item-overlay">
        <RKEmphasisText className="text-overflow-ellipsis">{name}</RKEmphasisText>
        <div>
          <RKContextText>Reviewed {reviewedAt && `on ${formatDate(reviewedAt)}`}</RKContextText>
          <div className="flex-parent grid-item-overlay-info">
            <RKContextText className="text-overflow-ellipsis grid-item-reviewer-name">
              by <b>{reviewerDisplayName}</b>
            </RKContextText>
            <div className="grid-item-fill-row" />
            <FontAwesomeIcon
              id={deleteButtonId}
              className={`grid-item-delete-button ${
                tooltipOpen && 'grid-item-delete-button-selected'
              }`}
              icon={faTrash}
              onClick={() => setTooltipOpen(!tooltipOpen)}
            />
            <DeletePopover
              isOpen={tooltipOpen}
              setTooltipOpen={setTooltipOpen}
              targetId={deleteButtonId}
              resumeId={resumeId}
              scrollableContainerId={containerId}
            >
              <RKContextText small={false}>
                Reviews cannot be recovered after deleting
              </RKContextText>
              <Button className="danger-button" onClick={deleteButtonAction}>
                <small>Delete Review</small>
              </Button>
            </DeletePopover>
          </div>
        </div>
        <RKHelpTooltip disabled={tooltipOpen} targetId={deleteButtonId}>
          Delete review
        </RKHelpTooltip>
      </div>
      <Link to={`/resume/${resumeId}/${reviewId}`}>
        <div className="grid-item">
          <img
            alt="Review thumbnail"
            onError={(e) => {
              e.target.src = ThumbnailPlaceholder;
            }}
            src={markupThumbnailUrl || thumbnailUrl || ThumbnailPlaceholder}
          />
        </div>
      </Link>
    </div>
  );
}

ReviewItem.propTypes = {
  review: PropTypes.exact({
    name: PropTypes.string.isRequired,
    resumeId: PropTypes.string.isRequired,
    reviewedAt: PropTypes.number.isRequired,
    reviewerName: PropTypes.string,
    reviewId: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    markupThumbnailUrl: PropTypes.string.isRequired,
    viewed: PropTypes.bool.isRequired,
  }).isRequired,
  containerId: PropTypes.string,
};

ReviewItem.defaultProps = {
  containerId: null,
};

export default ReviewItem;
