import PropTypes from 'prop-types';
import React from 'react';
import './css/RKPill.css';

/**
 * A pill component to display small bits of information.
 */
function RKPill({ children, className, danger, topRight }) {
  let styling = `rkpill ${className}`;
  if (danger) {
    styling += ' rkpill-danger';
  }
  if (topRight) {
    styling += ' rkpill-topright';
  }
  return <div className={styling}>{children}</div>;
}

RKPill.propTypes = {
  /**
   * Text to fill the pill with.
   */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  ]).isRequired,
  /**
   * Any additional styling
   */
  className: PropTypes.string,
  /**
   * A flag to set the pill to danger colours.
   */
  danger: PropTypes.bool,
  /**
   * A flag to position the pill in the top right of its container.
   */
  topRight: PropTypes.bool,
};

RKPill.defaultProps = {
  danger: false,
  className: '',
  topRight: false,
};

export default RKPill;
