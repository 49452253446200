import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import canvasEventEmitter, {
  canvasAction,
  createCanvasEvent,
} from '../../../helpers/canvasEventEmitter';
import { GA_CATEGORY, gaEvent } from '../../../helpers/googleAnalyticsHelpers';
import { selectCanvas } from '../../../redux/reducers/canvasSlice';
import { setTool } from '../../../redux/reducers/selectedToolSlice';
import RKHeaderButton from '../../design-system/RKHeaderButton';
import RKHelpTooltip from '../../design-system/RKHelpTooltip';
import ToolTypes from './SelectedToolTypes';

/* eslint-disable no-param-reassign */
function setEraseMode(canvas, onMouseDown, onMouseUp, onMouseMove) {
  canvas.isDrawingMode = false;
  canvas.selection = false;
  canvas.forEachObject((o) => {
    o.selectable = false;
    o.evented = true;
  });

  canvas.off('mouse:down');
  canvas.off('mouse:move');
  canvas.off('mouse:up');
  canvas.off('object:added');

  canvas.on('mouse:down', onMouseDown);
  canvas.on('mouse:up', onMouseUp);
  canvas.on('mouse:move', onMouseMove);
}

function EraseToolButton({ isSelected, disabled }) {
  const dispatch = useDispatch();
  const canvas = useSelector(selectCanvas);
  let isDown = false;
  let deletedObjects = [];
  let lastCoords = null;

  function onMouseDown(o) {
    isDown = true;
    if (o.target && !canvas.isTargetTransparent(o.target, o.pointer.x, o.pointer.y)) {
      canvas.remove(o.target);
      deletedObjects.push(o.target);
    }
    lastCoords = o.pointer;
  }

  function onMouseMove(o) {
    if (!isDown) return;
    if (!o.target) return;
    // Check all objects since target could've been covering something else
    canvas.forEachObject((obj) => {
      const deltaX = lastCoords.x - o.pointer.x;
      const deltaY = lastCoords.y - o.pointer.y;
      // Check linear interpolation between points
      for (let percent = 0; percent < 1; percent += 0.01) {
        const checkX = lastCoords.x + Math.round(deltaX * percent);
        const checkY = lastCoords.y + Math.round(deltaY * percent);
        if (!canvas.isTargetTransparent(obj, checkX, checkY)) {
          canvas.remove(obj);
          deletedObjects.push(obj);
          break;
        }
      }
    });
    lastCoords = o.pointer;
  }

  function onMouseUp() {
    isDown = false;
    if (deletedObjects.length > 0) {
      canvasEventEmitter.emit(
        'action',
        createCanvasEvent(canvasAction.DELETE, deletedObjects, null),
      );
      deletedObjects = [];
    }
    lastCoords = null;
  }

  useEffect(() => {
    if (isSelected) {
      setEraseMode(canvas, onMouseDown, onMouseUp, onMouseMove);
    }
  }, [isSelected]);

  const onEraseButtonClick = (e) => {
    gaEvent(GA_CATEGORY.REVIEWER, 'onEraseButtonClick', 'Clicked EraseToolButton');
    if (isSelected) {
      dispatch(setTool(ToolTypes.SELECTION));
      e.currentTarget.blur();
    } else {
      dispatch(setTool(ToolTypes.ERASER));
    }
  };

  return (
    <>
      <RKHeaderButton
        id="erase-tool"
        isSelected={isSelected}
        canDeselect
        disabled={disabled || !canvas}
        onClick={onEraseButtonClick}
      >
        <FontAwesomeIcon icon={faEraser} size="lg" />
      </RKHeaderButton>
      <RKHelpTooltip targetId="erase-tool">Erase drawings</RKHelpTooltip>
    </>
  );
}

EraseToolButton.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

EraseToolButton.defaultProps = {
  disabled: false,
};

export default EraseToolButton;
