import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import './css/GradientScroll.css';

// https://github.com/jbccollins/react-gradient-scroll-indicator
// Similar implementation as the above library, but without style-it
// Also won't show the shroud on non-scrollable children
function GradientScroll({ children }) {
  const [hideTopOverlay, setHideTopOverlay] = useState(false);
  const [hideBottomOverlay, setHideBottomOverlay] = useState(false);
  const contentElement = useRef(null);
  const scrollableElement = useRef(null);

  const onScroll = () => {
    const scrollableScrollTop = scrollableElement.current.scrollTop;
    const contentHeight = contentElement.current.clientHeight;
    const atBottom = scrollableElement.current.scrollHeight === contentHeight + scrollableScrollTop;
    setHideTopOverlay(scrollableScrollTop === 0);
    setHideBottomOverlay(atBottom);
  };

  useEffect(onScroll, [children]);

  // Uses a hack to programmatically display/hide the ::before and ::after shroud
  // https://stackoverflow.com/a/65690431
  const overlayStyle = {
    '--widthTop': hideTopOverlay ? 0 : '100%',
    '--widthBottom': hideBottomOverlay ? 0 : '100%',
  };

  return (
    <div className="scroll-scrollable" onScroll={onScroll} ref={scrollableElement}>
      <div className="scroll-overlay" style={overlayStyle} />
      <div className="scroll-content" ref={contentElement}>
        {children}
      </div>
    </div>
  );
}

GradientScroll.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default GradientScroll;
