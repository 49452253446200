import PropTypes from 'prop-types';
import React from 'react';
import GoogleLoginLib from 'react-google-login';
import { useDispatch } from 'react-redux';

import env from '../../helpers/envHelpers';
import { googleLogin } from '../../redux/reducers/userLoginSlice';
import './css/GoogleLogin.css';

function GoogleLogin({ setLoginFailed }) {
  const dispatch = useDispatch();

  const handleGoogleLoginSuccess = (res) => {
    dispatch(googleLogin(res.tokenId));
    setLoginFailed(false);
  };

  const handleGoogleLoginFailure = () => {
    setLoginFailed(true);
  };

  return (
    <GoogleLoginLib
      className="google-login-button"
      clientId={env('GOOGLE_CLIENT_ID')}
      buttonText="Sign in with Google"
      onSuccess={handleGoogleLoginSuccess}
      onFailure={handleGoogleLoginFailure}
      cookiePolicy="single_host_origin"
    />
  );
}

GoogleLogin.propTypes = {
  setLoginFailed: PropTypes.func.isRequired,
};

export default GoogleLogin;
