/* eslint-disable no-param-reassign */
// (the `state` param of reducers is intended to be reassigned)
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { REFRESH_TOKEN_ERROR_NAME } from '../../helpers/customErrorHelpers';
import env from '../../helpers/envHelpers';
import { getLocalLoggedIn, getLocalUserInfo } from '../../helpers/localStorageHelpers';
import THUNK_STATUS from '../../helpers/reduxHelpers';
import { fetchResumesMetadata } from './resumesMetadataSlice';
import { uploadResume } from './revieweePdfSlice';

// [POST /login/google] login using google OAuth
export const googleLogin = createAsyncThunk('userLogin/googleLogin', async (tokenId) => {
  const url = new URL(`${env('RVKT_API_HOST')}/login/google`);
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tokenId,
    }),
  };
  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    throw new Error(`Response code: ${response.status}`);
  }
  return response.json();
});

// [POST /login/linkedin] login using linkedin OAuth
export const linkedinLogin = createAsyncThunk(
  'userLogin/linkedinLogin',
  async ({ code, redirectUri }) => {
    const url = new URL(`${env('RVKT_API_HOST')}/login/linkedin`);
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
        redirectUri,
      }),
    };
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error(`Response code: ${response.status}`);
    }
    return response.json();
  },
);

// [POST /logout] request generator
export const userLogout = createAsyncThunk('userLogin/logout', async () => {
  const url = new URL(`${env('RVKT_API_HOST')}/logout`);
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    throw new Error(`Response code: ${response.status}`);
  }
  return response.json();
});

const userInfo = getLocalUserInfo();

export const userLoginSlice = createSlice({
  name: 'userLogin',
  initialState: {
    loggedIn: getLocalLoggedIn(),
    status: THUNK_STATUS.WAITING,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    profilePictureUrl: userInfo.profilePictureUrl,
    userId: userInfo.userId,
  },
  extraReducers: {
    [googleLogin.pending]: (state) => {
      state.status = THUNK_STATUS.LOADING;
    },
    [googleLogin.fulfilled]: (state, { payload }) => {
      const { firstName, lastName, profilePictureUrl, userId } = payload.data;

      state.loggedIn = true;
      state.firstName = firstName;
      state.lastName = lastName;
      state.profilePictureUrl = profilePictureUrl;
      state.status = THUNK_STATUS.SUCCEEDED;
      state.userId = userId;
    },
    [googleLogin.rejected]: (state) => {
      state.status = THUNK_STATUS.FAILED;
    },
    [linkedinLogin.pending]: (state) => {
      state.status = THUNK_STATUS.LOADING;
    },
    [linkedinLogin.fulfilled]: (state, { payload }) => {
      const { firstName, lastName, profilePictureUrl, userId } = payload.data;

      state.loggedIn = true;
      state.firstName = firstName;
      state.lastName = lastName;
      state.profilePictureUrl = profilePictureUrl;
      state.status = THUNK_STATUS.SUCCEEDED;
      state.userId = userId;
    },
    [linkedinLogin.rejected]: (state) => {
      state.status = THUNK_STATUS.FAILED;
    },
    [userLogout.fulfilled]: (state) => {
      state.loggedIn = false;
    },
    [fetchResumesMetadata.rejected]: (state, { error }) => {
      if (error.name === REFRESH_TOKEN_ERROR_NAME) {
        state.loggedIn = false;
      }
    },
    [uploadResume.rejected]: (state, { error }) => {
      if (error.name === REFRESH_TOKEN_ERROR_NAME) {
        state.loggedIn = false;
      }
    },
  },
});

export const selectLoggedInStatus = (state) => state.userLogin.status;
export const selectUserLoginState = (state) => state.userLogin.loggedIn;
export const selectUserData = (state) => {
  const { firstName, lastName, profilePictureUrl, userId } = state.userLogin;

  return {
    firstName,
    lastName,
    profilePictureUrl,
    userId,
  };
};
export default userLoginSlice.reducer;
