import { faMapMarker, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row } from 'reactstrap';

import {
  selectCommentPins,
  setSelectedPinUuid,
  selectSelectedPinUuid,
} from '../../redux/reducers/commentPinsSlice';
import { selectGeneralComments } from '../../redux/reducers/savedReviewApiSlice';
import { setTool } from '../../redux/reducers/selectedToolSlice';
import RKCard from '../design-system/RKCard';
import RKEmphasisText from '../design-system/RKEmphasisText';
import GradientScroll from '../shared/GradientScroll';
import GeneralComments from './GeneralComments';
import ToolTypes from './tools/SelectedToolTypes';

import './css/CommentToolbar.css';

const compareCoordinates = (a, b) => {
  // Round to nearest 5, so that pins on almost same yAxis are sorted by x
  if (Math.round(a[1].yPos / 5) < Math.round(b[1].yPos / 5)) {
    return -1;
  }
  if (Math.round(a[1].yPos / 5) > Math.round(b[1].yPos / 5)) {
    return 1;
  }
  // Secondary sort on x axis
  if (a[1].xPos < b[1].xPos) {
    return -1;
  }
  if (a[1].xPos > b[1].xPos) {
    return 1;
  }
  return 0;
};

function CommentToolbar({ isDisabled, getCommentDisplayContents }) {
  const commentPins = useSelector(selectCommentPins);
  const commentList = Object.entries(commentPins);
  commentList.sort(compareCoordinates);
  const dispatch = useDispatch();
  const generalCommentsValue = useSelector(selectGeneralComments);

  const commentOnClickHandler = (uuid) => {
    dispatch(setSelectedPinUuid({ uuid }));
    dispatch(setTool(ToolTypes.COMMENT));
  };
  const selectedPinUuid = useSelector(selectSelectedPinUuid);

  return (
    <RKCard className="last-comments">
      <h5 className="last-comments-header">
        <RKEmphasisText className={isDisabled ? 'last-comments-header-disabled' : ''}>
          Comments
        </RKEmphasisText>
      </h5>
      <GradientScroll>
        {!isDisabled && (
          <>
            {generalCommentsValue && (
              <GeneralComments generalCommentsValue={generalCommentsValue} />
            )}
            {commentList.map(([uuid, { text, timestamp, dismissed }]) => {
              const commentSelected = uuid === selectedPinUuid;
              return (
                <Row
                  key={uuid}
                  noGutters
                  className="last-comments-pin-comment"
                  onClick={() => commentOnClickHandler(uuid)}
                >
                  <div className="last-comments-comment-block">
                    <div
                      className={`last-comments-pin ${
                        dismissed ? 'last-comments-pin-disabled' : 'last-comments-pin-enabled'
                      }`}
                    >
                      {/* Layering in order to "fill" the transparent hole in faMapMarkerAlt, see
                      https://fontawesome.com/v5.15/how-to-use/on-the-web/styling/layering */}
                      <span className="fa-layers">
                        <FontAwesomeIcon
                          className={`last-comments-pin-backing ${
                            commentSelected && 'last-comments-pin-outline'
                          }`}
                          icon={faMapMarker}
                        />
                        <FontAwesomeIcon
                          className={commentSelected ? '' : 'last-comments-pin-deselected'}
                          icon={faMapMarkerAlt}
                        />
                      </span>
                    </div>
                    {getCommentDisplayContents(uuid, text, timestamp, commentSelected, dismissed)}
                  </div>
                </Row>
              );
            })}
          </>
        )}
      </GradientScroll>
    </RKCard>
  );
}

CommentToolbar.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  getCommentDisplayContents: PropTypes.func.isRequired,
};

export default CommentToolbar;
