/* eslint-disable no-param-reassign */
// (the `state` param of reducers is intended to be reassigned)
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { authFetch } from '../../helpers/authHelpers';
import env from '../../helpers/envHelpers';
import { deleteResume } from './revieweePdfSlice';
import { deleteSavedReview } from './savedReviewApiSlice';

// [GET /reviews/metadata] request generator
function generateGetReviewsMetadataRequest(userId) {
  const url = new URL(`${env('RVKT_API_HOST')}/reviews/metadata`);
  url.search = new URLSearchParams({
    userId,
  }).toString();

  const requestOptions = { method: 'GET' };

  return authFetch(url, requestOptions);
}

export const fetchReviewsMetadata = createAsyncThunk(
  'reviewsMetadata/fetchReviewsMetadata',
  async (userId) => {
    const response = await generateGetReviewsMetadataRequest(userId);

    if (!response.ok) {
      throw new Error(`Response code: ${response.status}`);
    }

    const responseJson = await response.json();
    return responseJson.data;
  },
);

export const reviewsMetadataSlice = createSlice({
  name: 'reviewsMetadata',
  initialState: {
    reviewsMetadata: [],
    isLoading: false,
    isLoaded: false,
    isError: false,
  },
  extraReducers: {
    [fetchReviewsMetadata.fulfilled]: (state, { payload }) => {
      state.reviewsMetadata = payload;
      state.isLoading = false;
      state.isLoaded = true;
    },
    [fetchReviewsMetadata.pending]: (state) => {
      state.isLoading = true;
      state.isLoaded = false;
      state.isError = false;
    },
    [fetchReviewsMetadata.rejected]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [deleteSavedReview.fulfilled]: (state, { payload }) => {
      state.reviewsMetadata = state.reviewsMetadata.filter(
        (data) => data.reviewId !== payload.reviewId,
      );
    },
    [deleteResume.fulfilled]: (state, { payload }) => {
      if (payload.deleteAssociatedReviews) {
        state.reviewsMetadata = state.reviewsMetadata.filter(
          (data) => data.resumeId !== payload.resumeId,
        );
      }
    },
  },
});

export const selectReviewsMetadata = (state) => state.reviewsMetadata.reviewsMetadata;
export const selectReviewsMetadataStatus = (state) => {
  return {
    isLoading: state.reviewsMetadata.isLoading,
    isLoaded: state.reviewsMetadata.isLoaded,
    isError: state.reviewsMetadata.isError,
  };
};
export default reviewsMetadataSlice.reducer;
