import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import REVIEW_TYPE from '../../helpers/reviewTypeEnum';
import { setCanvasDimensions, setCanvasZoom } from '../../redux/reducers/canvasSlice';
import { setSelectedPinUuid } from '../../redux/reducers/commentPinsSlice';
import {
  selectPdfMarkupIsComplete,
  selectPdfMarkupIsLoading,
  setScaleFinished,
} from '../../redux/reducers/pdfMarkupStatusSlice';
import {
  selectInitialHeight,
  selectScaleRatio,
  setScaleRatio,
} from '../../redux/reducers/scaleSlice';
import CommentPinLayer from '../review/CommentPinLayer';
import ArrowWrapper from './pdf-markup-builder/ArrowWrapper';
import PdfView from './pdf-markup-builder/PdfView';
import ReviewCanvas from './pdf-markup-builder/ReviewCanvas';
import SavedReviewCanvas from './pdf-markup-builder/SavedReviewCanvas';
import SkeletonPdf from './SkeletonPdf';
import ZoomWrapper from './ZoomWrapper';

import './css/PdfView.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

function AnnotatedPdfContainer({
  pdfData,
  resumeId,
  reviewId,
  onPageChange,
  showBackButton,
  backButtonLink,
  reviewType,
  isEditable,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const pdfRef = useRef(null);
  const pdfContainerRef = useRef(null);

  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const initialHeight = useSelector(selectInitialHeight);
  const scaleRatio = useSelector(selectScaleRatio);

  const onRenderSuccess = () => {
    const { clientHeight, clientWidth } = pdfRef.current;
    if (clientHeight && clientWidth) {
      setDimensions({ height: clientHeight, width: clientWidth });
    }
  };

  useEffect(() => {
    // Needs to be backwards compatible, if reviews didn't have height props don't scale
    if (dimensions.height && dimensions.width) {
      let newScaleRatio;
      if (initialHeight) {
        newScaleRatio = dimensions.height / initialHeight;
      } else {
        newScaleRatio = 1;
      }
      dispatch(setScaleRatio(newScaleRatio));
      dispatch(setCanvasZoom(newScaleRatio));
      dispatch(setScaleFinished(true));
    }
  }, [dimensions, initialHeight]);

  useEffect(() => {
    dispatch(setCanvasDimensions(dimensions));
  }, [dimensions]);

  const navEnabled = useSelector(selectPdfMarkupIsComplete);
  const isLoading = useSelector(selectPdfMarkupIsLoading);

  return (
    <div
      className="pdf-static-container"
      onMouseDown={() => dispatch(setSelectedPinUuid({ uuid: null }))}
      aria-hidden="true"
      ref={pdfContainerRef}
    >
      {showBackButton && (
        <Button
          className="pdf-page-button pdf-page-back-button"
          onClick={() => history.push(backButtonLink)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      )}
      <ZoomWrapper>
        <div className="pdf-container">
          <ArrowWrapper
            navEnabled={navEnabled}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            onPageChange={onPageChange}
          >
            <div className="pdf-canvas-wrapper">
              {isLoading && <SkeletonPdf />}
              <div ref={pdfRef} className={isLoading ? 'pdf-hidden' : ''}>
                <PdfView
                  onPageRenderSuccess={onRenderSuccess}
                  pdfData={pdfData}
                  page={page}
                  setTotalPages={setTotalPages}
                  pdfContainerRef={pdfContainerRef}
                />
                {dimensions.height !== 0 && dimensions.width !== 0 && (
                  <>
                    {reviewType === REVIEW_TYPE.REVIEW && (
                      <ReviewCanvas
                        resumeId={resumeId}
                        height={dimensions.height}
                        width={dimensions.width}
                      />
                    )}
                    {reviewType === REVIEW_TYPE.SAVED_REVIEW && (
                      <SavedReviewCanvas reviewId={reviewId} />
                    )}
                    <CommentPinLayer scaleRatio={scaleRatio} isEditable={isEditable} />
                  </>
                )}
              </div>
            </div>
          </ArrowWrapper>
        </div>
      </ZoomWrapper>
    </div>
  );
}

AnnotatedPdfContainer.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  pdfData: PropTypes.arrayOf(PropTypes.number),
  showBackButton: PropTypes.bool,
  backButtonLink: PropTypes.string,
  reviewType: PropTypes.oneOf([REVIEW_TYPE.REVIEW, REVIEW_TYPE.SAVED_REVIEW]).isRequired,
  resumeId: PropTypes.string,
  reviewId: PropTypes.string,
  isEditable: PropTypes.bool.isRequired,
};

AnnotatedPdfContainer.defaultProps = {
  pdfData: null,
  showBackButton: false,
  backButtonLink: '/home',
  resumeId: '',
  reviewId: '',
};

export default AnnotatedPdfContainer;
