export const REFRESH_TOKEN_ERROR_NAME = 'InvalidRefreshTokenError';

export class RefreshTokenError extends Error {
  constructor(...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RefreshTokenError);
    }
    this.name = REFRESH_TOKEN_ERROR_NAME;
    this.message = 'Invalid Refresh Token';
  }
}
