/* eslint-disable no-param-reassign */
// (the `state` param of reducers is intended to be reassigned)
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { authFetch } from '../../helpers/authHelpers';
import env from '../../helpers/envHelpers';
import { deleteResume } from './revieweePdfSlice';

// [GET /resumes/metadata] request generator
function generateGetResumesMetadataRequest(userId) {
  const url = new URL(`${env('RVKT_API_HOST')}/resumes/metadata`);
  url.search = new URLSearchParams({
    userId,
  }).toString();

  const requestOptions = { method: 'GET' };

  return authFetch(url, requestOptions);
}

export const fetchResumesMetadata = createAsyncThunk(
  'resumesMetadata/fetchResumesMetadata',
  async (userId) => {
    const response = await generateGetResumesMetadataRequest(userId);

    if (!response.ok) {
      throw new Error(`Response code: ${response.status}`);
    }

    const responseJson = await response.json();
    return responseJson.data;
  },
);

export const resumesMetadataSlice = createSlice({
  name: 'resumesMetadata',
  initialState: {
    resumesMetadata: [],
  },
  extraReducers: {
    [fetchResumesMetadata.fulfilled]: (state, { payload }) => {
      state.resumesMetadata = payload;
    },
    [deleteResume.fulfilled]: (state, { payload }) => {
      state.resumesMetadata = state.resumesMetadata.filter(
        (data) => data.resumeId !== payload.resumeId,
      );
    },
  },
});

export const selectResumesMetadata = (state) => state.resumesMetadata.resumesMetadata;
export default resumesMetadataSlice.reducer;
