import PropTypes from 'prop-types';
import React from 'react';

import DeleteCard from './DeleteCard';
import ResumeNameCard from './ResumeNameCard';
import ShareCard from './ShareCard';

import './css/UploadSidebar.css';

function UploadSidebar({ disabled, resumeId, initialShareOpen }) {
  return (
    <div className="upload-sidebar">
      <ShareCard initialShareOpen={initialShareOpen} disabled={disabled} />
      <ResumeNameCard disabled={disabled} />
      <div className="upload-sidebar-bottom">
        <DeleteCard resumeId={resumeId} disabled={disabled} />
      </div>
    </div>
  );
}

UploadSidebar.propTypes = {
  disabled: PropTypes.bool,
  resumeId: PropTypes.string.isRequired,
  initialShareOpen: PropTypes.bool.isRequired,
};

UploadSidebar.defaultProps = {
  disabled: false,
};

export default UploadSidebar;
