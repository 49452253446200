import React from 'react';
import Joyride from 'react-joyride';
import { Alert } from 'reactstrap';

export const ReviewerOnboardingStepTarget = 'review-onboarding-popover';

export function ReviewerOnboardingJoyride() {
  const steps = [
    {
      target: `.${ReviewerOnboardingStepTarget}`,
      content: (
        <div style={{ textAlign: 'left' }}>
          <h5>Submit the review once you&apos;re done!</h5>
          <br />
          <span>The author won&apos;t be able to see the review until it&apos;s submitted.</span>
          <br />
          <br />
          <Alert color="info">
            Remember to press <b>Finish</b> to submit your review!
          </Alert>
        </div>
      ),
      placement: 'auto',
      hideFooter: true,
    },
  ];

  return (
    <Joyride
      steps={steps}
      styles={{
        options: {
          // Hint colour
          primaryColor: 'var(--secondary-light)',
        },
      }}
    />
  );
}
