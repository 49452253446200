import React from 'react';
import Joyride from 'react-joyride';
import { Alert } from 'reactstrap';

export const UploadOnboardingStepTarget = 'upload-onboarding-popover';

export function UploadOnboardingJoyride() {
  const steps = [
    {
      target: `.${UploadOnboardingStepTarget}`,
      content: (
        <div style={{ textAlign: 'left' }}>
          <h5>Generate your link to start sharing!</h5>
          <br />
          <span>
            A <b>share link</b> is unique to each resume and can be sent to all your reviewers. Each
            reviewer will see a new copy of your resume that they can give feedback on!
          </span>
          <br />
          <br />
          <Alert color="info">
            When you&apos;re ready, press <b>Share</b> to get this resume&apos;s link!
          </Alert>
        </div>
      ),
      placement: 'left',
      hideFooter: true,
    },
  ];

  return (
    <Joyride
      steps={steps}
      styles={{
        options: {
          // Hint colour
          primaryColor: 'var(--secondary-light)',
        },
      }}
    />
  );
}
