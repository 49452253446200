import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import ErrorToastRenderer from '../components/shared/ErrorToastRenderer';
import Header from '../components/shared/Header';
import { gaPageview } from '../helpers/googleAnalyticsHelpers';
import { selectUserLoginState } from '../redux/reducers/userLoginSlice';
import './css/NotFound.css';

function NotFound() {
  gaPageview();
  const loggedIn = useSelector(selectUserLoginState);
  const history = useHistory();

  if (!loggedIn) {
    history.replace('/login');
  }

  return (
    <>
      <ErrorToastRenderer />
      <Header />
      <div className="notfound">
        <div>It looks like that page doesn&apos;t exist. Please check the URL and try again.</div>
        <Button onClick={() => history.push('/home')}>Go home</Button>
      </div>
    </>
  );
}

export default NotFound;
