import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectCommentPins, selectSelectedPinUuid } from '../../redux/reducers/commentPinsSlice';
import CommentPin from './CommentPin';

function CommentPinLayer({ scaleRatio, isEditable }) {
  const commentPins = useSelector(selectCommentPins);
  const selectedPinUuid = useSelector(selectSelectedPinUuid);

  return (
    <div>
      {Object.entries(commentPins).map(([uuid, { xPos, yPos, text, timestamp, dismissed }]) => (
        <CommentPin
          key={uuid}
          uuid={uuid}
          xPos={xPos * scaleRatio}
          yPos={yPos * scaleRatio}
          text={text}
          timestamp={timestamp}
          isSelected={uuid === selectedPinUuid}
          isEditable={isEditable}
          isDismissed={dismissed}
        />
      ))}
    </div>
  );
}

CommentPinLayer.propTypes = {
  scaleRatio: PropTypes.number.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default CommentPinLayer;
