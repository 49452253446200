import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { formatDate, formatTime } from '../../helpers/dateHelpers';
import { updatePinDismissed } from '../../redux/reducers/commentPinsSlice';
import RKContextText from '../design-system/RKContextText';
import CommentToolbarInput from './CommentToolbarInput';

import './css/CommentToolbar.css';

function CommentToolbarDisplay({ uuid, text, timestamp, isSelected, isDismissed }) {
  const dispatch = useDispatch();
  const [hideSelectedComment, setHideSelectedComment] = useState(false);

  let commentToolbarInputClassName = 'last-comments-comment';
  if (isDismissed) {
    commentToolbarInputClassName += ' last-comments-comment-dismissed';
    if (!isSelected || hideSelectedComment) {
      commentToolbarInputClassName += ' last-comments-comment-collapse';
    }
  }

  useEffect(() => {
    if (!isSelected) {
      setHideSelectedComment(false);
    }
  }, [isSelected]);

  return (
    <>
      <FontAwesomeIcon
        className="commentmodal-button"
        icon={isDismissed ? faPlus : faMinus}
        onClick={() => {
          dispatch(updatePinDismissed({ uuid, dismissed: !isDismissed }));
          setHideSelectedComment(!hideSelectedComment);
        }}
      />
      <div className={commentToolbarInputClassName}>
        <CommentToolbarInput uuid={uuid} text={text} isEditable={false} />
      </div>
      {(!isDismissed || (isSelected && !hideSelectedComment)) && (
        <div className="last-comments-comment-block-footer">
          <RKContextText className="last-comments-footer-text">
            {formatDate(timestamp)}
          </RKContextText>
          <RKContextText className="last-comments-footer-text">
            {formatTime(timestamp)}
          </RKContextText>
        </div>
      )}
    </>
  );
}

CommentToolbarDisplay.propTypes = {
  uuid: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDismissed: PropTypes.bool.isRequired,
};

export default CommentToolbarDisplay;
