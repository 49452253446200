import PropTypes from 'prop-types';
import React from 'react';
import './css/RKHeaderButton.css';

/**
 * A text/icon button in the ReviewKit header.
 */
function RKHeaderButton({ children, id, isSelected, canDeselect, disabled, onClick }) {
  const selectedStyle = `rkheaderbutton-selected${canDeselect ? '-deselectable' : ''}`;
  return (
    <button
      id={id} // React will turn this null attrtibuet into and unset html attribute
      type="button"
      className={`rkheaderbutton ${isSelected ? selectedStyle : 'rkheaderbutton-deselected'}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

RKHeaderButton.propTypes = {
  /**
   * The text or icon to use in the button. Icon should be a FontAwesomeIcon object.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  /**
   * Id of button.
   */
  id: PropTypes.string,
  /**
   * If the button is currently selected.
   */
  isSelected: PropTypes.bool.isRequired,
  /**
   * If the button can be deselected if it's clicked again while already selected.
   */
  canDeselect: PropTypes.bool,
  /**
   * If the button cannot be interacted with.
   */
  disabled: PropTypes.bool,
  /**
   * Function to run on button click.
   */
  onClick: PropTypes.func.isRequired,
};

RKHeaderButton.defaultProps = {
  id: null,
  canDeselect: false,
  disabled: false,
};

export default RKHeaderButton;
