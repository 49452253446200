// window.RVKT_ENV is set in env-config.js (generated at build-time)
export default function env(key) {
  return window?.RVKT_ENV?.[key] ?? '';
}

export const APP_ENV = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
};
