import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { HomeOnboardingJoyride } from '../components/home/HomeOnboardingJoyride';
import RecentUploadsGrid from '../components/home/RecentUploadsGrid';
import ReviewsGrid from '../components/home/ReviewsGrid';
import ErrorToastRenderer from '../components/shared/ErrorToastRenderer';
import Header from '../components/shared/Header';
import { gaPageview } from '../helpers/googleAnalyticsHelpers';
import { selectUserLoginState } from '../redux/reducers/userLoginSlice';

import './css/Home.css';
import '../style/css/Sticky.css';

function HomeView() {
  gaPageview();
  const history = useHistory();
  const loggedIn = useSelector(selectUserLoginState);

  if (!loggedIn) {
    history.replace('/login');
  }

  // Store if the user has visited this page before in local storage for onboarding
  // TODO: Move this from localStorage to DB
  const [firstVisitToPage, setFirstVisitToPage] = useState(false);
  useEffect(() => {
    setFirstVisitToPage(!localStorage.getItem('wasHomeViewVisited'));
    localStorage.setItem('wasHomeViewVisited', 1);
  }, []);

  return (
    <div className="homeview">
      {/* Only show onboarding guide in first visit. */}
      {firstVisitToPage && <HomeOnboardingJoyride />}
      <ErrorToastRenderer />
      <div className="sticky-header">
        <Header />
      </div>
      <div className="homeview-content">
        <RecentUploadsGrid />
        <ReviewsGrid />
      </div>
    </div>
  );
}

export default HomeView;
