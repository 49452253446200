import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { initCanvas, resetCanvas } from '../../../redux/reducers/canvasSlice';
import { resetPins } from '../../../redux/reducers/commentPinsSlice';
import { resetLoadStatus } from '../../../redux/reducers/pdfMarkupStatusSlice';
import {
  fetchSavedAnnotations,
  updateSavedReview,
} from '../../../redux/reducers/savedReviewApiSlice';
import '../css/Canvas.css';

const COMMENT_PUSH_FREQUENCY_MS = 30 * 1000;

function SavedReviewCanvas({ reviewId }) {
  const dispatch = useDispatch();
  useEffect(() => {
    const canvas = new fabric.StaticCanvas('saved-review-canvas');
    dispatch(initCanvas({ canvas }));
    dispatch(fetchSavedAnnotations(reviewId));

    const pushComments = () => dispatch(updateSavedReview({ reviewId }));
    const intervalId = setInterval(pushComments, COMMENT_PUSH_FREQUENCY_MS);
    window.addEventListener('beforeunload', pushComments);
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('beforeunload', pushComments);
      pushComments();
      dispatch(resetCanvas());
      dispatch(resetPins());
      dispatch(resetLoadStatus());
    };
  }, []);

  return (
    <div className="canvas no-pointer-events">
      <canvas id="saved-review-canvas" />
    </div>
  );
}

SavedReviewCanvas.propTypes = {
  reviewId: PropTypes.string.isRequired,
};

export default SavedReviewCanvas;
