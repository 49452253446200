import PropTypes from 'prop-types';
import React from 'react';
import { Media } from 'reactstrap';

import env from '../../helpers/envHelpers';
import RKEmphasisText from '../design-system/RKEmphasisText';
import EmptyReviewImage from './assets/empty-review.png';
import './css/ReviewEmpty.css';

function ReviewEmpty({ showSaveMessage }) {
  return (
    <div className="review-empty-base">
      <div className="review-empty-resume-block">
        <Media src={EmptyReviewImage} />
        {showSaveMessage ? (
          <RKEmphasisText>Thanks for submitting your review!</RKEmphasisText>
        ) : (
          <>
            <h5>Open a share link to start reviewing!</h5>
            <RKEmphasisText>{`${env('RVKT_HOST').replace(
              /.*:\/\//,
              '',
            )}/review/<review id>`}</RKEmphasisText>
          </>
        )}
      </div>
    </div>
  );
}

ReviewEmpty.propTypes = {
  showSaveMessage: PropTypes.bool,
};

ReviewEmpty.defaultProps = {
  showSaveMessage: false,
};

export default ReviewEmpty;
