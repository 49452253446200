import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Media } from 'reactstrap';

import Login from '../components/login/Login';
import ErrorToastRenderer from '../components/shared/ErrorToastRenderer';
import Header from '../components/shared/Header';
import env from '../helpers/envHelpers';
import { gaPageview } from '../helpers/googleAnalyticsHelpers';
import { selectUserLoginState } from '../redux/reducers/userLoginSlice';
import UploadImage from './assets/login-graphic.png';
import './css/LoginPage.css';

function LoginPage() {
  gaPageview();
  const loggedIn = useSelector(selectUserLoginState);
  const history = useHistory();

  if (loggedIn) {
    history.replace('/home');
  }

  return (
    <div className="login-page">
      <ErrorToastRenderer />
      <Header noShadow showUserProfile={false} />
      <div className="login-page-modal">
        <h2>Sign Up</h2>
        <div className="login-page-modal-body">
          <Login />
          <h3>
            by signing up you agree to our{' '}
            <a href={`${env('RVKT_HOST')}/terms`} target="_blank" rel="noopener noreferrer">
              terms and conditions
            </a>
          </h3>
          <Media src={UploadImage} />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
