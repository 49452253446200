/* eslint-disable no-param-reassign */
// (the `state` param of reducers is intended to be reassigned)
import http from 'http';
import https from 'https';

import { createAsyncThunk, createSlice, isFulfilled } from '@reduxjs/toolkit';
import axios from 'axios';

import { authAxiosRequest } from '../../helpers/authHelpers';
import {
  getCompressedComments,
  getDecompressedAnnotations,
} from '../../helpers/compressionHelpers';
import env from '../../helpers/envHelpers';

// [GET /review/complete] request generator
function generateGetReviewCompleteRequest(reviewId) {
  const config = {
    baseURL: `${env('RVKT_API_HOST')}`,
    params: {
      reviewId,
    },
    headers: { 'Content-Type': 'application/json' },
  };
  return authAxiosRequest('/review/complete', 'GET', {}, config);
}

// [PUT /review/complete] request generator to update comments for Saved Reviews
function generatePutReviewCompleteRequest(currentState, reviewId) {
  const { page } = currentState.reviewerPdf;
  const { pins, serializedPins } = currentState.commentPins;

  // If pins haven't loaded, don't update
  if (Object.keys(pins).length === 0) {
    // TODO: ideally check for non-existent pins, not empty
    return Promise.resolve({
      data: {
        data: '',
        status: 200,
        statusText: 'OK',
      },
    });
  }
  const serializedComments = JSON.stringify({
    ...serializedPins,
    [page]: JSON.stringify(pins),
  });

  const compressedComments = getCompressedComments(serializedComments);

  const data = {
    comments: compressedComments,
  };

  // Only PUT to the server if the comments are different than what the server has
  if (currentState.savedReviewApi.lastPutCompressedComments === compressedComments) {
    return Promise.resolve({
      data: {
        data,
        status: 200,
        statusText: 'OK',
      },
    });
  }

  const config = {
    baseURL: `${env('RVKT_API_HOST')}`,
    params: {
      reviewId,
    },
    headers: { 'Content-Type': 'application/json' },
    // Since we send this request once on page unload.
    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true }),
  };

  return axios.put('/review/complete', data, config);
}

// [DELETE /review/complete] request generator
function generateDeleteReviewCompleteRequest(resumeId, reviewId) {
  const config = {
    baseURL: `${env('RVKT_API_HOST')}`,
    params: {
      resumeId,
      reviewId,
    },
  };
  return authAxiosRequest('/review/complete', 'DELETE', {}, config);
}

export const fetchSavedAnnotations = createAsyncThunk(
  'completedReview/fetchSavedAnnotations',
  async (reviewId) =>
    generateGetReviewCompleteRequest(reviewId)
      .then((response) => {
        const responseData = response.data;
        const { decompressedMarkup, decompressedComments } = getDecompressedAnnotations(
          responseData.data,
        );
        responseData.data.markup = decompressedMarkup;
        responseData.data.comments = decompressedComments;
        return responseData.data;
      })
      .catch((error) => {
        throw new Error(`Response code: ${error.response.status}`);
      }),
);

export const updateSavedReview = createAsyncThunk(
  'completedReview/updateSavedReview',
  async ({ reviewId }, thunkApi) =>
    generatePutReviewCompleteRequest(thunkApi.getState(), reviewId)
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(`Response code: ${error.response.status}`);
      }),
);

export const deleteSavedReview = createAsyncThunk(
  'completedReview/deleteSavedReview',
  async ({ resumeId, reviewId }) =>
    generateDeleteReviewCompleteRequest(resumeId, reviewId)
      .then((response) => {
        response.data.reviewId = reviewId;
        return response.data;
      })
      .catch((error) => {
        throw new Error(`Response code: ${error.response.status}`);
      }),
);

export const savedReviewApiSlice = createSlice({
  name: 'savedReview',
  initialState: {
    resumeId: null, // The resumeId for the current session.
    reviewId: null, // The reviewId for the current session.
    reviewerName: null,
    reviewedAt: null,
    reviewerEmail: null,
    generalComments: null,
    lastPutCompressedComments: null,
  },
  reducers: {
    setSavedResumeId: (state, { payload }) => {
      state.resumeId = payload;
    },
    setSavedReviewId: (state, { payload }) => {
      state.reviewId = payload;
    },
    resetSavedResumeData: (state) => {
      state.resumeId = null;
      state.reviewId = null;
      state.reviewerName = null;
      state.reviewedAt = null;
      state.reviewerEmail = null;
      state.generalComments = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isFulfilled(fetchSavedAnnotations), (state, { payload }) => {
        state.reviewerName = payload.reviewerName;
        state.reviewedAt = payload.reviewedAt;
        state.reviewerEmail = payload.reviewerEmail;
        state.generalComments = payload.generalComments;
      })
      .addMatcher(isFulfilled(deleteSavedReview), (state, { payload }) => {
        if (payload.reviewId === state.reviewId) {
          state.resumeId = null;
          state.reviewId = null;
        }
      })
      .addMatcher(isFulfilled(updateSavedReview), (state, { payload }) => {
        if (payload.data.comments) {
          state.lastPutCompressedComments = payload.data.comments;
        }
      });
  },
});

export const { setSavedReviewId, setSavedResumeId, resetSavedResumeData } =
  savedReviewApiSlice.actions;
export const selectReviewerName = (state) => state.savedReviewApi.reviewerName;
export const selectReviewedAt = (state) => state.savedReviewApi.reviewedAt;
export const selectReviewerEmail = (state) => state.savedReviewApi.reviewerEmail;
export const selectGeneralComments = (state) => state.savedReviewApi.generalComments;
export default savedReviewApiSlice.reducer;
