import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

import '../css/PdfView.css';

function ArrowWrapper({ children, onPageChange, page, setPage, totalPages, navEnabled }) {
  const showPageNavigation = totalPages > 1;

  const decrementPage = () =>
    setPage((oldPage) => {
      onPageChange(oldPage, oldPage - 1);
      return oldPage - 1;
    });

  const incrementPage = () =>
    setPage((oldPage) => {
      onPageChange(oldPage, oldPage + 1);
      return oldPage + 1;
    });

  if (!showPageNavigation) {
    return children;
  }

  return (
    <>
      <Button
        className="pdf-page-button pdf-page-nav-button"
        disabled={page === 1 || !navEnabled}
        onClick={decrementPage}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </Button>
      {children}
      <Button
        className="pdf-page-button pdf-page-nav-button"
        disabled={page === totalPages || !navEnabled}
        onClick={incrementPage}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </>
  );
}

ArrowWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onPageChange: PropTypes.func,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func,
  totalPages: PropTypes.number.isRequired,
  navEnabled: PropTypes.bool,
};

ArrowWrapper.defaultProps = {
  onPageChange: () => {},
  setPage: () => {},
  navEnabled: true,
};

export default ArrowWrapper;
