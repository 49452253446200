import { faClipboardList, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Input, Tooltip } from 'reactstrap';

import { authFetch } from '../../helpers/authHelpers';
import env from '../../helpers/envHelpers';
import { selectUserData } from '../../redux/reducers/userLoginSlice';
import RKEmphasisText from '../design-system/RKEmphasisText';
import RKModal from '../design-system/RKModal';
import RKModalElement from '../design-system/RKModalElement';
import ReactSelectCreatableInput from './ReactSelectCreatableInput';

import './css/ShareModal.css';

// [POST /emails/invite] request generator
const generatePostEmailsInviteRequest = (requestBody) => {
  const url = new URL(`${env('RVKT_API_HOST')}/emails/invite`);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  };
  return authFetch(url, requestOptions);
};

const copyToClipboard = (id, setCopyTooltipOpen) => {
  const copyTextarea = document.getElementById(id);
  copyTextarea.focus();
  copyTextarea.select();
  document.execCommand('copy');
  setCopyTooltipOpen(true);
  setTimeout(() => setCopyTooltipOpen(false), 3500);
};

function ShareModal({ open, toggle, pdfId }) {
  const shareLinkId = 'sharemodal-link';
  const { firstName, profilePictureUrl } = useSelector(selectUserData);

  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);
  const [sendTooltipOpen, setSendTooltipOpen] = useState(false);

  const [inputEmails, setInputEmails] = useState([]);
  const [sendingEmail, setSendingEmail] = useState(false);

  const [linkSectionHovered, setLinkSectionHovered] = useState(false);
  const [emailSectionHovered, setEmailSectionHovered] = useState(false);

  useEffect(
    () =>
      function cleanup() {
        setCopyTooltipOpen(false);
        setSendTooltipOpen(false);
      },
    [open],
  );

  const shareLink = `${env('RVKT_HOST')}/review/${pdfId}`;
  const sendEmail = async () => {
    setSendingEmail(true);
    try {
      const response = await generatePostEmailsInviteRequest({
        sendTo: inputEmails,
        senderFirstName: firstName,
        senderProfilePictureUrl: profilePictureUrl,
        reviewPageUrl: shareLink,
      });
      if (!response.ok) {
        throw response; // TODO: Some visual failure indication.
      }
      setSendTooltipOpen(true);
      setTimeout(() => setSendTooltipOpen(false), 3500);
    } finally {
      setSendingEmail(false);
    }
  };

  return (
    <RKModal isOpen={open} toggle={toggle}>
      <RKModalElement onHoverChange={setLinkSectionHovered}>
        <h3>
          Share via <RKEmphasisText>link</RKEmphasisText>
        </h3>
        <div className="sharemodal-element-body">
          <Input id={shareLinkId} value={shareLink} onChange={() => {}} />
          <Button
            id="sharemodal-copy"
            className={`sharemodal-element-button ${
              linkSectionHovered ? 'primary-button' : 'tertiary-button'
            }`}
            onClick={() => copyToClipboard(shareLinkId, setCopyTooltipOpen)}
          >
            <FontAwesomeIcon icon={faClipboardList} /> Copy
          </Button>
        </div>
        <Tooltip
          className="sharemodal-tooltip"
          placement="top"
          isOpen={copyTooltipOpen}
          target="sharemodal-copy"
          trigger=""
          fade
        >
          Copied
        </Tooltip>
      </RKModalElement>
      <RKModalElement onHoverChange={setEmailSectionHovered}>
        <h3>
          <RKEmphasisText>Email</RKEmphasisText> link to reviewers
        </h3>
        <div className="sharemodal-element-body">
          <ReactSelectCreatableInput
            onValuesChanged={setInputEmails}
            placeholder="Enter an email and press enter"
          />
          <Button
            id="sharemodal-send"
            className={`sharemodal-element-button ${
              emailSectionHovered ? 'primary-button' : 'tertiary-button'
            }`}
            disabled={sendingEmail || inputEmails.length === 0}
            onClick={sendEmail}
          >
            <FontAwesomeIcon icon={faShare} /> Send
          </Button>
        </div>
        <Tooltip
          className="sharemodal-tooltip"
          placement="top"
          isOpen={sendTooltipOpen}
          target="sharemodal-send"
          trigger=""
          fade
        >
          Sent
        </Tooltip>
      </RKModalElement>
    </RKModal>
  );
}

ShareModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  pdfId: PropTypes.string,
};

ShareModal.defaultProps = {
  pdfId: null,
};
export default ShareModal;
