import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import canvasEventEmitter, {
  canvasAction,
  createCanvasEvent,
} from '../../../helpers/canvasEventEmitter';
import { GA_CATEGORY, gaEvent } from '../../../helpers/googleAnalyticsHelpers';
import { selectCanvas } from '../../../redux/reducers/canvasSlice';
import { setTool } from '../../../redux/reducers/selectedToolSlice';
import RKHeaderButton from '../../design-system/RKHeaderButton';
import RKHelpTooltip from '../../design-system/RKHelpTooltip';
import ToolTypes from './SelectedToolTypes';

/* eslint-disable no-param-reassign */
function setPencilMode(canvas, width, brushColor, onMouseDown, onMouseUp, onObjectAdded) {
  canvas.freeDrawingBrush.color = brushColor;
  canvas.freeDrawingBrush.width = width;
  canvas.isDrawingMode = true;
  canvas.off('mouse:down');
  canvas.off('mouse:move');
  canvas.off('mouse:up');
  canvas.off('object:added');

  // Listeners to detect and emit when objects are drawn
  canvas.on('mouse:down', onMouseDown);
  canvas.on('mouse:up', onMouseUp);
  canvas.on('object:added', onObjectAdded);
}

function PencilToolButton({ isSelected, disabled }) {
  const dispatch = useDispatch();
  const canvas = useSelector(selectCanvas);
  let isDown = false;
  let lastAddedObject = null;

  function onObjectAdded(o) {
    if (isDown) {
      lastAddedObject = o.target;
    }
  }

  function onMouseDown() {
    isDown = true;
  }

  function onMouseUp() {
    isDown = false;
    // lastAddedObject should be drawn object and not objects added from undo/redo
    if (lastAddedObject) {
      canvasEventEmitter.emit('action', createCanvasEvent(canvasAction.ADD, null, lastAddedObject));
      lastAddedObject = null;
    }
  }

  useEffect(() => {
    if (isSelected) {
      setPencilMode(canvas, 2, '#333', onMouseDown, onMouseUp, onObjectAdded);
    }
  }, [isSelected]);

  const onPencilButtonClick = (e) => {
    gaEvent(GA_CATEGORY.REVIEWER, 'onPencilButtonClick', 'Clicked PencilToolButton');
    if (isSelected) {
      dispatch(setTool(ToolTypes.SELECTION));
      e.currentTarget.blur();
    } else {
      dispatch(setTool(ToolTypes.PENCIL));
    }
  };

  return (
    <>
      <RKHeaderButton
        id="pencil-tool"
        isSelected={isSelected}
        canDeselect
        disabled={disabled || !canvas}
        onClick={onPencilButtonClick}
      >
        <FontAwesomeIcon icon={faPen} size="lg" />
      </RKHeaderButton>
      <RKHelpTooltip targetId="pencil-tool">Draw strokes</RKHelpTooltip>
    </>
  );
}

PencilToolButton.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

PencilToolButton.defaultProps = {
  disabled: false,
};

export default PencilToolButton;
