/* eslint-disable jsx-a11y/label-has-associated-control */
// For a checkbox, nesting the <input> within the <label> is a valid equivalent to
// <label for="id-of-input">, and saves the dev from having to provide a valid ID
// (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label).
// The airbnb config requires nesting _and_ supplying an ID, which is contentious
// (https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/718) and makes the
// API more complex (unique ID needs to be supplied), so I've opted to disable the rule here.

import PropTypes from 'prop-types';
import React from 'react';
import './css/RKCheckbox.css';

/**
 * A checkbox with some associated text.
 * Since the only use case for now is a danger-colored checkbox,
 * the color of the checkbox is non-customizable and defaults to danger.
 */
function RKCheckbox({ checked, label, onChange }) {
  return (
    <label className="rkcheckbox">
      <span className="rkcheckbox-input-wrapper rkcheckbox-danger">
        <input type="checkbox" name="checkbox" checked={checked} onChange={onChange} />
        <div className="rkcheckbox-checked-icon" />
      </span>
      <span className="rkcheckbox-label">{label}</span>
    </label>
  );
}

RKCheckbox.propTypes = {
  /**
   * Whether the checkbox is toggled on.
   */
  checked: PropTypes.bool,
  /**
   * The text to display beside and associate with the checkbox.
   */
  label: PropTypes.string,
  /**
   * Function to run on checkbox change.
   */
  onChange: PropTypes.func.isRequired,
};

RKCheckbox.defaultProps = {
  checked: false,
  label: '',
};

export default RKCheckbox;
