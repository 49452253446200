import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'reactstrap';

import RKModalElement from './RKModalElement';

import './css/RKModal.css';

/**
 * A modal consisting of sections which can be individually hovered to receive an emphasis color.
 *
 * The modal emphasis is compatible with RKEmphasisText (the hovered sections will have emphasis
 * while the unhovered sections will not). For other hover interactions, use the
 * hover callback on RKModalElement.
 */
function RKModal({ isOpen, toggle, children }) {
  // Hover first section by default
  const [hoveredSection, setHoveredSection] = useState(0);

  return (
    <Modal
      className="rkmodal"
      isOpen={isOpen}
      toggle={toggle}
      onClosed={() => setHoveredSection(0)}
      centered
    >
      {React.Children.map(children, (child, idx) =>
        React.cloneElement(child, {
          hovered: idx === hoveredSection,
          underlined: idx !== children.length - 1,
          onMouseEnter: () => setHoveredSection(idx),
        }),
      )}
    </Modal>
  );
}

RKModal.propTypes = {
  /**
   * Whether or not the modal should be shown.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Callback function for toggling isOpen in the controlling component.
   */
  toggle: PropTypes.func.isRequired,
  /**
   * One or more RKModalElements to be rendered as the modal contents, each RKModalElement will be
   * turned into a highlightable modal section which will receive an emphasis color when hovered
   * (non-hovered sections will be uncolored).
   */
  // The custom validator already has validation that there is 1 or more children (= isRequired)
  // eslint-disable-next-line react/require-default-props
  children: (props, propName, componentName) => {
    const prop = props[propName];

    if (React.Children.count(prop) === 0) {
      return new Error(`${componentName} must have at least 1 child.`);
    }

    let error = null;
    React.Children.forEach(prop, (child) => {
      if (child.type.name !== RKModalElement.name) {
        error = new Error(`${componentName} children should be of type ${RKModalElement.name}.`);
      }
    });
    return error;
  },
};

export default RKModal;
