import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import { selectTool, setTool } from '../../redux/reducers/selectedToolSlice';
import RKHelpTooltip from '../design-system/RKHelpTooltip';
import Header from '../shared/Header';
import { ReviewerOnboardingStepTarget } from './ReviewerOnboardingJoyride';
import CommentToolButton from './tools/CommentToolButton';
import EraseToolButton from './tools/EraseToolButton';
import HighlightToolButton from './tools/HighlightToolButton';
import PencilToolButton from './tools/PencilToolButton';
import RectangleToolButton from './tools/RectangleToolButton';
import ToolTypes from './tools/SelectedToolTypes';
import SelectionToolButton from './tools/SelectionToolButton';
import UndoRedoToolButton from './tools/UndoRedoTool';

import './css/ReviewHeader.css';

function ReviewHeader({ disabled, finishedToggle }) {
  const dispatch = useDispatch();
  const selectedTool = useSelector(selectTool);
  if (disabled && selectedTool !== ToolTypes.SELECTION) {
    dispatch(setTool(ToolTypes.SELECTION));
  }

  return (
    <Header>
      <div className="review-header">
        <SelectionToolButton
          isSelected={selectedTool === ToolTypes.SELECTION}
          disabled={disabled}
        />
        <CommentToolButton isSelected={selectedTool === ToolTypes.COMMENT} disabled={disabled} />
        <PencilToolButton isSelected={selectedTool === ToolTypes.PENCIL} disabled={disabled} />
        <HighlightToolButton
          isSelected={selectedTool === ToolTypes.HIGHLIGHTER}
          disabled={disabled}
        />
        <RectangleToolButton
          isSelected={selectedTool === ToolTypes.RECTANGLE}
          disabled={disabled}
        />
        <EraseToolButton isSelected={selectedTool === ToolTypes.ERASER} disabled={disabled} />
        <UndoRedoToolButton disabled={disabled} selectedTool={selectedTool} />
      </div>
      <div className="review-header-finish">
        <Button
          id="finish-button"
          className={`primary-button ${ReviewerOnboardingStepTarget}`}
          disabled={disabled}
          onClick={finishedToggle}
        >
          <FontAwesomeIcon icon={faCheck} />
          Finish
        </Button>
      </div>
      <RKHelpTooltip targetId="finish-button">End review session</RKHelpTooltip>
    </Header>
  );
}

ReviewHeader.propTypes = {
  disabled: PropTypes.bool,
  finishedToggle: PropTypes.func.isRequired,
};

ReviewHeader.defaultProps = {
  disabled: false,
};

export default ReviewHeader;
