import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row } from 'reactstrap';

import SavedReviewEmpty from '../components/review/SavedReviewEmpty';
import SavedReviewSidebar from '../components/review/SavedReviewSidebar';
import AnnotatedPdfContainer from '../components/shared/AnnotatedPdfContainer';
import ErrorToastRenderer from '../components/shared/ErrorToastRenderer';
import Header from '../components/shared/Header';
import { gaPageview } from '../helpers/googleAnalyticsHelpers';
import REVIEW_TYPE from '../helpers/reviewTypeEnum';
import { changePage as markupChangePage } from '../redux/reducers/canvasSlice';
import { changePage as commentsChangePage } from '../redux/reducers/commentPinsSlice';
import { fetchResume, setResumeId } from '../redux/reducers/reviewerApiSlice';
import { setPage, selectReviewerPdf, resetResume } from '../redux/reducers/reviewerPdfSlice';
import { resetSavedResumeData } from '../redux/reducers/savedReviewApiSlice';
import { selectUserLoginState } from '../redux/reducers/userLoginSlice';

import './css/Page.css';
import '../style/css/Sticky.css';
import './css/PdfContainer.css';

// Prop types are all from an external API, for the full typedefs see:
// https://reactrouter.com/web/api/Route/route-props
/* eslint-disable react/prop-types */
function SavedReviewView({ match }) {
  gaPageview();
  const dispatch = useDispatch();
  const pdfData = useSelector(selectReviewerPdf);
  const loggedIn = useSelector(selectUserLoginState);

  const { resumeId, reviewId } = match.params || {};
  const isEmpty = !resumeId;

  useEffect(
    () => () => {
      dispatch(resetResume());
      dispatch(resetSavedResumeData());
    },
    [],
  );

  useEffect(() => {
    if (resumeId && reviewId) {
      dispatch(setResumeId(resumeId));
      dispatch(fetchResume(resumeId));
    }
  }, [dispatch, resumeId, reviewId]);

  const history = useHistory();
  if (!loggedIn) {
    history.replace('/login');
  }

  return (
    <div className="page-flex-container" style={{ minWidth: '950px' }}>
      <ErrorToastRenderer />
      <div className="sticky-header">
        <Header />
      </div>
      <Row className="pdf-container-whole-width" noGutters>
        <div className="pdf-container-main-body">
          {isEmpty ? (
            <SavedReviewEmpty />
          ) : (
            <AnnotatedPdfContainer
              reviewId={reviewId}
              reviewType={REVIEW_TYPE.SAVED_REVIEW}
              pdfData={pdfData}
              showBackButton
              backButtonLink="/home"
              onPageChange={(oldPage, newPage) => {
                dispatch(setPage({ oldPage, newPage }));
                dispatch(markupChangePage({ oldPage, newPage }));
                dispatch(commentsChangePage({ oldPage, newPage }));
              }}
              isEditable={false}
            />
          )}
        </div>
        <SavedReviewSidebar isDisabled={isEmpty || !pdfData} />
      </Row>
    </div>
  );
}

export default SavedReviewView;
