import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';

import CommentToolbar from '../components/review/CommentToolbar';
import CommentToolbarEditableDisplay from '../components/review/CommentToolbarEditableDisplay';
import NotesFromAuthor from '../components/review/NotesFromAuthor';
import ReviewEmpty from '../components/review/ReviewEmpty';
import { ReviewerOnboardingJoyride } from '../components/review/ReviewerOnboardingJoyride';
import ReviewHeader from '../components/review/ReviewHeader';
import SaveModal from '../components/review/SaveModal';
import AnnotatedPdfContainer from '../components/shared/AnnotatedPdfContainer';
import ErrorToastRenderer from '../components/shared/ErrorToastRenderer';
import { gaPageview } from '../helpers/googleAnalyticsHelpers';
import REVIEW_TYPE from '../helpers/reviewTypeEnum';
import { changePage as markupChangePage } from '../redux/reducers/canvasSlice';
import { changePage as commentsChangePage } from '../redux/reducers/commentPinsSlice';
import { setResumeId, fetchResume } from '../redux/reducers/reviewerApiSlice';
import {
  setPage,
  selectReviewerPdf,
  resetResume,
  selectReviewerPdfNotes,
} from '../redux/reducers/reviewerPdfSlice';

import './css/Page.css';
import '../style/css/Sticky.css';
import './css/PdfContainer.css';

// Prop types are all from an external API, for the full typedefs see:
// https://reactrouter.com/web/api/Route/route-props
/* eslint-disable react/prop-types */
function ReviewerView({ match }) {
  gaPageview();
  const dispatch = useDispatch();
  const pdfData = useSelector(selectReviewerPdf);
  const notes = useSelector(selectReviewerPdfNotes);

  const { resumeId } = match.params;
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  const [redirectedFromSave, setRedirectedFromSave] = useState(false);
  const isEmpty = !resumeId;

  // Store if the user has visited this page before in local storage for onboarding
  const [firstVisitToPage, setFirstVisitToPage] = useState(false);
  useEffect(() => {
    setFirstVisitToPage(!localStorage.getItem('wasReviewViewVisited'));
    localStorage.setItem('wasReviewViewVisited', 1);
  }, []);

  useEffect(
    () => () => {
      dispatch(resetResume());
    },
    [],
  );

  useEffect(() => {
    if (resumeId) {
      dispatch(setResumeId(resumeId));
      dispatch(fetchResume(resumeId));
    }
  }, [dispatch, resumeId]);

  useEffect(() => {
    const alertUser = (e) => {
      if (isEmpty) {
        return undefined;
      }
      // Custom confirmation messages are not supported in most modern browsers.
      // https://stackoverflow.com/a/38880926
      const confirmationMessage =
        ('The page contains unsubmitted work, are you sure you want to leave? ',
        'Make sure to press Finish to submit your review.');
      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
    };

    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [isEmpty]);

  const getCommentDisplayContents = (uuid, text, timestamp, isSelected) => (
    <CommentToolbarEditableDisplay
      uuid={uuid}
      text={text}
      timestamp={timestamp}
      isSelected={isSelected}
    />
  );

  return (
    <div className="page-flex-container" style={{ minWidth: '950px' }}>
      {/* Only show onboarding guide in first visit */}
      {firstVisitToPage && <ReviewerOnboardingJoyride />}
      <ErrorToastRenderer />
      <div className="sticky-header">
        <ReviewHeader disabled={isEmpty || !pdfData} finishedToggle={toggleOpen} />
      </div>
      <Row className="pdf-container-whole-width" noGutters>
        <div className="pdf-container-main-body">
          {isEmpty ? (
            <ReviewEmpty showSaveMessage={redirectedFromSave} />
          ) : (
            <AnnotatedPdfContainer
              reviewType={REVIEW_TYPE.REVIEW}
              resumeId={resumeId}
              pdfData={pdfData}
              onPageChange={(oldPage, newPage) => {
                dispatch(setPage({ oldPage, newPage }));
                dispatch(markupChangePage({ oldPage, newPage }));
                dispatch(commentsChangePage({ oldPage, newPage }));
              }}
              isEditable
            />
          )}
        </div>
        <div className="pdf-container-side-bar">
          <CommentToolbar
            isDisabled={isEmpty || !pdfData}
            getCommentDisplayContents={getCommentDisplayContents}
          />
          {/* 
            Since this wrapping div is a flex box, margin collapsing wont be performed: 
            https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing.
            The following card must have its top margin manually turned off.
          */}
          {notes.trim() && <NotesFromAuthor notes={notes} />}
        </div>
      </Row>
      {resumeId && (
        <SaveModal
          open={open}
          resumeId={resumeId}
          toggleModal={toggleOpen}
          setSaved={() => setRedirectedFromSave(true)}
        />
      )}
    </div>
  );
}

export default ReviewerView;
