/* eslint-disable no-param-reassign */
// (the `state` param of reducers is intended to be reassigned)
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { authFetch } from '../../helpers/authHelpers';
import env from '../../helpers/envHelpers';
import THUNK_STATUS from '../../helpers/reduxHelpers';

export const uploadResume = createAsyncThunk('revieweePdf/uploadResumeStatus', async (pdfData) => {
  const data = new FormData();
  data.append('resume', pdfData);
  const settings = {
    method: 'POST',
    body: data,
  };
  const response = await authFetch(`${env('RVKT_API_HOST')}/resume`, settings);
  if (!response.ok) {
    throw new Error(response.status);
  }
  const responseJson = await response.json();
  return responseJson.data;
});

export const fetchResume = createAsyncThunk('revieweePdf/fetchResumeStatus', async (resumeId) => {
  const url = new URL(`${env('RVKT_API_HOST')}/auth/resume`);
  url.search = new URLSearchParams({
    id: resumeId,
  }).toString();
  const requestOptions = { method: 'GET' };

  const response = await authFetch(url, requestOptions);
  if (!response.ok) {
    throw new Error(`Response code: ${response.status}`);
  }
  const responseJson = await response.json();
  return {
    resumeId,
    pdfData: responseJson.data.pdf,
    name: responseJson.data.name,
    notes: responseJson.data.notes,
    uploadedAt: responseJson.data.uploadedAt,
  };
});

export const deleteResume = createAsyncThunk(
  'revieweePdf/DeleteResumeStatus',
  async ({ resumeId, deleteAllReviews }) => {
    const url = new URL(`${env('RVKT_API_HOST')}/resume`);
    url.search = new URLSearchParams({
      id: resumeId,
      deleteAssociatedReviews: deleteAllReviews,
    }).toString();
    const requestOptions = {
      method: 'DELETE',
    };

    const response = await authFetch(url, requestOptions);
    if (!response.ok) {
      throw new Error(`Response code: ${response.status}`);
    }
    return { deleteAssociatedReviews: deleteAllReviews, resumeId };
  },
);

export const updatePdfData = createAsyncThunk(
  'revieweePdf/updatePdfDataStatus',
  async ({ resumeId, name, notes }) => {
    const url = new URL(`${env('RVKT_API_HOST')}/resume`);
    url.search = new URLSearchParams({
      resumeId,
    }).toString();

    const data = JSON.stringify({
      name: name || undefined, // resume name cannot be cleared
      notes: notes ?? undefined, // notes can be cleared
    });

    const settings = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      keepalive: true,
      method: 'PUT',
      body: data,
    };

    const response = await authFetch(url, settings);
    if (!response.ok) {
      throw new Error(`Response code: ${response.status}`);
    }
    const responseJson = await response.json();
    return responseJson.data;
  },
);

export const revieweePdfSlice = createSlice({
  name: 'revieweePdf',
  initialState: {
    pdf: null, // A PDF file (array of bytes).
    pdfId: null,
    name: '',
    notes: '',
    uploadedAt: null,
    fetchResumeStatus: THUNK_STATUS.WAITING,
    uploadResumeStatus: THUNK_STATUS.WAITING,
  },
  reducers: {
    resetResume: (state) => {
      state.pdf = null;
      state.pdfId = null;
      state.name = '';
      state.notes = '';
      state.uploadedAt = null;
    },
    setPdf: (state, { payload }) => {
      state.pdf = payload;
    },
  },
  extraReducers: {
    [uploadResume.fulfilled]: (state, { payload }) => {
      state.pdfId = payload.resumeId;
      state.name = payload.name ?? '';
      state.notes = payload.notes ?? '';
      state.uploadedAt = payload.uploadedAt;
      state.uploadResumeStatus = THUNK_STATUS.SUCCEEDED;
    },
    [uploadResume.pending]: (state) => {
      state.uploadResumeStatus = THUNK_STATUS.LOADING;
      // If a new resume is uploaded, it hasn't been fetched yet
      state.fetchResumeStatus = THUNK_STATUS.WAITING;
    },
    [uploadResume.rejected]: (state) => {
      state.uploadResumeStatus = THUNK_STATUS.FAILED;
    },
    [fetchResume.fulfilled]: (state, { payload }) => {
      state.pdfId = payload.resumeId;
      state.pdf = payload.pdfData;
      state.name = payload.name ?? '';
      state.notes = payload.notes ?? '';
      state.uploadedAt = payload.uploadedAt;
      state.fetchResumeStatus = THUNK_STATUS.SUCCEEDED;
    },
    [fetchResume.pending]: (state) => {
      state.fetchResumeStatus = THUNK_STATUS.LOADING;
    },
    [fetchResume.rejected]: (state) => {
      state.fetchResumeStatus = THUNK_STATUS.FAILED;
    },
    [updatePdfData.fulfilled]: (state, { payload }) => {
      state.name = payload.name ?? '';
      state.notes = payload.notes ?? '';
    },
    [deleteResume.fulfilled]: (state, { payload }) => {
      if (payload.resumeId === state.pdfId) {
        state.pdfId = null;
        state.pdf = null;
        state.name = '';
        state.notes = '';
        state.uploadedAt = null;
      }
    },
  },
});

export const { resetResume, setPdf } = revieweePdfSlice.actions;
export const selectRevieweePdf = (state) => state.revieweePdf.pdf;
export const selectRevieweePdfName = (state) => state.revieweePdf.name;
export const selectRevieweePdfNotes = (state) => state.revieweePdf.notes;
export const selectRevieweePdfUploadedAt = (state) => state.revieweePdf.uploadedAt;
export const selectRevieweePdfId = (state) => state.revieweePdf.pdfId;
export const selectFetchResumeStatus = (state) => state.revieweePdf.fetchResumeStatus;
export const selectUploadResumeStatus = (state) => state.revieweePdf.uploadResumeStatus;
export default revieweePdfSlice.reducer;
