/* eslint-disable no-param-reassign */
// (the `state` param of reducers is intended to be reassigned)
import { createSlice } from '@reduxjs/toolkit';

export const errorSlice = createSlice({
  name: 'error',
  initialState: {
    isError: false,
  },
  reducers: {
    setIsError: (state) => {
      state.isError = true;
    },
    resetIsError: (state) => {
      state.isError = false;
    },
  },
});

export const { setIsError, resetIsError } = errorSlice.actions;
export const selectIsError = (state) => state.error.isError;
export default errorSlice.reducer;
