import 'bootstrap/dist/css/bootstrap.css';
import './style/css/index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';

import env from './helpers/envHelpers';
import pageStore from './redux/pageStore';
import Routes from './Routes';
import * as serviceWorker from './serviceWorker';

const trackers = [
  {
    trackingId: 'UA-196506811-1',
    gaOptions: { name: 'trackerProd' },
  },
  {
    trackingId: 'UA-196506811-2',
    gaOptions: { name: 'trackerStaging' },
  },
];

// Currently capturing 100% of users, might want to reduce in future
ReactGA.initialize(trackers, {
  debug: true,
  siteSpeedSampleRate: 100,
  alwaysSendToDefaultTracker: false,
});

Sentry.init({
  dsn: `${env('SENTRY_DSN')}`,
  integrations: [new Integrations.BrowserTracing()],
  // Captures 50% of transactions for performance monitoring
  tracesSampleRate: 0.5,
});

ReactDOM.render(
  <Provider store={pageStore}>
    <Routes />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
