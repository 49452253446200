import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import canvasEventEmitter, {
  canvasAction,
  createCanvasEvent,
} from '../../../helpers/canvasEventEmitter';
import { GA_CATEGORY, gaEvent } from '../../../helpers/googleAnalyticsHelpers';
import { selectCanvas } from '../../../redux/reducers/canvasSlice';
import { setTool } from '../../../redux/reducers/selectedToolSlice';
import RKHeaderButton from '../../design-system/RKHeaderButton';
import RKHelpTooltip from '../../design-system/RKHelpTooltip';
import ToolTypes from './SelectedToolTypes';

/* eslint-disable no-param-reassign */
function setRectangleMode(canvas, onMouseDown, onMouseMove, onMouseUp) {
  canvas.isDrawingMode = false;
  canvas.selection = false;
  canvas.forEachObject((o) => {
    o.selectable = false;
    o.evented = false;
  });

  canvas.off('mouse:down');
  canvas.off('mouse:move');
  canvas.off('mouse:up');

  canvas.on('mouse:down', onMouseDown);
  canvas.on('mouse:move', onMouseMove);
  canvas.on('mouse:up', onMouseUp);
}

function RectangleToolButton({ isSelected, disabled }) {
  const dispatch = useDispatch();
  const canvas = useSelector(selectCanvas);
  let isDown = false;
  let rectangle;
  let xOrig;
  let yOrig;

  function onMouseDown(o) {
    isDown = true;

    const pointer = canvas.getPointer(o.e);
    xOrig = pointer.x;
    yOrig = pointer.y;

    rectangle = new fabric.Rect({
      fill: '',
      stroke: 'rgba(255,0,0,1)',
      strokeWidth: 3,
      left: pointer.x,
      top: pointer.y,
      width: 0,
      height: 0,
      selectable: false,
      evented: false,
    });

    canvas.add(rectangle);
  }

  function onMouseMove(o) {
    if (!isDown) return;

    const pointer = canvas.getPointer(o.e);
    if (xOrig > pointer.x) {
      rectangle.set({ left: pointer.x });
    } else {
      rectangle.set({ left: xOrig });
    }
    if (yOrig > pointer.y) {
      rectangle.set({ top: pointer.y });
    } else {
      rectangle.set({ top: yOrig });
    }
    rectangle.set({
      width: Math.abs(xOrig - pointer.x),
      height: Math.abs(yOrig - pointer.y),
    });

    canvas.renderAll();
  }

  function onMouseUp() {
    isDown = false;
    const newRectangle = new fabric.Rect({
      // updates bounding box for erasing
      fill: '',
      stroke: 'rgba(255,0,0,1)',
      strokeWidth: 3,
      left: rectangle.left,
      top: rectangle.top,
      width: rectangle.width, // this triggers bounding box size only on object creation
      height: rectangle.height, // this triggers bounding box size only on object creation
      selectable: false,
      evented: false,
    });
    canvas.remove(rectangle);
    canvas.add(newRectangle);
    canvasEventEmitter.emit('action', createCanvasEvent(canvasAction.ADD, null, newRectangle));
  }

  useEffect(() => {
    if (isSelected) {
      setRectangleMode(canvas, onMouseDown, onMouseMove, onMouseUp);
    }
  }, [isSelected]);

  const onRectangleButtonClick = (e) => {
    gaEvent(GA_CATEGORY.REVIEWER, 'onRectangleButtonClick', 'Clicked RectangleToolButton');
    if (isSelected) {
      dispatch(setTool(ToolTypes.SELECTION));
      e.currentTarget.blur();
    } else {
      dispatch(setTool(ToolTypes.RECTANGLE));
    }
  };

  return (
    <>
      <RKHeaderButton
        id="rectangle-tool"
        isSelected={isSelected}
        canDeselect
        disabled={disabled || !canvas}
        onClick={onRectangleButtonClick}
      >
        <FontAwesomeIcon icon={faSquare} size="lg" />
      </RKHeaderButton>
      <RKHelpTooltip targetId="rectangle-tool">Draw rectangles</RKHelpTooltip>
    </>
  );
}

RectangleToolButton.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

RectangleToolButton.defaultProps = {
  disabled: false,
};

export default RectangleToolButton;
