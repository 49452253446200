/* eslint-disable no-param-reassign */
// (the `state` param of reducers is intended to be reassigned)
import { createSlice } from '@reduxjs/toolkit';

import ToolTypes from '../../components/review/tools/SelectedToolTypes';

export const selectedToolSlice = createSlice({
  name: 'selectedTool',
  initialState: {
    tool: ToolTypes.NONE,
  },
  reducers: {
    setTool: (state, { payload }) => {
      state.tool = payload;
    },
    resetTool: (state) => {
      state.tool = ToolTypes.NONE;
    },
  },
});

export const { setTool, resetTool } = selectedToolSlice.actions;
export const selectTool = (state) => state.selectedTool.tool;
export default selectedToolSlice.reducer;
