/* eslint-disable no-param-reassign */
// (the `state` param of reducers is intended to be reassigned)
import { createSlice, isFulfilled } from '@reduxjs/toolkit';

import { fetchAnnotations } from './reviewerApiSlice';
import { fetchSavedAnnotations } from './savedReviewApiSlice';

export const commentPinsSlice = createSlice({
  name: 'commentPins',
  initialState: {
    // serializedPins is an object mapping int (page number) to string (serialized pins).
    serializedPins: {},
    pins: {}, // Map of uuid -> pin data; plain object for serializability
    selectedPinUuid: null,
    zoomScale: 1, // zoom factor for dragging comments
  },
  reducers: {
    addPin: (state, { payload }) => {
      const { pinData } = payload;
      state.pins[pinData.uuid] = pinData;
      state.selectedPinUuid = pinData.uuid;
    },
    deletePin: (state, { payload }) => {
      const { uuid } = payload;
      delete state.pins[uuid];
    },
    updatePinText: (state, { payload }) => {
      const { uuid, newText } = payload;
      state.pins[uuid].text = newText;
      state.pins[uuid].timestamp = Date.now();
    },
    updatePinPosition: (state, { payload }) => {
      const { uuid, xPos, yPos } = payload;
      state.pins[uuid].xPos = xPos;
      state.pins[uuid].yPos = yPos;
    },
    updatePinDismissed: (state, { payload }) => {
      const { uuid, dismissed } = payload;
      state.pins[uuid].dismissed = dismissed;
    },
    setSelectedPinUuid: (state, { payload }) => {
      const { uuid } = payload;
      state.selectedPinUuid = uuid;
    },
    resetPins: (state) => {
      state.pins = {};
    },
    changePage: (state, { payload }) => {
      const { oldPage, newPage } = payload;
      state.serializedPins[oldPage] = JSON.stringify(state.pins);
      // Reset pins, since the page is changing
      if (newPage in state.serializedPins) {
        state.pins = JSON.parse(state.serializedPins[newPage]);
      } else {
        state.pins = {};
      }
    },
    updatePinScale: (state, { payload }) => {
      state.zoomScale = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isFulfilled(fetchAnnotations, fetchSavedAnnotations),
      (state, { payload }) => {
        state.serializedPins = JSON.parse(payload.comments);
        state.pins = JSON.parse(state.serializedPins[1]);
      },
    );
  },
});

export const {
  addPin,
  deletePin,
  updatePinText,
  updatePinPosition,
  updatePinDismissed,
  setSelectedPinUuid,
  resetPins,
  changePage,
  updatePinScale,
} = commentPinsSlice.actions;
export const selectCommentPins = (state) => state.commentPins.pins;
export const selectSelectedPinUuid = (state) => state.commentPins.selectedPinUuid;
export const selectPinScale = (state) => state.commentPins.zoomScale;
export default commentPinsSlice.reducer;
