import PropTypes from 'prop-types';
import React from 'react';

import './css/RKEmphasisText.css';

/**
 * Font tags for green text used for emphasis text.
 */
function RKEmphasisText({ children, className }) {
  return <div className={`rkemphasistext ${className}`}>{children}</div>;
}

RKEmphasisText.propTypes = {
  /**
   * The text to by styled.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node)]).isRequired,

  /**
   * Any additional styling.
   */
  className: PropTypes.string,
};

RKEmphasisText.defaultProps = {
  className: '',
};

export default RKEmphasisText;
