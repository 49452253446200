import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import { deleteResume } from '../../redux/reducers/revieweePdfSlice';
import RKCard from '../design-system/RKCard';
import RKCheckbox from '../design-system/RKCheckbox';
import RKContextText from '../design-system/RKContextText';

import './css/DeleteCard.css';

function DeleteCard({ disabled, resumeId }) {
  const history = useHistory();
  const dispatch = useDispatch();

  // Whether the card is in the "confirm deletion" state
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const [deleteAllReviews, setDeleteAllReviews] = useState(false);
  const toggleDeleteAllReviews = () => setDeleteAllReviews(!deleteAllReviews);

  const deleteButtonAction = () => {
    dispatch(deleteResume({ resumeId, deleteAllReviews }));
    history.push('/home');
  };

  const deleteCardClassName = 'delete-card';
  const mouseDownDetection = (event) => {
    const composedPaths = event.composedPath();
    if (!composedPaths) {
      // This code is only reachable on IE.
      // This early return will not be functional but prevents our app from just crashing
      // https://caniuse.com/mdn-api_event_composedpath
      return;
    }
    const paths = composedPaths.map((path) => path.className);
    const deleteCardClicked = paths.some((element) => {
      const classString = `${element}`; // ensure classname is a string
      return classString.includes(deleteCardClassName);
    });

    // Revert the card to the original state if the user clicks out of it
    if (!deleteCardClicked) {
      setConfirmingDelete(false);
    }
  };
  useEffect(() => {
    document.body.addEventListener('mousedown', mouseDownDetection);
    return () => {
      document.body.removeEventListener('mousedown', mouseDownDetection);
    };
  });

  return (
    <RKCard className={deleteCardClassName}>
      {confirmingDelete ? (
        <>
          <RKCheckbox
            label="Also delete associated reviews"
            checked={deleteAllReviews}
            onChange={toggleDeleteAllReviews}
          />
          <RKContextText>Resumes cannot be recovered after deleting</RKContextText>
          <Button
            className="danger-button delete-card-deletebutton delete-card-deletebutton-confirming-delete"
            onClick={deleteButtonAction}
          >
            <h6>
              Confirm Deletion <FontAwesomeIcon icon={faTrash} />
            </h6>
          </Button>
        </>
      ) : (
        <Button
          disabled={disabled}
          className="delete-card-grey-red-button delete-card-deletebutton delete-card-deletebutton-default"
          onClick={() => setConfirmingDelete(true)}
        >
          <h6>
            Delete Resume <FontAwesomeIcon icon={faTrash} />
          </h6>
        </Button>
      )}
    </RKCard>
  );
}

DeleteCard.propTypes = {
  resumeId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

DeleteCard.defaultProps = {
  disabled: false,
};

export default DeleteCard;
